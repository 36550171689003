.felles-module {
  margin-bottom: 136px;

  @media (max-width: 904px) {
    margin-bottom: 56px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  &--red {
    .felles-module__content {
      background-color: $red-100;
      color: #fff;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 58px 75px;

      @media (max-width: 904px) {
        flex-direction: column;
      }

      @media (max-width: 1139px) {
        padding: 37px 45px;
      }

      .felles-module__content--text{
        h2 {
          font-weight: $fw-light;
          padding-right: 32px;

          @media (max-width: 904px) {
            padding-right: 0px;
            text-align: center;
            font-size: 26px;
            line-height: 28px;
            margin-bottom: 16px;
          }
        }

        p {
          margin-top: 16px;
          padding-right: 32px;
          font-size: 24px;
          line-height: 34px;
          font-weight: $fw-light;

          @media (max-width: 904px) {
            margin-top: 11px;
            margin-bottom: 30px;
            padding-right: 0;
            text-align: center;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      .btn {
        color: #fff;
        border-color: #fff;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 904px) {
          padding: 11px 24px;
          font-size: 18px;
          line-height: 26px;
        }

        span {
          position: relative;
          top: 0px;

          @media (max-width: 904px) {
            display: none;
          }
        }

        p {
          white-space: nowrap;

          @media (max-width: 904px) {
            padding-right: 0;
          }
        }

        &:active{
          outline: none;
          box-shadow: none;
          background-color: $red-dark-2;
          border-color: $white;
      }
      }
    }
  }

  &--warm {

    .felles-module__content {
      background-color: $warm-60;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 72px 108px;

      @media (max-width: 1139px) {
        padding: 52px 24px;
      }

      &--text {
        @media (min-width: 905px) {
          width: 69%;
        }

        @media (max-width: 904px){
          text-align: center;
        }

        h2 {
          margin-bottom: 16px;
          font-weight: $fw-light;

          @media (max-width: 904px) {
            margin-bottom: 11px;
          }
        }

        p {
          font-size: 24px;
          line-height: 34px;
          font-weight: $fw-light;

          @media (max-width: 904px) {
            font-size: 16px;
          }
        }
      }

      .btn {
        border: none;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        padding: 20px 30px;

        p {
          color: #fff;
          font-size: 24px;
          font-weight: $fw-light;
          white-space: nowrap;

          @media (max-width: 904px) {
            font-size: 17px;
          }
        }

        span {
          display: block;
          align-self: center;
        }
      }

      @media (max-width: 904px) {
        flex-direction: column;
        align-items: center;
        padding: 49px 20px 44px 20px;

        &--text {

          p {
            margin-bottom: 30px;
            line-height: 22px;

            br {
              display: none;
            }
          }
        }

        .btn {
          padding: 14px 21px;

          .icon {
            width: 24px;
            height: 24px;

            &--arrow-right-32-white{
              background-image: url('./../images/icons/arrow/right/arrow-right-24-white.svg');
            }

            &--arrow-up-right-36-white{
              background-image: url('./../images/icons/arrow/up-right/arrow-up-right-24-white.svg');
            }
          }
        }
      }
    }
  }

  &--green {

    .felles-module__content {
      background-color: $contrast-dark-2;
      padding: 64px 76px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      @media (max-width: 1139px) {
        padding: 50px 24px;
      }

      @media (max-width: 904px) {
        flex-direction: column;
        padding: 48px 16px 32px 16px;
      }

      &--text {
        color: #fff;

        @media (max-width: 904px) {
          text-align: center;
        }

        h2 {
          font-weight: $fw-light;
          margin-bottom: 24px;

          @media (max-width: 904px) {
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 18px;
          }
        }

        p {
          font-size: 20px;
          font-weight: $fw-light;
          line-height: 28px;

          @media (max-width: 904px) {
            margin-bottom: 18px;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      &--icon {
        &.icon--arrow-right-64-white{
          @media (max-width: 904px) {
            width: 48px;
            height: 48px;
            background-image: url('./../images/icons/arrow/right/arrow-right-48-white.svg');
            margin-left: 0;
          }
        }

        &.icon--arrow-up-right-64-white{
          @media (max-width: 904px) {
            width: 48px;
            height: 48px;
            background-image: url('./../images/icons/arrow/up-right/arrow-up-right-48-white.svg');
            margin-left: 0;
          }
        }
      }
    }
  }
}
