.related-news{
    margin-bottom: 136px;

    @media (max-width: 904px) {
        margin-bottom: 80px;
    }

    &:last-child{
       margin-bottom: 0;
    }

    &__header{
        display: flex;
        flex-direction: row;
        align-items: baseline;
        justify-content: space-between;
        margin-bottom: 32px;

        @media (max-width: 904px) {
            margin-bottom: 18px;
        }

        .title--h4 , .title--h2{
            padding-right: 30px;
        }

        .arrow-link{
            white-space: nowrap;

            @media (max-width: 904px) {
                display: none;
            }
        }
    }

    .arrow-card-wrapper{
        .arrow-card{
            .arrow-link{
                .text{
                    p{
                        @media (max-width: 904px) {
                            font-size: 16px;
                            line-height: 22px;
                        }
                    }
                }
            }
        }

        &.slick-initialized{
            @media (max-width: 904px) {
                display: block;
                overflow: hidden;
                margin-right: -32px;
                margin-left: -32px;
            }

            .slick-list{
                @media (max-width: 904px) {
                    padding: 0 23px 0 23px !important;
                }

                .arrow-card{
                    @media (max-width: 904px) {
                        display: inline-block;
                        margin-right: 9px;
                        margin-bottom: 0;
                        margin-left: 9px;
                    }
                }
            }
        }
    }

    .see-all-mobile{
        display: none;
        width: 100%;
        margin-top: 34px;
        padding-top: 25px;
        border-top: 1px solid $solid-40;
        font-size: 15px;
        line-height: 26px;

        @media (max-width: 904px) {
            display: flex;
        }
    }
}
