.featured-hero{
  position: relative;
  margin-bottom: 96px;
  padding: 108px 0 128px;
  background-color: $warm-40;

  @media (max-width: 904px) {
    margin-bottom: 64px;
    padding: 25px 0 38px;
  }

  .breadcrumbs__wrapper{
    margin-bottom: 136px;

    @media (max-width: 904px) {
      margin-bottom: 52px;
    }
  }

  .intro{
    margin-top: 48px;

    @media (max-width: 1280px) {
      margin-top: 32px;
      font-size: 26px;
      line-height: 36px;
    }

    @media (max-width: 904px) {
      margin-top: 32px;
      font-size: 20px;
      line-height: 30px;
    }
  }

  .under-intro{
    margin-top: 24px;
    font-size: 20px;
    line-height: 28px;
    font-weight: $fw-light;

    @media (max-width: 904px) {
      font-size: 16px;
      line-height: 22px;
    }
  }

  .arrow-link{
    margin-top: 73px;

    @media (max-width: 904px) {
      margin-top: 33px;
      font-size: 20px;
      line-height: 28px;
    }

    .icon{
      &--arrow-right-32-red{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/arrow/right/arrow-right-32-red.svg');
      }
    }
  }

  .btn{
    margin-top: 80px;

    @media (max-width: 904px) {
      margin-top: 30px;
      font-size: 19px;
      line-height: 20px;
      padding: 19px 24px;
    }

    .icon{
      &--arrow-right-32-white{
        @media (max-width: 904px) {
          width: 24px;
          height: 24px;
          background-image: url(./../images/icons/arrow/right/arrow-right-24-white.svg);
        }
      }

      &--arrow-up-right-36-white{
        @media (max-width: 904px) {
          width: 24px;
          height: 24px;
          background-image: url(./../images/icons/arrow/up-right/arrow-up-right-24-white.svg);
        }
      }
    }
  }

  &__image{
    display: flex;
    align-items: flex-end;

    figure{
      display: flex;
      align-items: center;
      width: 100%;
      height: 500px;

      img , lottie-player{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: bottom;
      }
    }

    &--desktop{
      @media (max-width: 904px) {
        display: none;
      }
    }

    &--mobile{
      display: none;

      @media (max-width: 904px) {
        display: flex;
        margin-top: 21px;
      }

      figure{
        max-height: 287px;
        height: auto;

        img , lottie-player{
          max-height: 287px;

          @media (max-width: 904px) {
            height: 287px !important;
          }
        }
      }
    }
  }

  &--img{
    padding-bottom: 80px;

    @media (max-width: 904px) {
      padding-bottom: 60px;
    }

    h1{
      @media (min-width: 1280px) and (max-width: 1680px) {
        font-size: 60px;
        line-height: 70px;
      }
    }

    .intro{
      line-height: 42px;

      @media (max-width: 1280px) {
        line-height: 36px;
      }

      @media (max-width: 904px) {
        line-height: 30px;
      }
    }

    .btn{
      margin-top: 56px;

      @media (max-width: 904px) {
        margin-top: 30px;
      }
    }
  }

  &--wide{
    .featured-hero__image{
      figure{
        height: 380px;
      }
    }
  }

  &--extra-wide{
    margin-bottom: 96px;
    padding-bottom: 128px;

    @media (max-width: 904px) {
      margin-bottom: 64px;
      padding-bottom: 60px;
    }

    .short-title{
      margin-top: 0;
      font-size: 40px;
      line-height: 50px;
      font-weight: $fw-light;

      @media (max-width: 1280px) {
        font-size: 32px;
        line-height: 42px;
      }

      @media (max-width: 904px) {
        font-size: 18px;
        line-height: 26px;
      }
    }

    .intro{
      margin-top: 40px;
      font-size: 24px;
      line-height: 34px;

      @media (max-width: 904px) {
        margin-top: 24px;
        font-size: 18px;
        line-height: 26px;
      }
    }

    .btn{
      margin-top: 80px;

      @media (max-width: 904px) {
        margin-top: 30px;
      }
    }

    .featured-hero__image{
      align-items: flex-start;
      margin-top: 100px;

      @media (max-width: 904px) {
        margin-top: 33px;
      }

      figure{
        height: 374px;
      }
    }
  }

  &--contact{
    padding-bottom: 68px;

    @media (max-width: 904px) {
      padding-bottom: 63px;
    }

    .contact-info{
      max-width: 624px;
      margin-top: 54px;

      @media (max-width: 904px) {
        margin-top: 42px;
      }

      .item{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        @media (max-width: 1139px) {
          flex-direction: column;
          margin-bottom: 12px;
        }

        @media (max-width: 904px) {
          margin-bottom: 8px;
        }

        &:last-child{
          margin-bottom: 0;
        }

        h6{
          width: 30%;
          font-size: 22px;
          line-height: 40px;
          font-weight: $fw-medium;

          @media (max-width: 1139px) {
            width: 100%;
          }

          @media (max-width: 904px) {
            margin-bottom: 4px;
            font-size: 18px;
            line-height: 26px;
            font-weight: $fw-semi-bold;
          }
        }

        p{
          width: calc(70% - 32px);
          font-size: 22px;
          line-height: 40px;
          font-weight: $fw-light;

          @media (max-width: 1139px) {
            width: 100%;
          }

          @media (max-width: 904px) {
            font-size: 18px;
            line-height: 26px;
          }

          .map-tip{
            font-size: 18px;
            line-height: 28px;
          }
        }
      }
    }
  }

  &--company{
    @media (min-width: 905px) {
      padding-bottom: 104px;
    }

    .featured-hero__image{
      justify-content: flex-end;
      align-items: flex-start;
      padding-top: 176px;

      @media (max-width: 904px) {
        padding-top: 0;
      }

      figure{
        max-width: 404px;
        height: auto;

        img{
          object-position: top;
        }
      }

      &.featured-hero__image--mobile{
        @media (max-width: 904px) {
          margin-top: 52px;
          justify-content: center;
        }

        figure{
          img{
            @media (max-width: 904px) {
              object-position: center;
              height: auto !important;
            }
          }
        }
      }
    }

    .share-list{
      top: calc(100% - 32px);
      right: inherit;
      left: calc(1650px + (100% - 2080px) / 2);

      @media (max-width: 1679px) {
        left: inherit;
        right: 168px;
      }

      @media (max-width: 1439px) {
        right: inherit;
        left: calc(1528px + (100% - 2080px) / 2);
      }

      @media (max-width: 1139px) {
        right: 18px;
        left: inherit;
      }

      @media (max-width: 904px) {
        top: calc(100% - 26px);
      }
    }
  }

  &--association{
    @media (min-width: 905px) {
      margin-bottom: 148px;
      padding-bottom: 84px;
    }

    h1 , p{
      max-width: 765px;
    }

    .intro{
      @media (max-width: 1280px) {
        font-size: 26px;
        line-height: 36px;
      }

      @media (min-width: 905px) {
        margin-top: 32px;
      }
    }
  }

  &--moss-green-10{
    background-color: $moss-green-10;
  }
}
