.testimonials {
  margin-bottom: 32px;

  &:last-child{
    margin-bottom: 0;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 32px 0;
    border-bottom: 1px solid #000;
    flex-wrap: wrap;

    @media (max-width: 599px) {
      padding: 16px 0;
    }

    &:first-child {
      border-top: 1px solid #000;
    }

    &--image {
      width: 30%;

      @media (max-width: 599px) {
        width: 100%;
        .img {
          padding-top: calc((167 / 291) * 100%);
        }
      }
    }

    &--text {
      font-size: 24px;
      line-height: 34px;
      width: 65.5%;
      font-weight: $fw-light;

      @media (max-width: 1139px) {
        font-size: 15px;
        line-height: 22px;
        // This size is not on the design, but there was no intermediary size in the design;
      }

      @media (max-width: 599px) {
        font-size: 14px;
        line-height: 20px;
        width: 100%;

      }

      p {
        &:first-child {
          font-weight: $fw-bold;
          margin-bottom: 16px;

          @media (max-width: 599px) {
            margin-bottom: 8px;
          }
        }
      }
    }

    &--left {
      flex-direction: row;

      @media (max-width: 599px) {
        flex-direction: column;
      }

      .testimonials__item--image {

        @media (max-width: 599px) {
          margin-bottom: 16px;
        }
      }
    }

    &--right {
      flex-direction: row-reverse;

      @media (max-width: 599px) {
        flex-direction: column;
      }

      .testimonials__item--image {

        @media (max-width: 599px) {
          margin-bottom: 16px;
        }
      }
    }
  }
}
