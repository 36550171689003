.calendar{
  margin-bottom: 32px;

  @media (max-width: 904px) {
    margin-bottom: 16px;
  }

  @media (max-width: 599px) {
    margin-bottom: 0;
  }

  &:hover{
    z-index: 1;
  }

  &:last-child{
    > a{
      @media (max-width: 599px) {
        border-bottom: 1px solid $solid-40;
      }
    }
  }

  > a{
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid $solid-40;
    transition: box-shadow 0.1s;

    &:hover{
      @media (min-width: 905px) {
        box-shadow: 6px 6px 0px $warm-80;
        position: relative;
        z-index: 1;
      }
    }

    @media (max-width: 904px) {
      flex-direction: row;
      flex-wrap: wrap;
    }

    @media (max-width: 599px) {
      border-bottom: none;
    }

    .calendar__dates{
      display: flex;
      flex-direction: row;
      padding: 24px 36px;
      border-bottom: 1px solid $solid-40;

      @media (max-width: 904px) {
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 21%;
        padding: 20px 10px;
        border-bottom: none;
        border-right: 1px solid $solid-40;
      }

      .date{
        margin-left: 24px;
        padding-left: 24px;
        border-left: 1px solid $solid-40;

        @media (max-width: 904px) {
          margin-top: 8px;
          margin-left: 0;
          padding-top: 8px;
          padding-left: 0;
          border-top: 1px solid $solid-40;
          border-left: none;
        }

        &:first-child{
          margin-left: 0;
          padding-left: 0px;
          border: none;

          @media (max-width: 904px) {
            margin-top: 0;
            padding-top: 0;
            border-top: none;
          }
        }

        p{
          &.day{
            display: inline-block;
            font-size: 40px;
            line-height: 50px;
            font-weight: $fw-light;

            @media (max-width: 904px) {
              font-size: 24px;
              line-height: 24px;
              font-weight: $fw-book;
            }

            &::after{
              content: ".";
            }

            &::before{
              content: "-";

              @media (max-width: 904px) {
                // display: none;
              }
            }

            &:first-child{
              &::before{
                display: none;
              }
            }
          }

          &.month{
            font-size: 22px;
            line-height: 22px;
            font-weight: $fw-book;

            @media (max-width: 904px) {
              display: none;
              font-size: 20px;
              line-height: 24px;
            }

            &--short{
              display: none;

              @media (max-width: 904px) {
                display: block;
              }
            }
          }
        }
      }
    }

    .calendar__info{
      position: relative;
      padding: 32px 29px 28px 36px;

      @media (max-width: 904px) {
        width: 79%;
        padding: 18px 12px 18px 22px;
      }

      &::before{
        content: "";
        display: block;
        position: absolute;
        left: 0;
        width: 10px;
        height: calc(100% - 32px - 28px);
        background-color: $red-60;

        @media (max-width: 904px) {
          width: 6px;
          height: calc(100% - 18px - 18px);
        }
      }

      .title-info{
        p{
          margin-bottom: 20px;
          font-size: 18px;
          line-height: 26px;
          font-weight: $fw-light;

          @media (max-width: 904px) {
            margin-bottom: 4px;
            font-size: 14px;
            line-height: 20px;
            font-weight: $fw-book;
          }
        }

        h6{
          color: $black;
          font-size: 24px;
          line-height: 34px;
          font-weight: $fw-book;

          @media (max-width: 904px) {
            font-size: 20px;
            line-height: 24px;
          }
        }
      }

      .location-info{
        margin-top: 20px;

        @media (max-width: 904px) {
          margin-top: 12px;
        }

        p{
          font-size: 18px;
          line-height: 26px;
          font-weight: $fw-light;

          @media (max-width: 904px) {
            font-size: 14px;
            line-height: 20px;
            font-weight: $fw-book;
          }
        }

        .canceled-tag{
          display: inline-block;
          padding: 9px 18px 10px;
          background-color: $red-error;
          color: $white;
          font-size: 16px;
          line-height: 16px;
          font-weight: $fw-semi-bold;

          @media (max-width: 904px) {
            padding: 9px 14px;
            font-size: 16px;
            line-height: 16px;
            font-weight: $fw-book;
          }

          &--registered{
            background-color: $contrast-40;
            color: $main-text-color;
            font-weight: $fw-book;
          }

          &--awaiting{
            background-color: $yellow-30;
            color: $main-text-color;
            font-weight: $fw-book;
          }
        }
      }
    }
  }

  &--highlighted{
    > a{
      .calendar__dates{
        background-color: $red-20;
      }
    }
  }

  &--list{
    @media (min-width: 905px) { // desktop only
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 0px;

      &:last-child{
        > a{
          border-bottom: 1px solid $solid-40;
        }
      }

      > a{
        flex-direction: row;
        flex-wrap: wrap;
        border-bottom: none;

        .calendar__dates{
          align-items: center;
          justify-content: center;
          width: 16%;
          padding: 28px 16px;
          border-bottom: none;
          border-right: 1px solid $solid-40;
          text-align: center;

          .date{
            p{
              &.month{
                display: block;

                &--short{
                  display: none;
                }
              }
            }
          }

          &--double{
            .date{
              p{
                &.month{
                  display: none;

                  &--short{
                    display: block;
                  }
                }
              }
            }
          }
        }

        .calendar__info{
          display: flex;
          flex-direction: row-reverse;
          justify-content: flex-end;
          align-items: center;
          flex-wrap: wrap;
          width: 84%;
          padding: 17px 54px 16px 48px;

          &::before{
            height: calc(100% - 17px - 16px);
          }

          .title-info{
            width: 73%;

            p{
              margin-bottom: 4px;
            }
          }

          .location-info{
            width: 27%;
            margin-top: 0;
            padding-right: 30px;
          }
        }
      }
    }

    @media (max-width: 1440px) and (min-width: 905px){
      > a{
        .calendar__dates{
          width: 20%;

          .date{
            margin-left: 16px;
            padding-left: 16px;
          }
        }

        .calendar__info{
          width: 80%;
        }
      }
    }

    @media (max-width: 904px) {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 0;

      &:last-child{
        > a{
          border-bottom: 1px solid $solid-40;
        }
      }

      > a{
        border-bottom: none;
      }
    }
  }

  &--yellow{
    > a{
      .calendar__info{
        &::before{
          background-color: $yellow-60;
        }
      }
    }

    &.calendar--highlighted{
      > a{
        .calendar__dates{
          background-color: $yellow-20;
        }
      }
    }
  }

  &--green{
    > a{
      .calendar__info{
        &::before{
          background-color: $contrast-60;
        }
      }
    }

    &.calendar--highlighted{
      > a{
        .calendar__dates{
          background-color: $contrast-20;
        }
      }
    }
  }

  &--contrast{
    > a{
      .calendar__info{
        &::before{
          background-color: $contrast-60;
        }
      }
    }

    &.calendar--highlighted{
      > a{
        .calendar__dates{
          background-color: $contrast-20;
        }
      }
    }
  }

  &--red{
    > a{
      .calendar__info{
        &::before{
          background-color: $red-60;
        }
      }
    }

    &.calendar--highlighted{
      > a{
        .calendar__dates{
          background-color: $red-20;
        }
      }
    }
  }

  &--blue{
    > a{
      .calendar__info{
        &::before{
          background-color: $blue-50;
        }
      }
    }

    &.calendar--highlighted{
      > a{
        .calendar__dates{
          background-color: $blue-20;
        }
      }
    }
  }

}
