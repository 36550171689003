.glossary-list{
  &__letter{
    margin-bottom: 64px;
    padding: 32px 0;
    border-top: 1px solid $solid-40;
    border-bottom: 1px solid $solid-40;
    color: $red-dark-3;
    font-size: 30px;
    line-height: 40px;
    font-weight: $fw-medium;

    @media (max-width: 904px) {
      margin-bottom: 32px;
      padding: 12px 0;
      font-size: 20px;
      line-height: 30px;
      font-weight: $fw-book;
    }
  }

  &__text{
    margin-bottom: 64px;

    @media (max-width: 904px) {
      margin-bottom: 32px;
    }

    &:last-child{
      margin-bottom: 0;
    }

    a{
      display: inline-block;

      &:hover , &:focus-visible{
        h4{
          color: $red-dark-3;
        }
      }

      h4{
        margin-bottom: 16px;
        font-size: 26px;
        line-height: 36px;
        font-weight: $fw-medium;

        @media (max-width: 904px) {
          margin-bottom: 12px;
          font-size: 18px;
          line-height: 28px;
        }
      }
    }

    p{
      font-size: 22px;
      line-height: 40px;
      font-weight: $fw-light;
      margin-bottom: 40px;

      @media (max-width: 904px) {
        font-size: 16px;
        line-height: 26px;
      }

      &:last-child{
        margin-bottom: 0px;
      }
    }
  }
}

.aplha-nav-wrapper{
  .sidebar__inner{
    .alpha-nav {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      transform: translate(46px, 0px);

      @media (max-width: 1220px) {
        transform: translate(20px, 0px);
      }

      li{
        position: relative;
        width: 24px;
        text-align: center;
        line-height: 26px;

        @media (max-width: 904px) {
          width: 20px;
          line-height: 22px;
        }

        a{
          display: block;
          width: 100%;
          height: 100%;
          color: $main-text-color;
          font-size: 15px;
          line-height: 26px;
          font-weight: $fw-medium;

          @media (max-width: 904px) {
            font-size: 10px;
            line-height: 22px;
            font-weight: $fw-medium;
          }

          &.active{
            &::after{
              content: "";
              display: block;
              position: absolute;
              top: 0;
              width: 24px;
              height: 28px;
              background-color: $contrast-40;
              border-radius: 6px;
              z-index: -1;

              @media (max-width: 904px) {
                width: 20px;
                height: 22px;
                border-radius: 4px;
              }
            }
          }

          &.disabled{
            color: $solid-60;
            font-weight: $fw-book;
            pointer-events: none;
          }
        }
      }
    }
  }
}
