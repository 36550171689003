.front-page{
  &__hero{
    margin-bottom: 136px;
    padding: 80px 0 95px;
    background-color: $warm-40;

    @media (max-width: 904px) {
      margin-bottom: 60px;
      padding: 59px 0 52px;
    }

    .text-content{
      h1{
        padding-top: 52px;

        @media (min-width: 1280px) and (max-width: 1680px) {
          font-size: 60px;
          line-height: 70px;
        }

        @media (max-width: 904px) {
          padding-top: 0;
        }
      }

      h2{
        margin-top: 24px;
        font-weight: $fw-light;
        font-size: 24px;
        line-height: 34px;

        @media (max-width: 1280px) {
          font-size: 22px;
          line-height: 32px;
        }

        @media (max-width: 904px) {
          margin-top: 32px;
          font-size: 20px;
          line-height: 30px;
        }
      }

      .btn{
        &.btn--lg{
          margin-top: 56px;

          @media (max-width: 904px) {
            margin-top: 30px;
            font-size: 19px;
            line-height: 19px;
            padding: 16px 24px;
          }

          .icon{
            &--arrow-right-32-white{
              @media (max-width: 904px) {
                width: 24px;
                height: 24px;
                background-image: url('./../images/icons/arrow/right/arrow-right-24-white.svg');
              }
            }

            &--arrow-up-right-36-white{
              @media (max-width: 904px) {
                width: 24px;
                height: 24px;
                background-image: url('./../images/icons/arrow/up-right/arrow-up-right-24-white.svg');
              }
            }
          }
        }
      }
    }


    .hero-image{
      display: flex;
      align-items: flex-start;

      figure{
        display: flex;
        align-items: center;
        width: 100%;
        height: 500px;

        img , lottie-player{
          width: 100%;
          height: 100%;
          object-fit: contain;
          object-position: bottom;
        }
      }

      &--desktop{
        @media (max-width: 904px) {
          display: none;
        }
      }

      &--mobile{
        display: none;

        @media (max-width: 904px) {
          display: flex;
          margin-top: 21px;
        }

        figure{
          max-height: 287px;
          height: auto;

          img , lottie-player{
            max-height: 287px;

            @media (max-width: 904px) {
              height: 287px !important;
            }
          }
        }
      }
    }

    .streik-module , .items-with-arrows{
      margin-top: 120px;
      margin-bottom: 0;

      @media (max-width: 904px) {
        margin-top: 60px;
      }
    }
  }
}
