.article-links{
    margin-bottom: 60px;

    &__header{
        margin-bottom: 55px;
        
        @media (max-width: 600px) {
            margin-bottom: 40px;
        }

        h2{
            font-size: 32px;
            line-height: 40px;
            font-weight: $fw-light;

            @media (max-width: 904px) {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }

    &__link{
        margin-bottom: 100px;

        @media (max-width: 904px) {
            margin-bottom: 50px;
        }

        a{
            width: 82%;
            display: block;

            @media (max-width: 904px) {
                width: 90%;
            }

            @media (max-width: 600px) {
                width: 100%;
            }

            &:hover{
                .icon{
                    -ms-transform: translate(10px,0px); /* IE 9 */
                    transform: translate(10px,0px); /* Standard syntax */
                }
            }

            h3{
                margin-bottom: 12px;
                font-size: 28px;
                line-height: 38px;
                font-weight: $fw-light;

                @media (max-width: 904px) {
                    font-size: 20px;
                    line-height: 26px;
                    font-weight: $fw-book;
                }
            }
            
            p{
                font-size: 20px;
                line-height: 28px;
                font-weight: $fw-light;

                @media (max-width: 904px) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            .icon{
                margin-top: 24px;
                transition: 0.2s;

                @media (max-width: 904px) {
                    margin-top: 16px;
                    width: 32px;
                    height: 32px;
                    background-image: url('./../images/icons/arrow/right/arrow-right-32-red.svg');
                }
            }
        }
    }
}