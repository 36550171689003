.content-page{
  // ---
}

.content-hero{
  margin-bottom: 96px;
  padding-top: 108px;

  @media (max-width: 904px) {
    margin-bottom: 67px;
    padding-top: 25px;
  }

  &__header{
    margin-bottom: 80px;

    @media (max-width: 904px) {
      margin-bottom: 44px;
    }

    .breadcrumbs__wrapper{
      margin-bottom: 136px;

      @media (max-width: 904px) {
        margin-bottom: 32px;
      }

      &+.course-tag{
        margin-top: -23px;

        @media (max-width: 904px) {
          margin-top: 51px;
        }
      }
    }

    p{
      margin-top: 48px;
      font-size: 28px;
      line-height: 42px;
      font-weight: $fw-light;

      @media (max-width: 1280px) {
        margin-top: 32px;
        font-size: 24px;
        line-height: 34px;
      }

      @media (max-width: 904px) {
        margin-top: 24px;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0px;
      }

      strong , b{
        font-weight: $fw-bold;
      }
    }

    .course-tag{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 73px;

      @media (max-width: 904px) {
        margin-bottom: 21px;
      }

      .tag-color{
        min-width: 19px;
        width: 19px;
        height: 18px;
        margin-top: 4px;
        margin-right: 16px;

        @media (max-width: 904px) {
          min-width: 14px;
          width: 14px;
          height: 14px;
          margin-right: 13px;
        }

        &--red-80{
          background-color: $red-80;
        }

        &--contrast-80{
          background-color: $contrast-80;
        }

        &--yellow{
          background-color: $yellow-60;
        }

        &--blue{
          background-color: $blue-50;
        }
      }

      span{
        font-size: 18px;
        font-weight: $fw-book;

        @media (max-width: 904px) {
          font-size: 15px;
          line-height: 22px;
          font-weight: $fw-light;
        }
      }
    }
  }

  .offgrid-wrapper{
    &__text{
      position: relative;
      padding: 104px 0;

      @media (max-width: 904px) {
        padding: 48px 0;
      }

      .share-list{
        right: 45px;
        top: -32px;

        @media (max-width: 904px) {
          right: 0;
          top: -24px;
        }
      }

      .link-list{
        ul{
          li{
            .arrow-link{
              align-items: flex-start;
              padding: 24px 18px 24px 0;

              @media (max-width: 904px) {
                padding: 16px 6px 16px;
              }

              .text-wrapper{
                h4{
                  display: flex;
                  font-size: 22px;
                  line-height: 32px;
                  font-weight: $fw-light;

                  @media (max-width: 904px) {
                    font-size: 16px;
                    line-height: 24px;
                  }

                  .num{
                    display: inline-block;
                    min-width: 17px;
                    margin-right: 24px;

                    &::after{
                      content: ".";
                    }

                    @media (max-width: 904px) {
                      min-width: 13px;
                      margin-right: 16px;
                    }
                  }
                }
              }

              .icon{
                &--arrow-down-32-red{
                  @media (max-width: 904px) {
                    width: 24px;
                    height: 24px;
                    background-image: url('./../images/icons/arrow/down/arrow-down-24-red.svg');
                  }
                }

                &--arrow-right-32-red{
                  @media (max-width: 904px) {
                    width: 24px;
                    height: 24px;
                    background-image: url('./../images/icons/arrow/right/arrow-right-24-red.svg');
                  }
                }

                &--arrow-up-right-36-red{
                  @media (max-width: 904px) {
                    width: 24px;
                    height: 24px;
                    background-image: url('./../images/icons/arrow/up-right/arrow-up-right-24-red.svg');
                  }
                }
              }
            }
          }
        }
      }

      .teaser{
        font-size: 28px;
        line-height: 42px;
        font-weight: $fw-light;

        @media (max-width: 1280px) {
          font-size: 24px;
          line-height: 34px;
        }

        @media (max-width: 904px) {
          margin-bottom: 42px;
          font-size: 18px;
          line-height: 28px;
        }
      }

      .offgrid__col{
        &--right{
          @media (max-width: 1139px) and (min-width: 905px) {
            padding-right: 46px;
          }

          .link-list{
            ul{
              @media (max-width: 904px) {
                margin-top: -1px;
              }
            }
          }
        }
      }
    }

    &--warm-60{
      background-color: $warm-60;
    }
  }

  &--image{
    .col-md-6{
      &:nth-child(2){
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
    }

    .content-hero__header{
      margin-bottom: 48px;

      @media (max-width: 904px) {
        margin-bottom: 32px;
      }

      h1{
        // position: absolute;
        width: 100%;
        // padding-right: 74px;

        // @media (max-width: 904px) {
        //   position: relative;
        //   padding-right: 0;
        // }
      }
    }

    .image-wrapper{
      max-width: 88%;
      padding-top: 158px;
      margin-bottom: -112px;

      @media (max-width: 904px) {
        max-width: 100%;
        padding-top: 0;
        margin-bottom: -32px;
      }

      figure{
        @media (max-width: 904px) {
          padding-top: calc((200 / 292) * 100%);
        }
      }
    }

    .offgrid-wrapper{
      &__text{
        padding: 200px 0 88px;

        @media (max-width: 904px) {
          padding: 72px 0 47px;
        }

        .share-list{
          right: 45px;
          top: 24px;

          @media (max-width: 1680px) {
            right: 2.2vw;
          }

          @media (max-width: 904px) {
            right: 12px;
            top: 8px;
          }
        }
      }
    }
  }

  &--basic{
    .content-hero__header{
      margin-bottom: 0;

      @media (max-width: 904px) {
        margin-bottom: 44px;
      }

      h1{
        margin-bottom: 48px;

        @media (max-width: 904px) {
          margin-bottom: 0;
        }
      }

      p{
        margin-bottom: 80px;

        @media (max-width: 904px) {
          margin-top: 24px;
          margin-bottom: 0;
        }
      }
    }

    .offgrid-wrapper{
      &__text{
        padding: 80px 0 77px;

        @media (max-width: 904px) {
          padding: 54px 0 42px;
        }

        .teaser{
          margin-bottom: 0;
        }
      }
    }

    &.content-hero--image{
      .offgrid-wrapper{
        &__text{
          padding: 150px 0 60px;

          @media (max-width: 904px) {
            padding-top: 74px;
          }
        }
      }
    }
  }

  &--info{
    .offgrid-wrapper{
      &__text{
        padding: 50px 0;

        @media (max-width: 904px) {
          padding: 54px 0 42px;
        }

        .teaser{
          font-size: 18px;
          line-height: 26px;
          color: $solid-80;

          @media (max-width: 904px) {
            font-size: 14px;
            line-height: 24px;
            font-weight: $fw-book;
          }
        }
      }
    }

    &.content-hero--image{
      .offgrid-wrapper{
        &__text{
          padding: 150px 0 60px;

          @media (max-width: 904px) {
            padding-top: 74px;
          }
        }
      }
    }
  }

  &--course{
    .content-hero__header{
      @media (min-width: 905px) {
        margin-bottom: 104px;
      }
    }

    .offgrid-wrapper{
      &__text{
        @media (min-width: 905px) {
          padding: 98px 0;
        }

        .offgrid__col{
          .course-information{
            li{
              display: flex;
              flex-direction: row;
              padding: 12px 0 12px 15px;
              border-bottom: 1px solid $solid-40;

              @media (max-width: 904px) {
                padding-left: 0;
              }

              &:first-child{
                border-top: 1px solid $solid-40;
              }

              h6{
                margin-right: 4px;
                font-size: 20px;
                line-height: 28px;
                font-weight: $fw-medium;

                @media (max-width: 904px) {
                  font-size: 16px;
                  line-height: 24px;
                }
              }

              p{
                font-size: 20px;
                line-height: 28px;
                font-weight: $fw-light;

                @media (max-width: 904px) {
                  font-size: 16px;
                  line-height: 24px;
                }
              }
            }
          }

          .course-action-button{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-end;
            margin-top: auto;
            margin-left: auto;
            height: 100%;

            @media (max-width: 904px) {
              height: auto;
              margin-left: 0;
            }

            .btn{
              margin-top: 21px;

              @media (max-width: 904px) {
                padding: 8px 16px;
                font-size: 15px;
                line-height: 26px;
                font-weight: $fw-book;
                border-radius: 6px;
              }

              &:first-child{
                margin-top: 0;
              }

              .icon{
                &--download{
                  @media (max-width: 904px) {
                    width: 24px;
                    height: 24px;
                    background-image: url('./../images/icons/download/download-24-white.svg');
                  }

                  path{
                    @media (max-width: 904px) {
                      display: none;
                    }
                  }
                }

                &--arrow-right-32-white{
                  @media (max-width: 904px) {
                    width: 24px;
                    height: 24px;
                    background-image: url('./../images/icons/arrow/right/arrow-right-24-white.svg');
                  }
                }

                &--plus-32-white{
                  @media (max-width: 904px) {
                    width: 24px;
                    height: 24px;
                    background-image: url('./../images/icons/plus/plus-24-white.svg');
                  }
                }
              }
            }

            p{
              margin-top: 11px;
              font-size: 18px;
              line-height: 26px;
              font-weight: $fw-light;

              @media (max-width: 904px) {
                margin-top: 9px;
                font-size: 15px;
                line-height: 22px;
              }
            }
          }

          &--right{
            display: flex;

            .course-action-button{
              @media (max-width: 904px) {
                margin-top: 47px;
              }
            }
          }
        }
      }
    }
  }

  &--image-lg{
    .content-hero__header{
      margin-bottom: 75px;

      @media (max-width: 904px) {
        margin-bottom: 34px;
      }
    }

    .content-hero__image {
      max-width: 94.5%;
      margin-bottom: -111px;
      position: relative;
      z-index: 1;

      @media (max-width: 904px) {
        margin-bottom: -56px;
        max-width: 100%;
      }

      .img {
        position: relative;

        @media (max-width: 904px) {
          padding-top: calc((200 / 292) * 100%);
        }
      }
    }

    .offgrid-wrapper{
      &__text{
        padding: 111px 0 70px;

        @media (max-width: 904px) {
          padding: 56px 0 49px;
        }

        .share-list{
          top: 21px;
          right: 42px;

          @media (max-width: 1679px) {
            right: 2.7%;
          }

          @media (max-width: 904px) {
            top: 26px;
            right: 13px;
          }
        }

        .image-caption{
          margin-top: 32px;
          font-size: 18px;
          line-height: 26px;
          font-weight: $fw-light;
          color: $solid-80;

          @media (max-width: 904px) {
            margin-top: 24px;
            font-size: 14px;
            line-height: 20px;
            font-weight: $fw-book;
          }
        }

        .offgrid__col{
          margin-top: 96px;

          @media (max-width: 904px) {
            margin-top: 0;
          }

          &--left{
            @media (max-width: 904px) {
              margin-top: 32px;
            }
          }

          &--right{
            .course-action-button{
              @media (max-width: 904px) {
                margin-top: 40px;
              }
            }
          }
        }
      }
    }
  }
}
