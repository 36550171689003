.entry-cards{
    margin-bottom: 72px;

    &:last-child{
        margin-bottom: -64px;

        @media (max-width: 904px) {
            margin-bottom: 0;
        }
    }

    &__card{
        margin-bottom: 64px;

        @media (max-width: 904px) {
            margin-bottom: 42px;
        }

        &:last-child{
            @media (max-width: 904px) {
                margin-bottom: 0px;
            }
        }

        a{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: 100%;
            background-color: $warm-40;

            .text{
                position: relative;
                height: 100%;
                display: flex;
                flex-direction: column;
                padding: 40px 47px 40px;

                @media (max-width: 904px) {
                    padding: 28px 36px 25px 32px;
                }

                h3{
                    font-size: 28px;
                    line-height: 38px;
                    font-weight: $fw-light;

                    @media (max-width: 904px) {
                        font-size: 20px;
                        line-height: 27px;
                    }
                }

                p{
                    margin-top: 8px;
                    margin-bottom: 32px;
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: $fw-light;

                    @media (max-width: 904px) {
                        margin-top: 6px;
                        margin-bottom: 12px;
                        font-size: 16px;
                        line-height: 22px;
                    }
                }

                .icon{
                    margin-top: auto;

                    @media (max-width: 904px) {
                        margin-top: 0;
                        width: 32px;
                        height: 32px;
                        background-image: url('./../images/icons/arrow/right/arrow-right-32-red.svg');
                    }
                }
            }

            &.arrow-link--animation-up-right{
                .text{
                    .icon{
                        @media (max-width: 904px) {
                            width: 32px;
                            height: 32px;
                            background-image: url('./../images/icons/arrow/up-right/arrow-up-right-36-red.svg');
                        }
                    }
                }
            }
        }
    }

    &--6clm{
        .entry-cards__card{
            a{
                figure{
                    @media (max-width: 904px) {
                        padding-top: calc((232 / 450) * 100%);
                    }
                }

                .text{
                    padding: 48px 76px 40px;

                    @media (max-width: 904px) {
                        padding: 28px 36px 25px;
                    }

                    p{
                        @media (min-width: 904px) {
                            margin-top: 12px;
                            margin-bottom: 12px;
                        }
                    }
                }
            }
        }
    }
}
