// Resets
body{
    overflow-x: hidden;
}

ul , ol{
    margin: 0;
    padding: 0;
    list-style: none;
}

h1, h2, h3, h4, h5, h6, p{
    margin: 0;
}

figure{
    margin: 0;
}

button{
    color: $main-text-color;
}

.scroll-top-arrow{
    display: none;
    position: fixed;
    bottom: 64px;
    left: calc(1520px + ((100vw - 1600px)/2));
    z-index: 1;

    @media (max-width: 1680px) {
        left: inherit;
        right: 60px;
    }

    @media (max-width: 904px) {
        right: 20px;
        bottom: 20px;
    }

    button{
        display: flex;
        align-items: center;
        justify-content: center;
        width: 64px;
        height: 64px;
        background-color: $warm-20;
        border-radius: 100%;

        @media (max-width: 904px) {
            width: 48px;
            height: 48px;
        }

        .icon{
            @media (max-width: 904px) {
                width: 18px;
                height: 18px;
                background-image: url('./../images/icons/arrow/up/arrow-up-16-red.svg');
            }
        }
    }
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// Margin classes
.mt-0{
    margin-top: 0 !important;
}

.mr-0{
  margin-right: 0 !important;
}

.mb-0{
    margin-bottom: 0 !important;
}

.ml-0{
    margin-left: 0 !important;
}

// padding- classes
.pt-0{
  padding-top: 0 !important;
}

.pr-0{
  padding-right: 0 !important;
}

.pb-0{
  padding-bottom: 0 !important;
}

.pl-0{
  padding-left: 0 !important;
}


.bg-overlay{
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: $black;
    opacity: 0.7;
    cursor: pointer;
    z-index: 2;
}

.stroke-red{
    path{
        stroke: $red-100;
    }
}

.stroke-white{
    path{
        stroke: $white;
    }
}

.show-desktop{
    @media (max-width: 904px) {
        display: none;
    }
}

.show-mobile{
    display: none;

    @media (max-width: 904px) {
        display: block;
    }
}

.offgrid-wrapper{
    background-color: $warm-40;
    width: calc(1680px + (100% - 2080px) / 2);

    @media (max-width: 1680px) {
        width: calc(100% - 200px);
    }

    @media (max-width: 1440px) {
        width: calc(1040px + (100% - 1040px) / 2);
    }

    @media (max-width: 1140px) {
        width: calc(100% - 50px);
    }

    @media (max-width: 904px) {
        width: 100vw;
    }

    &__text{
        max-width: 1280px;
        margin-left: auto;

        @media (max-width: 1680px) {
            width: calc(100vw - 416px);
        }

        @media (max-width: 1440px) {
            width: 1040px;
        }

        @media (max-width: 1140px) {
            width: calc(100% - 50px);
        }

        @media (max-width: 904px) {
            width: calc(100% - 64px);
            margin: 0 auto;
        }
    }
}

.pubdate , time[pubdate]{
  display: block;
  white-space: nowrap;
  margin-bottom: 8px;
  color: $solid-80;
  font-size: 18px;
  line-height: 18px;
  font-weight: $fw-light;

  @media (max-width: 904px) {
      font-size: 14px;
      line-height: 20px;
  }
}

.line-clamp{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;

    &--2{
        -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2;
    }

    &--3{
        -webkit-line-clamp: 3; /* number of lines to show */
                line-clamp: 3;
    }
}

main.page{
    padding-bottom: 200px;

    @media (max-width: 904px) {
        padding-bottom: 136px;
    }
}

.share-list {
    position: absolute;
    right: -32px;
    top: calc(100% - 24px - 64px);
    z-index: 1;
    background-color: $warm-20;
    border-radius: 32px;
    height: 64px;
    overflow-y: hidden;
    padding: 6px 0;

    @media (max-width: 904px) {
      height: 48px;
      right: 5px;
      top: calc(100% - 30px);
      padding: 0;
    }

    &.active {
      box-shadow: -4px 4px 11px rgba(0, 0, 0, 0.25);
      height: auto;
      background-color: $white;
    }

    li {
      height: 52px;

      @media (max-width: 904px) {
        height: 50px;
      }

      button , a {
        display: block;
        width: 64px;
        height: 52px;
        background-repeat: no-repeat;
        background-size: 32px;
        background-position: center;
        border-radius: 100px;

        @media (max-width: 904px) {
          width: 48px;
          height: 50px;
          background-size: 24px;
        }
      }
    }

    .network-button {
      background-image: url('./../images/icons/network/network-32-red.svg');

      @media (max-width: 904px) {
        background-image: url('./../images/icons/network/network-24-red.svg');
      }
    }

    &--facebook {
      background-image: url('./../images/icons/network/facebook-32-red.svg');

      @media (max-width: 904px) {
        background-image: url('./../images/icons/network/facebook-24-red.svg');
      }
    }

    &--linkedin {
      background-image: url('./../images/icons/network/linkedin-32-red.svg');

      @media (max-width: 904px) {
        background-image: url('./../images/icons/network/linkedin-24-red.svg');
      }
    }

    &--email {
      background-image: url('./../images/icons/network/envelope-32-red.svg');

      @media (max-width: 904px) {
        background-image: url('./../images/icons/network/envelope-24-red.svg');
      }
    }

    &--link {
      background-image: url('./../images/icons/network/link-32-red.svg');

      @media (max-width: 904px) {
        background-image: url('./../images/icons/network/link-24-red.svg');
      }
    }
  }

.pagination{
    margin-top: 48px;

    @media (max-width: 904px) {
        margin-top: 40px;
    }

    &__wrapper{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        input{
            width: 40px;
            height: 37px;
            margin: 0 12px;
            text-align: center;
            font-size: 18px;
            line-height: normal;
            border: 1px solid $solid-40;
            -webkit-appearance: none;
            border-radius: 5px !important;
        }

        p{
            margin-left: 16px;
            font-size: 18px;
            line-height: normal;
            font-weight: $fw-book;
        }
    }
}
.loader-wrapp {
  overflow: hidden;
}
.loader,
.loader:after {
  border-radius: 50%;
  width: 149px;
  height: 149px;
}
.loader {
  margin: 0px auto;
  font-size: 10px;
  position: relative;
  text-indent: -9999em;
  border-top: 1.6em solid transparentize($red-100, 0.5);
  border-right: 1.6em solid transparentize($red-100, 0.5);
  border-bottom: 1.6em solid transparentize($red-100, 0.5);
  border-left: 1.6em solid $red-100;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation: load8 1.1s infinite linear;
  animation: load8 1.1s infinite linear;
}
@-webkit-keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load8 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.user-card__avatar , .avatar-color{
  background-color: transparent;
}

.hide-mobile{
  @media (max-width: 904px) {
      display: none;
  }
}
