.icon{
    display: inline-block;
    background-size: contain;
    background-repeat: no-repeat;
    flex-shrink: 0;

    // Arrow Up
    &--arrow-up-16-red{
        width: 16px;
        height: 16px;
        background-image: url('./../images/icons/arrow/up/arrow-up-16-red.svg');
    }

    // Arrow Up
    &--arrow-up-32-red{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/arrow/up/arrow-up-32-red.svg');
    }

    // Arrow Right
    &--arrow-right-16-red{
        width: 16px;
        height: 16px;
        background-image: url('./../images/icons/arrow/right/arrow-right-16-red.svg');
    }

    &--arrow-right-24{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/arrow/right/arrow-right-24.svg');
    }

    &--arrow-right-24-red{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/arrow/right/arrow-right-24-red.svg');
    }

    &--arrow-right-24-white{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/arrow/right/arrow-right-24-white.svg');
    }

    &--arrow-right-32-red{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/arrow/right/arrow-right-32-red.svg');
    }

    &--arrow-right-32-white{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/arrow/right/arrow-right-32-white.svg');
    }

    &--arrow-right-48-white{
        width: 48px;
        height: 48px;
        background-image: url('./../images/icons/arrow/right/arrow-right-48-white.svg');
    }

    &--arrow-right-48-red{
        width: 48px;
        height: 48px;
        background-image: url('./../images/icons/arrow/right/arrow-right-48-red.svg');
    }

    &--arrow-right-64-red{
        width: 64px;
        height: 64px;
        background-image: url('./../images/icons/arrow/right/arrow-right-64-red.svg');
    }

    &--arrow-right-64-white{
        width: 64px;
        height: 64px;
        background-image: url('./../images/icons/arrow/right/arrow-right-64-white.svg');
    }


    // Arrow Down
    &--arrow-down-32-red{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/arrow/down/arrow-down-32-red.svg');
    }

    &--arrow-down-24-red{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/arrow/down/arrow-down-24-red.svg');
    }

    &--arrow-down-16-white{
        width: 16px;
        height: 16px;
        background-image: url('./../images/icons/arrow/down/arrow-down-16-white.svg');
    }

    &--arrow-down-24-white{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/arrow/down/arrow-down-24-white.svg');
    }

    // Arrow Left
    &--arrow-left-16-red{
        width: 16px;
        height: 16px;
        background-image: url('./../images/icons/arrow/left/arrow-left-16-red.svg');
    }

    &--arrow-left-24-red{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/arrow/left/arrow-left-24-red.svg');
    }

    &--arrow-left-32-red{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/arrow/left/arrow-left-32-red.svg');
    }

    &--arrow-left-24-white{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/arrow/left/arrow-left-24-white.svg');
    }

    &--arrow-left-32-white{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/arrow/left/arrow-left-32-white.svg');
    }

    &--arrow-left-48-red{
        width: 48px;
        height: 48px;
        background-image: url('./../images/icons/arrow/left/arrow-left-48-red.svg');
    }

    //---------------------------------------------------------------------------------

    // Arrow Up-Right
    &--arrow-up-right-24-red{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/arrow/up-right/arrow-up-right-24-red.svg');
    }

    &--arrow-up-right-24-white{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/arrow/up-right/arrow-up-right-24-white.svg');
    }

    &--arrow-up-right-36{
        width: 36px;
        height: 36px;
        background-image: url('./../images/icons/arrow/up-right/arrow-up-right-36.svg');
    }

    &--arrow-up-right-36-red{
        width: 36px;
        height: 36px;
        background-image: url('./../images/icons/arrow/up-right/arrow-up-right-36-red.svg');
    }

    &--arrow-up-right-36-white{
        width: 36px;
        height: 36px;
        background-image: url('./../images/icons/arrow/up-right/arrow-up-right-36-white.svg');
    }

    &--arrow-up-right-48-red{
        width: 48px;
        height: 48px;
        background-image: url('./../images/icons/arrow/up-right/arrow-up-right-48-red.svg');
    }

    &--arrow-up-right-48-white{
        width: 48px;
        height: 48px;
        background-image: url('./../images/icons/arrow/up-right/arrow-up-right-48-white.svg');
    }

    &--arrow-up-right-64{
        width: 64px;
        height: 64px;
        background-image: url('./../images/icons/arrow/up-right/arrow-up-right-64.svg');
    }

    &--arrow-up-right-64-red{
        width: 64px;
        height: 64px;
        background-image: url('./../images/icons/arrow/up-right/arrow-up-right-64-red.svg');
    }

    &--arrow-up-right-64-white{
        width: 64px;
        height: 64px;
        background-image: url('./../images/icons/arrow/up-right/arrow-up-right-64-white.svg');
    }

    //---------------------------------------------------------------------------------

    // Chat
    &--chat-24{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/chat/chat-24.svg');
    }

    //---------------------------------------------------------------------------------

    // Check
    &--check-24-white{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/check/check-24-white.svg');
    }

    &--check-24-green{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/check/check-24-green.svg');
    }

    &--check-32-green{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/check/check-32-green.svg');
    }

    &--check-32-white{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/check/check-32-white.svg');
    }

    //---------------------------------------------------------------------------------

    // Chevron
    &--chevron-down-24-red{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/chevron/chevron-down-24-red.svg');
    }

    &--chevron-right-24-red{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/chevron/chevron-right-24-red.svg');
    }

    &--chevron-right-32-red{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/chevron/chevron-right-32-red.svg');
    }

    //---------------------------------------------------------------------------------

    // Download
    &--download{
        path{
            transition: 0.2s;
        }
    }

    &--download-32-red{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/download/download-32-red.svg');
    }

    &--download-24-red{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/download/download-24-red.svg');
    }

    &--download-24-white{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/download/download-24-white.svg');
    }

    //---------------------------------------------------------------------------------

    // Exclamation
    &--exclamation-32-red{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/exclamation/exclamation-32-red.svg');
    }

    //---------------------------------------------------------------------------------
    //---------------------------------------------------------------------------------

    // Filter
    &--filter-24{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/filter/filter-24.svg');
    }

    //---------------------------------------------------------------------------------

    // List (menu)
    &--list-24{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/list/list-24.svg');
    }

    //---------------------------------------------------------------------------------

    // List (dashes)
    &--list-dashes-32{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/list-dashes/list-dashes-32.svg');
    }

    &--list-dashes-48{
        width: 48px;
        height: 48px;
        background-image: url('./../images/icons/list-dashes/list-dashes-48.svg');
    }

    //---------------------------------------------------------------------------------

    // minus
    &--minus-16-red{
        width: 16px;
        height: 16px;
        background-image: url('./../images/icons/minus/minus-16-red.svg');
    }

    &--minus-24-red{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/minus/minus-24-red.svg');
    }

    //---------------------------------------------------------------------------------

    // Pencil
    &--pencil-24{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/pencil/pencil-24.svg');
    }

    &--pencil-24-red{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/pencil/pencil-24-red.svg');
    }

    //---------------------------------------------------------------------------------

    // Play
    &--play-circle-white{
        width: 130px;
        height: 130px;
        background-image: url('./../images/icons/play/play-circle-white.svg');
    }

    //---------------------------------------------------------------------------------

    // Plus
    &--plus-48-red{
        width: 48px;
        height: 48px;
        background-image: url('./../images/icons/plus/plus-48-red.svg');
    }

    &--plus-32-red{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/plus/plus-32-red.svg');
    }

    &--plus-24-red{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/plus/plus-24-red.svg');
    }

    &--plus-16-red{
        width: 16px;
        height: 16px;
        background-image: url('./../images/icons/plus/plus-16-red.svg');
    }

    &--plus-64-red{
        width: 64px;
        height: 64px;
        background-image: url('./../images/icons/plus/plus-64-red.svg');
    }

    &--plus-24-white{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/plus/plus-24-white.svg');
    }

    &--plus-16-white{
        width: 16px;
        height: 16px;
        background-image: url('./../images/icons/plus/plus-16-white.svg');
    }

    &--plus-32-white{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/plus/plus-32-white.svg');
    }

    //---------------------------------------------------------------------------------

    // Reply
    &--reply-24-red{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/reply/reply-24-red.svg');
    }

    //---------------------------------------------------------------------------------

    // Search
    &--search-16-black{
        width: 16px;
        height: 16px;
        background-image: url('./../images/icons/search/search-16-black.svg');
    }

    &--search-24{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/search/search-24.svg');
    }

    &--search-24-black{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/search/search-24-black.svg');
    }

    &--search-32{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/search/search-32.svg');
    }

    //---------------------------------------------------------------------------------

    // Signout
    &--signout-16-red{
        width: 16px;
        height: 16px;
        background-image: url('./../images/icons/signout/signout-16-red.svg');
    }

    &--signout-24-red{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/signout/signout-24-red.svg');
    }

    //---------------------------------------------------------------------------------

    // Squares
    &--squares-32{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/squares/squares-32.svg');
    }

    &--squares-48{
        width: 48px;
        height: 48px;
        background-image: url('./../images/icons/squares/squares-48.svg');
    }

    //---------------------------------------------------------------------------------

    // Trash
    &--trash-24{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/trash/trash-24.svg');
    }

    //---------------------------------------------------------------------------------

    // User
    &--user-24{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/user/user-24.svg');
    }

    //---------------------------------------------------------------------------------

    // X
    &--x-24-black{
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/x/x-24.svg');
    }

    &--x-32-red{
        width: 32px;
        height: 32px;
        background-image: url('./../images/icons/x/x-32-red.svg');
    }

    &--x-12-white{
        width: 12px;
        height: 12px;
        background-image: url('./../images/icons/x/x-12-white.svg');
    }

    //---------------------------------------------------------------------------------

}

@mixin download-icon-hover{
    path{
        &:nth-child(1) , &:nth-child(2){
            animation-duration: 0.7s;
            animation-name: download-icon-animation;
            animation-iteration-count: 1;
            animation-direction: normal;
            animation-timing-function: ease-out;
        }
    }
}

@keyframes download-icon-animation {
    0%{
        -ms-transform: translate(0px,0%); /* IE 9 */
        transform: translate(0px,0%); /* Standard syntax */
    }

    35%{
        -ms-transform: translate(0px,100%); /* IE 9 */
        transform: translate(0px,100%); /* Standard syntax */
    }

    35.1%{
        -ms-transform: translate(0px,-100%); /* IE 9 */
        transform: translate(0px,-100%); /* Standard syntax */
    }

    50%{
        -ms-transform: translate(0px,-20%); /* IE 9 */
        transform: translate(0px,-20%); /* Standard syntax */
    }

    80%{
        -ms-transform: translate(0px,0%); /* IE 9 */
        transform: translate(0px,0%); /* Standard syntax */
    }

    100%{
        -ms-transform: translate(0px,0%); /* IE 9 */
        transform: translate(0px,0%); /* Standard syntax */
    }
}
