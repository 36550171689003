.my-page {
    // --
}

.user-links-section {
    margin-bottom: 190px;

    @media (max-width: 904px) {
        margin-bottom: 64px;
    }

    .top-section__fig {
        display: flex;
        flex-direction: column;

        figure {
            display: flex;
            align-items: center;
            width: 100%;
            max-width:510px;
            height: 520px;
            margin: 0 auto;

            @media (max-width: 904px) {
                display: none;
            }

            img,
            lottie-player {
                width: 100%;
                height: 100%;
                object-fit: contain;
                object-position: bottom;
            }
        }

        .btn--wide {
            margin-left: auto;
            margin-right: auto;
            margin-top: 64px;
        }
    }
}

.user-card {
    display: flex;
    flex-direction: row;
    flex-shrink: 0;
    max-width: 375px;
    margin-bottom: 62px;

    @media (max-width: 904px) {
        margin-bottom: 38px;
    }

    &__avatar {
        width: 64px;
        min-width: 64px;
        height: 64px;
        margin-right: 32px;
        border-radius: 100%;
        color: $white;
        font-weight: $fw-bold;
        text-align: center;
        font-size: 28px;
        line-height: 60px;

        @media (max-width: 904px) {
            width: 54px;
            min-width: 54px;
            height: 54px;
            margin-right: 22px;
            font-size: 24px;
            line-height: 50px;
        }
    }

    &__info {
        .name {
            font-size: 36px;
            line-height: 48px;
            font-weight: $fw-light;

            @media (max-width: 904px) {
                font-size: 24px;
                line-height: normal;
                font-weight: $fw-book;
            }
        }

        .intro {
            margin-top: 4px;
            color: $solid-100;
            font-size: 15px;
            line-height: 22px;
            font-weight: $fw-light;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* number of lines to show */
            -webkit-box-orient: vertical;

            @media (max-width: 904px) {
                font-size: 16px;
                line-height: 18px;
                font-weight: $fw-light;
            }
        }
    }
}
