.nyheter{
  &__header{
    padding: 96px 0 80px;

    @media (max-width: 904px) {
      padding: 25px 0 24px;
    }

    .breadcrumbs__wrapper{
      margin-bottom: 144px;

      @media (max-width: 904px) {
        margin-bottom: 48px;
      }
    }

    .header-content{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;

      .title{
        padding-right: 32px;

        @media (max-width: 904px) {
          padding-right: 16px;
        }
      }

      .btn-wrapper{
        display: flex;
        flex-direction: row;

        .btn{
          &--view{
            margin-right: 16px;

            &:last-child{
              margin-right: 0;
            }

            @media (max-width: 904px) {
              display: none;
            }
          }

          &--grid-view{
            margin-left: 43px;
          }
        }
      }
    }

    .header-ingress{
      margin-top: 48px;

      @media (max-width: 904px) {
        margin-top: 24px;
      }

      .title--h6{
        @media (max-width: 904px) {
          font-size: 18px;
          line-height: 26px;
          letter-spacing: 0px;
        }
      }
    }
  }

  &__previews{
    .article-previews{
      &:last-child{
        margin-bottom: 0px;
      }

      &--list{
        margin-bottom: 0px;

        &+.article-previews--list{
          .arrow-card-wrapper{
            .arrow-card{
              &:first-child{
                .arrow-link{
                  .text{
                    border: none;
                  }
                }
              }
            }
          }
        }
      }
    }
  }


  .pagination{
    margin-top: 48px;
    margin-bottom: 136px;

    @media (max-width: 904px) {
      margin-bottom: 75px;
    }

    &:last-child{
      margin-bottom: 0;
    }

    &__wrapper{
      padding-top: 48px;
      border-top: 1px solid $solid-40;

      @media (max-width: 904px) {
        padding-top: 40px;
      }
    }

    &--list-view{
      margin-top: 0;

      .pagination__wrapper{
        border-top: none;
      }
    }
  }
}
