.article-previews{
    overflow: hidden;

    &__header{
        margin-bottom: 32px;

        @media (max-width: 904px) {
            margin-bottom: 18px;
        }

        .title--h4 , .title--h2{
            padding-right: 30px;
        }
    }

    &--single{
        margin-bottom: 104px;

        @media (max-width: 904px) {
            margin-bottom: 48px;
        }

        &:last-child{
            margin-bottom: 0;
        }

        a{
            display: block;
            pointer-events: none;

            .image-wrapper{
                max-width: 94%;
                margin-bottom: -112px;
                pointer-events: all;

                @media (max-width: 904px) {
                    min-width: 100%;
                    margin-bottom: -58px;
                }

                figure{
                    @media (max-width: 904px) {
                        padding-top: calc((190 / 292) * 100%);
                    }
                }
            }

            .offgrid-wrapper{ // component styles in global.scss
                &__text{
                    padding-top: 112px;
                    pointer-events: all;

                    @media (max-width: 904px) {
                        padding-top: 58px;
                    }

                    .content{
                        display: flex;
                        flex-direction: row;
                        padding-top: 48px;
                        padding-bottom: 48px;

                        @media (max-width: 904px) {
                            flex-direction: column;
                            padding-top: 24px;
                            padding-bottom: 39px;
                        }

                        .pubdate , time{
                            margin-top: 17px;
                            margin-right: 72px;
                            margin-bottom: 0;

                            @media (max-width: 904px) {
                                margin-top: 0;
                                margin-right: 0;
                                margin-bottom: 8px;
                            }
                        }

                        header{
                            width: 100%;
                            padding-right: 17%;

                            @media (max-width: 904px) {
                                padding-right: 0;
                            }

                            .title--h4{
                                @media (max-width: 904px) {
                                    font-size: 30px;
                                    line-height: 40px;
                                    letter-spacing: -0.5px;
                                }
                            }

                            p{
                                margin-top: 16px;
                                font-size: 20px;
                                line-height: 28px;
                                font-weight: $fw-light;

                                @media (max-width: 904px) {
                                    margin-top: 6px;
                                    font-size: 18px;
                                    line-height: 26px;
                                }
                            }

                            .icon{
                                margin-top: 16px;
                                display: block;
                                margin-left: 0;

                                @media (max-width: 904px) {
                                    margin-top: 8px;
                                }

                                &--arrow-right-32-red , &--arrow-right-48-red{
                                    @media (max-width: 904px) {
                                        width: 32px;
                                        height: 32px;
                                        background-image: url('./../images/icons/arrow/right/arrow-right-32-red.svg');
                                    }
                                }

                                &--arrow-up-right-36-red , &--arrow-up-right-48-red{
                                    @media (max-width: 904px) {
                                        width: 36px;
                                        height: 36px;
                                        background-image: url('./../images/icons/arrow/up-right/arrow-up-right-36-red.svg');
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.article-previews--list{
            .arrow-link{
                > .container{
                    display: none;
                }

                .offgrid-wrapper{
                    width: 100%;
                    background-color: transparent;

                    &__text{
                        position: relative;
                        width: 100%;
                        padding: 32px 14%;
                        border-top: 1px solid $solid-100;
                        border-bottom: 1px solid #464646;

                        @media (max-width: 904px) {
                            padding: 16px 0;
                        }

                        .content{
                            padding: 0;

                            .pubdate , time[pubdate]{
                                position: absolute;
                                left: 0;
                                margin-top: 10px;
                                margin-right: 0;

                                @media (max-width: 904px) {
                                    position: relative;
                                    margin-top: 0;
                                }
                            }

                            header{
                                padding: 0;

                                .title{
                                    font-size: 28px;
                                    line-height: 38px;

                                    @media (max-width: 904px) {
                                        font-size: 20px;
                                        line-height: 27px;
                                    }
                                }

                                p{
                                    margin-top: 8px;

                                    @media (max-width: 904px) {
                                        margin-top: 6px;
                                        font-size: 14px;
                                        line-height: 20px;
                                    }
                                }

                                .icon{
                                    position: absolute;
                                    right: 15px;
                                    top: 32px;
                                    margin: 0;

                                    @media (max-width: 904px) {
                                        position: relative;
                                        top: inherit;
                                        right: inherit;
                                        left: inherit;
                                        margin-top: 8px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &--4clm , &--6clm{
        margin-bottom: 104px;

        @media (max-width: 904px) {
            margin-bottom: 48px;
        }

        &:last-child{
            @media (max-width: 904px) {
                margin-bottom: 0px;
            }
        }
    }
}
