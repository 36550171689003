.contact-table{
  margin-bottom: 136px;

  @media (max-width: 904px) {
    margin-bottom: 56px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  &__item{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 48px 0;
    border-top: 1px solid $solid-100;

    &:last-child{
      border-bottom: 1px solid $solid-100;
    }

    @media (max-width: 904px) {
      flex-direction: column;
      padding: 32px 0;
    }

    header{
      width: 43%;
      padding-right: 112px;

      @media (max-width: 904px) {
        width: 100%;
        margin-bottom: 18px;
        padding: 0;
      }

      .title{
        @media (max-width: 904px) {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }

    .text{
      width: 57%;

      @media (max-width: 904px) {
        width: 100%;
      }

      p{
        font-size: 22px;
        line-height: 40px;
        font-weight: $fw-light;

        strong{
          font-weight: $fw-medium;
        }

        @media (max-width: 904px) {
          margin-bottom: 18px;
          font-size: 18px;
          line-height: 26px;
        }
      }

      table{
        width: 100%;

        tr {
          display: flex;

          @media (max-width: 904px) {
            flex-direction: column;
            margin-bottom: 8px;
          }

          &:last-child{
            @media (max-width: 904px) {
              margin-bottom: 0;
            }
          }

          td{
            padding-right: 32px;
            font-size: 22px;
            line-height: 40px;
            font-weight: $fw-light;

            &:first-child {
              @media (min-width: 905px) {
                width: 35%;
              }
            }

            &[colspan="2"] {
              width: 100%;
            }

            &:last-child{
              padding-right: 0;

              @media (max-width: 904px) {
                padding-bottom: 0;
              }
            }

            @media (max-width: 904px) {
              width: 100%;
              padding-right: 0;
              padding-bottom: 4px;
              font-size: 18px;
              line-height: 26px;
            }

            strong{
              font-weight: $fw-medium;
            }

            a{
              color: $red-dark-3;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}

.part-card{
  &+.contact-table{
    @media (min-width: 905px) {
      margin-top: -72px;
    }
  }
}

.contact-table{
  &+.part-card{
    @media (min-width: 905px) {
      margin-top: -72px;
    }
  }
}
