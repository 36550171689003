.footer {
  background-color: $solid-100;
  padding: 130px 0px 123px 0px;
  color: $white;
  font-size: 24px;
  line-height: 34px;
  font-weight: $fw-light;

  @media (max-width: 1139px) {
    font-size: 20px;
    line-height: 30px;
  }

  @media (max-width: 904px) {
    padding: 74px 0px 71px 0px;
  }

  .col-md-2 {
    padding-left: 0px !important;
  }

  .logo {
    margin-bottom: 64px;

    @media (max-width: 904px) {
      margin-bottom: 32px;
    }

    img {
      width: 187px;

      @media (max-width: 904px) {
        width: 134px;
      }
    }
  }

  &__link-list {
    width: 100%;

    @media (max-width: 904px) {
      margin-bottom: 60px;
    }

    ul {
      li {
        border-bottom: 1px solid #6B6B6B;

        &:first-child {
          border-top: 1px solid #6B6B6B;
        }

        a {
          display: flex;
          justify-content: space-between;
          color: $white;
          font-size: 24px;
          line-height: 34px;
          font-weight: $fw-light;
          padding: 24px 26px 24px 0px;

          @media (max-width: 1139px) {
            font-size: 20px;
            line-height: 30px;
          }

          @media (max-width: 904px) {
            padding: 20px 0px;
          }
        }
      }
    }
  }

  &__info, &__contact {
    height: 100%;
    display: flex;
    flex-flow: column;

    &--item {
      margin-bottom: 20px;

      @media (max-width: 904px) {
        margin-bottom: 30px;
      }

      h6 {
        font-size: 24px;
        line-height: 34px;
        margin-bottom: 12px;
        font-weight: $fw-semi-bold;

        @media (max-width: 1139px) {
          font-size: 20px;
          line-height: 30px;
        }

        @media (max-width: 904px) {
          margin-bottom: 0px;
        }
      }

      &.email {
        p {
          white-space: nowrap;

          a{
            color: $white;
            text-decoration: none;
            border-bottom: 1px solid $white;
          }
        }
      }
    }

    &--links {
      margin-top: 22px;
      margin-bottom: 65px;

      @media (max-width: 904px) {
        margin-top: 0px;
        margin-bottom: 0px;
      }

      a {
        display: flex;
        justify-content: start;
        align-items: center;
        color: $white;
        // line-height: 34px;

        @media (max-width: 904px) {

          margin-top: 32px;
          margin-bottom: 32px;

          &:first-child {
            margin-top: 0px;
          }
          &:last-child {
            margin-bottom: 64px;
          }
        }
      }

      .icon {
        width: 24px;
        height: 24px;
        background-image: url('./../images/icons/arrow/right/arrow-right-24-white.svg');
        margin-left: 12px;
      }
    }

    &--social {
      @media (max-width: 904px) {
        margin-bottom: 64px;
      }

      a {
        margin-right: 32px;

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .copyright {
      font-size: 18px;
      line-height: 22px;
      margin-top: auto;

      &--desktop {
        @media (max-width: 904px) {
          display: none;
        }
      }

      &--mobile {
        line-height: 34px;

        @media (min-width: 905px) {
          display: none;
        }
      }
    }

    .privacy {
      font-size: 18px;
      line-height: 22px;
      margin-top: auto;

      a {
        color: $white;
        border-bottom: 1px solid $white;
      }

      &--desktop {
        @media (max-width: 904px) {
          display: none;
        }
      }

      &--mobile {
        margin-bottom: 24px;
        line-height: 34px;

        @media (min-width: 905px) {
          display: none;
        }
      }
    }
  }

  &__info {
    &--item {
      &:nth-child(2) {
        margin-bottom: 30px;
      }
    }
  }
}
