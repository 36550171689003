.part-card{
  margin-bottom: 136px;

  @media (max-width: 904px) {
    margin-bottom: 56px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  .wrapper{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: $warm-40;
    padding: 92px 8.5% 75px;

    @media (max-width: 1139px) {
      padding-left: 5.5%;
      padding-right: 5.5%
    }

    @media (max-width: 904px) {
        padding: 43px 29px;
    }
  }

  &__header{
    width: 38%;
    margin-right: 13.5%;

    @media (max-width: 1139px) {
      width: 42.5%;
      margin-right: 4%;
    }

    @media (max-width: 904px) {
      width: 100%;
      margin-right: 0;
      margin-bottom: 18px;
    }

    .title{
      @media (max-width: 904px) {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }

  &__content{
    width: 48.5%;

    @media (max-width: 1139px) {
      width: 53.5%;
    }

    @media (max-width: 904px) {
      width: 100%;
    }

    .htmlArea{
      h3, h4, ul, ol, p{
        margin: 0;
      }

      table{
        width: 100%;

        tr{
          @media (max-width: 904px) {
            display: flex;
            flex-direction: column;
            margin-bottom: 8px;
          }

          &:last-child{
            @media (max-width: 904px) {
              margin-bottom: 0;
            }
          }

          td{
            padding-right: 32px;
            font-size: 22px;
            line-height: 40px;
            font-weight: $fw-light;

            &:last-child{
              padding-right: 0;

              @media (max-width: 904px) {
                padding-bottom: 0;
              }
            }

            @media (max-width: 904px) {
              width: 100%;
              padding-right: 0;
              padding-bottom: 4px;
              font-size: 18px;
              line-height: 26px;
            }

            strong{
              font-weight: $fw-medium;
            }

            a{
              color: $red-dark-3;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
