.image-gallery{
  margin: 96px 0;

  @media (max-width: 904px) {
    margin: 56px 0;
  }

  &:last-child{
    margin-bottom: 0;
  }

  &__buttons{
    @media (max-width: 904px) {
      display: none;
    }

    .row{
      position: relative;

      .col-12{
        position: absolute;
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        pointer-events: none;
        z-index: 1;

        button{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 64px;
          height: 64px;
          border-radius: 100%;
          background-color: $warm-60;
          pointer-events: all;

          &.prev{
            -webkit-transform: rotateZ(180deg);
            transform: rotateZ(180deg);
            margin-left: -32px;
          }

          &.next{
            margin-right: -32px;
          }
        }
      }
    }
  }

  &__gallery{
    display: block;
    overflow: hidden;

    .slick-list{
      padding: 0 calc((100vw - 1280px - 80px) / 2) !important;

      @media (max-width: 1680px) {
        padding: 0 calc(200px - 48px) !important;
      }

      @media (max-width: 1440px) {
        padding: 0 calc((100vw - 1040px - 80px)/2) !important;
      }

      @media (max-width: 1280px) {
        padding: 0 calc((100vw - 1040px - 48px)/2) !important;
      }

      @media (max-width: 1140px) {
        padding: 0 calc(50px - 16px) !important;
      }

      @media (max-width: 904px) {
        padding: 0 calc(32px - 10px) !important;
      }

      .slick-track{
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        .slide{
          display: inline-block;
          margin: 0 32px;
          outline: none;
          border: none;

          @media (max-width: 1280px) {
            margin: 0 16px;
          }

          @media (max-width: 904px) {
            margin: 0 10px;
          }

          figure{
            figcaption{
              margin-top: 32px;
              padding: 0 6%;
              font-size: 18px;
              line-height: 26px;
              font-weight: $fw-light;
              color: $solid-80;

              @media (max-width: 904px) {
                padding: 0;
                margin-top: 24px;
                font-size: 14px;
                line-height: 20px;
                font-weight: $fw-book;
              }
            }
          }
        }
      }
    }
  }
}
