.anonymous-notification{
  margin-bottom: 136px;

  @media (max-width: 904px) {
    margin-bottom: 56px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  header{
    padding-bottom: 27px;

    @media (max-width: 904px) {
      padding-bottom: 16px;
    }

    .title{
      font-size: 36px;
      line-height: 46px;

      @media (max-width: 904px) {
        font-size: 24px;
        line-height: 30px;
      }
    }
  }

  .form-table{
    &__row{
      .row{
        @media (min-width: 905px) {
          padding-top: 28px;
          padding-bottom: 19px;
        }
      }
    }
    .button-wrapper{
      margin-top: 32px;

      @media (max-width: 904px) {
        margin-top: 24px;
      }

      .btn{
        @media (max-width: 904px) {
          padding: 8px 16px;
          font-size: 15px;
          line-height: 26px;
          border-radius: 6px;
        }

        .icon{
          &--arrow-right-32-white{
            @media (max-width: 904px) {
              width: 24px;
              height: 24px;
              background-image: url('./../images/icons/arrow/right/arrow-right-24-white.svg');
            }
          }
        }
      }
    }
  }
}
