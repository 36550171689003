.e-learning{
    margin-bottom: 64px;
    
    @media (max-width: 904px) {
        margin-bottom: 96px;
    }

    .e-learning__accordion{
        margin-bottom: 16px;

        @media (max-width: 600px) {
            margin-bottom: 0;
        }

        &:first-of-type{
            .content{
                .btn-show{
                    @media (max-width: 600px) {
                        border-top: 1px solid $solid-40;
                    }
                }
            }
        }

        &.active{
            .content{
                margin-bottom: 24px;

                .btn-show{
                    border-bottom: none;
                }
            }

            .hidden-content{
                @media (max-width: 600px) {
                    padding-bottom: 26px;
                    border-bottom: 1px solid $solid-40;
                }
            }
        }

        .content{
            margin-bottom: 42px;
            
            @media (max-width: 600px) {
                margin-bottom: 0;
            }

            .btn-show{
                padding: 0;
                border-bottom: none;
                pointer-events: none;

                @media (max-width: 1440px) {
                    pointer-events: all;
                }

                @media (max-width: 600px) {
                    padding: 18px 0;
                    border-bottom: 1px solid $solid-40;
                    text-align: left;
                    align-items: flex-start;
                }

                h2{
                    font-size: 32px;
                    font-weight: $fw-light;

                    @media (max-width: 904px) {
                        font-size: 24px;
                    }

                    @media (max-width: 600px) {
                        padding-right: 20px;
                    }
                }

                .icon{
                    display: none;

                    @media (max-width: 600px) {
                        margin-top: 3px;
                        display: inline-block;
                    }
                }
            }
        }

        .hidden-content{
            display: block;

            @media (max-width: 600px) {
                display: none;
            }

            .e-learning__card{
                margin-bottom: 64px;

                @media (max-width: 904px) {
                    margin-bottom: 54px;
                }

                a{
                    display: block;

                    .img__wrapper{
                        margin-bottom: 32px;

                        @media (max-width: 1440px) {
                            margin-bottom: 24px;
                        }

                        figure{
                            div{
                                display: none;
                            }
                        }
                    }

                    h3{
                        margin-bottom: 12px;
                        font-size: 32px;
                        line-height: 40px;
                        font-weight: $fw-light;

                        @media (max-width: 1440px) {
                            margin-bottom: 8px;
                            font-size: 20px;
                            line-height: 30px;
                            font-weight: $fw-light;
                        }
                    }

                    p{
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: $fw-light;

                        @media (max-width: 1440px) {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                    
                    > .icon{
                        margin-top: 24px;
                        transition: 0.2s;

                        @media (max-width: 904px) {
                            display: none;
                        }
                    }

                    &:hover{
                        > .icon{
                            -ms-transform: translate(10px,0px); /* IE 9 */
                            transform: translate(10px,0px); /* Standard syntax */
                        }
                    }
                }

                &--text{
                    a{
                        .img__wrapper{
                            figure{
                                img{
                                    display: none;
                                }

                                div{
                                    position: absolute;
                                    top: 0;
                                    width: 100%;
                                    height: 100%;
                                    display: flex;
                                    align-items: center;
                                    justify-content: center;
                                    padding: 30px;
                                    font-size: 36px;
                                    line-height: 46px;
                                    text-align: center;
                                    font-weight: $fw-light;

                                    @media (max-width: 1440px) {
                                        padding: 20px;
                                        font-size: 30px;
                                        line-height: 40px;
                                        font-weight: $fw-extra-light;
                                    }
                                }
                            }
                        }
                    }
                }

                &--video{
                    a{
                        .img__wrapper{
                            position: relative;

                            &::after{
                                content: "";
                                position: absolute;
                                top: calc(50% - 48px);
                                left: calc(50% - 48px);
                                display: block;
                                background-color: $red-100;
                                background-image: url('./../images/icons/play/play-24-white.svg');
                                background-repeat: no-repeat;
                                background-position: 55%;
                                width: 96px;
                                height: 96px;
                                border-radius: 100%;

                                @media (max-width: 1440px) {
                                    width: 56px;
                                    height: 56px;
                                    top: calc(50% - 28px);
                                    left: calc(50% - 28px);
                                    background-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}