.list-view {
  .content-hero {
    margin-bottom: 50px;
  }

  &__list {
    margin-bottom: 48px;

    @media (max-width: 904px) {
      margin-bottom: 40px;
    }

    .list-top {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 40px;
      margin-bottom: 27px;

      @media (max-width: 904px) {
        margin-bottom: 24px;
      }

      .text {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        padding-right: 32px;

        h4 {
          margin-right: 32px;
          font-size: 30px;
          line-height: 40px;
          font-weight: $fw-medium;
          color: $red-dark-3;

          @media (max-width: 904px) {
            font-size: 20px;
            line-height: 30px;
            font-weight: $fw-book;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }

      .btn--filter{
        margin-bottom: 13px;

        @media (max-width: 904px) {
          margin-bottom: 0;
        }
      }
    }

    .arrow-card-wrapper {
      .arrow-card {
        .arrow-link {
          .text {
            border-color: $solid-40;

            .icon {
              @media (min-width: 905px) {
                right: 45px;
              }

              &--arrow-right-32-red,
              &--arrow-right-48-red {
                @media (max-width: 904px) {
                  width: 32px;
                  height: 32px;
                  background-image: url("./../images/icons/arrow/right/arrow-right-32-red.svg");
                }
              }

              &--arrow-up-right-36-red,
              &--arrow-up-right-48-red {
                @media (max-width: 904px) {
                  width: 36px;
                  height: 36px;
                  background-image: url("./../images/icons/arrow/up-right/arrow-up-right-36-red.svg");
                }
              }
            }
          }
        }
      }
    }

    .search-drawer {
      .content {
        .search-drawer__header {
          justify-content: flex-start;
          align-items: center;

          .close-btn {
            height: 32px;
            margin-right: 24px;
          }

          h3 {
            line-height: 24px;
          }
        }
      }
    }
  }
}
