.card-with-coloured-background{
    margin-bottom: 96px;
    padding: 110px 0 150px;
    background-color: $contrast-60;

    @media (max-width: 904px) {
        margin-bottom: 64px;
        padding: 65px 0 35px;
    }

    &:last-child{
        margin-bottom: 0;
    }

    &__header{
        margin-bottom: 38px;

        @media (max-width: 904px) {
            margin-bottom: 26px;
        }

        h2{
            font-size: 32px;
            line-height: 40px;
            font-weight: $fw-light;

            @media (max-width: 904px) {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }

    &__boxes{
        .card-with-coloured-background__box{
            display: flex;
            flex-direction: column;
            margin-bottom: 32px;

            a{
                display: flex;
                flex-direction: row;
                align-items: center;
                flex-shrink: 0;
                height: 100%;
                padding: 38px 34px 32px 38px;
                background-color: $white;
                border-radius: 12px;
                transition: box-shadow 0.2s;

                @media (max-width: 904px) {
                    padding: 25px;
                    align-items: flex-start;
                }

                .icon{
                    margin-left: auto;
                    transition: 0.2s;
                }

                &:hover{
                    @media (min-width: 905px) {
                        box-shadow: 0px 0px 0px 2px $contrast-dark-1;
                    }

                    .icon{
                        &--arrow-right-32-red{
                            -ms-transform: translate(10px,0px); /* IE 9 */
                            transform: translate(10px,0px); /* Standard syntax */
                        }

                        &--arrow-up-right-36-red{
                            -ms-transform: translate(5px,-5px); /* IE 9 */
                            transform: translate(5px,-5px); /* Standard syntax */
                        }
                    }
                }

                &:focus-visible{
                    outline-color: $main-text-color;
                }

                figure{
                    margin-right: 50px;

                    @media (max-width: 1440px) {
                        margin-right: 30px;
                    }

                    @media (max-width: 904px) {
                        position: absolute;
                        top: 25px;
                        margin-right: 0;
                    }

                    img{
                        width: 90px;
                        height: 90px;
                        object-fit: contain;

                        @media (max-width: 1440px) {
                            width: 70px;
                            height: 70px;
                        }

                        @media (max-width: 904px) {
                            width: 60px;
                            height: 60px;
                        }
                    }
                }

                .text{
                    max-height: 112px;
                    overflow: hidden;
                    padding-right: 10px;
                    // margin-bottom: auto;

                    @media (max-width: 904px) {
                        max-height: inherit;
                        overflow: initial;
                        padding-right: 0px;
                    }

                    h6{
                        font-size: 24px;
                        line-height: 34px;
                        font-weight: $fw-book;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; /* number of lines to show */
                        -webkit-box-orient: vertical;

                        @media (max-width: 904px) {
                            display: flex;
                            align-items: flex-end;
                            min-height: 60px;
                            padding-left: 78px;
                            font-size: 20px;
                            line-height: 26px;
                            overflow: initial;
                            text-overflow: inherit;
                            -webkit-line-clamp: inherit;
                            -webkit-box-orient: inherit;
                        }
                    }

                    p{
                        margin-top: 16px;
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: $fw-light;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; /* number of lines to show */
                        -webkit-box-orient: vertical;

                        @media (max-width: 904px) {
                            font-size: 16px;
                            line-height: 24px;
                            overflow: initial;
                            text-overflow: inherit;
                            display: block;
                            -webkit-line-clamp: inherit;
                            -webkit-box-orient: inherit;
                        }
                    }
                }

                .icon{
                        @media (max-width: 904px) {
                            display: none;
                        }
                }
            }
        }

        &--small{
            .card-with-coloured-background__box{
                flex: 0 0 33.3%;
                max-width: 33.3%;

                @media (max-width: 904px) {
                    flex: 0 0 50%;
                    max-width: 50%;
                }

                @media (max-width: 600px) {
                    flex: 0 0 100%;
                    max-width: 100%;
                    margin-bottom: 24px;
                }

                a{
                    padding: 42px 34px;

                    @media (max-width: 904px) {
                        padding: 30px 22px;
                    }

                    figure{
                        display: none;
                    }

                    .text{
                        width: 100%;

                        @media (max-width: 904px) {
                            padding-right: 20px;
                        }

                        h6{
                            margin-bottom: 0;

                            @media (max-width: 904px) {
                                min-height: inherit;
                                padding-left: 0;
                            }
                        }

                        p{
                            display: none;
                        }
                    }

                    .icon{
                        @media (max-width: 904px) {
                            display: inline-block;
                        }

                        &--arrow-right-32-red{
                            @media (max-width: 904px) {
                                width: 24px;
                                height: 24px;
                                background-image: url('./../images/icons/arrow/right/arrow-right-24-red.svg');
                            }
                        }

                        &--arrow-up-right-36-red{
                            @media (max-width: 904px) {
                                width: 24px;
                                height: 24px;
                                background-image: url('./../images/icons/arrow/up-right/arrow-up-right-24-red.svg');
                            }
                        }
                    }
                }
            }
        }
    }

    &--warm{
        background-color: $warm-60;

        .card-with-coloured-background__box{
            a{
                &:hover{
                    @media (min-width: 905px) {
                        box-shadow: 0px 0px 0px 2px $warm-dark-2;
                    }
                }
            }
        }
    }
}
