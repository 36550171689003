.accordion-card{
    margin-bottom: 136px;

    @media (max-width: 904px) {
        margin-bottom: 56px;
    }

    &:last-child{
        margin-bottom: 0;
    }

    &__header{
        margin-bottom: 34px;

        @media (max-width: 599px) {
            margin-bottom: 24px;
        }

        h2{
            font-size: 32px;
            line-height: 40px;
            font-weight: $fw-light;

            @media (max-width: 904px) {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }

    &__expand-all{
        @media (max-width: 599px) {
            display: none;
        }

        button{
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: auto;
            font-size: 20px;
            line-height: 20px;
            font-weight: $fw-light;

            @media (max-width: 599px) {
                font-size: 15px;
                line-height: 26px;
            }

            .icon{
                transition: 0.2s;
                margin-left: 20px;
            }

            &.active{
                .icon{
                    transform: rotate(-45deg);
                }
            }
        }
    }

    &__accordion{
        position: relative;
        margin-bottom: 32px;

        @media (max-width: 904px) {
            margin-bottom: 16px;
        }

        @media (max-width: 599px) {
            margin-bottom: 24px;
        }

        &:last-child{
            @media (min-width: 600px) {
                margin-bottom: 0;
            }
        }

        .accordion__wrapper{
            background-color: $yellow-20;

            > a{
                display: block;
                padding: 48px 46px;
                transition: outline 0.2s , padding 0.1s;
                outline: 2px solid transparent;
                outline-offset: -2px;

                @media (max-width: 904px) {
                    padding: 28px 16px 28px 28px;
                }

                &:hover{
                    @media (min-width: 905px) {
                        outline: 2px solid $yellow-60;
                    }
                }

                &:focus-visible{
                    @media (min-width: 905px) {
                        outline: 2px solid $main-text-color;
                    }
                }

                .text{
                    width: 85%;

                    h3{
                        margin-bottom: 13px;
                        font-size: 28px;
                        line-height: 38px;
                        font-weight: $fw-book;

                        @media (max-width: 904px) {
                            font-size: 20px;
                            line-height: 26px;
                        }
                    }

                    p{
                        font-size: 20px;
                        line-height: 28px;
                        font-weight: $fw-light;

                        @media (max-width: 904px) {
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }

                > .icon{
                    position: absolute;
                    top: 39px;
                    right: 42px;
                    transition: 0.2s;

                    @media (max-width: 904px) {
                        top: 24px;
                        right: 16px;
                        width: 32px;
                        height: 32px;
                        background-image: url('./../images/icons/plus/plus-32-red.svg');
                    }
                }
            }

            .hidden-content{
                padding: 0 46px 48px;
                display: none;

                @media (max-width: 904px) {
                    padding: 0px 24px 35px 24px;
                }

                ul{
                    li{
                        border-bottom: 1px solid $solid-40;

                        &:first-child{
                            border-top: 1px solid $solid-40;
                        }

                        a{
                            width: 100%;
                            justify-content: space-between;
                            padding: 22px 10px 22px 0;
                            font-size: 20px;
                            line-height: 26px;
                            font-weight: $fw-book;

                            @media (max-width: 904px) {
                                padding: 16px 10px 16px 0;
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }

        &.active{
            .accordion__wrapper{
                > a{
                    outline-color: transparent;

                    @media (max-width: 904px) {
                        padding-bottom: 24px;
                    }

                    > .icon {
                        transform: rotate(-45deg);
                    }
                }
            }
        }
    }

    .col-sm-6{
        &:last-child{
            .accordion-card__accordion{
                &:last-child{
                    @media (max-width: 599px) {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

}
