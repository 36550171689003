.text-and-media {
	margin-bottom: 136px;

	@media (max-width: 904px) {
		margin-bottom: 80px;
	}

	&:last-child{
		margin-bottom: 0;
 	}

	&__item {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 152px;

		@media (max-width: 904px) {
			margin-bottom: 32px;
		}

		&:last-child{
			margin-bottom: 0;
		}

		.item-text {
			font-weight: $fw-light;

			.title--h2 {
				font-weight: $fw-light;
				margin-bottom: 32px;

				@media (max-width: 904px) {
					font-size: 26px;
					line-height: 36px;
					margin-bottom: 5px;
					letter-spacing: 0px;
				}
			}

			p {
				font-size: 28px;
				line-height: 42px;
				margin-bottom: 32px;

				@media (max-width: 904px) {
					font-size: 16px;
					line-height: 22px;
					margin-bottom: 12px;
				}
			}

			.arrow-link {
				font-size: 24px;
				line-height: 34px;

				@media (max-width: 904px) {
					font-size: 16px;
					line-height: 22px;
				}

				.icon {
					@media (max-width: 904px) {
						width: 16px;
						height: 16px;
						background-image: url('./../images/icons/arrow/right/arrow-right-16-red.svg');
					}
				}
			}
		}

		&--left {
			flex-flow: row;

			@media (max-width: 600px) {
				flex-flow: column;
			}
		}
		&--right {
			flex-flow: row-reverse;

			@media (max-width: 600px) {
				flex-flow: column;
			}
		}

		&--square {
			.item-image {
				width: 40%;

				@media (max-width: 600px) {
					width: 100%;
					margin-bottom: 28px;
				}

				.img {
					@media (max-width: 600px) {
						padding-top: calc((219 / 292) * 100%);
					}
				}
			}

			.item-text {
				width: 48.5%;
				@media (max-width: 600px) {
					width: 100%;
				}
			}
		}

		&--large {
			.item-image {
				width: 48.5%;
				@media (max-width: 600px) {
					width: 100%;
					margin-bottom: 28px;
				}

				.img {
					@media (max-width: 600px) {
						padding-top: calc((219 / 292) * 100%);
					}
				}
			}

			.item-text {
				width: 40%;
				@media (max-width: 600px) {
					width: 100%;
				}
			}
		}
	}

	&--landing{
		margin-top: -232px;
		padding-top: 224px;
		padding-bottom: 200px;
		background-color: $warm-40;

		@media (max-width: 904px) {
			margin-top: -140px;
			padding-top: 140px;
			padding-bottom: 54px;
		}

		&:last-child{
			margin-bottom: -200px;

			@media (max-width: 904px) {
				margin-bottom: -136px;
			}
		}
	}
}
