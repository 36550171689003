.link-set{
    &__header{
        margin-bottom: 24px;

        .title{
            @media (max-width: 904px) {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }

    .link-list{
        ul{
            border-color: $solid-100;

            li{
                border-color: $solid-100;

                a{
                    align-items: flex-start;
                    padding-right: 16px;

                    @media (max-width: 904px) {
                        padding-right: 0;
                    }

                    .text-wrapper{
                        padding-right: 28px;

                        @media (max-width: 904px) {
                            padding-right: 10px;
                        }

                        h4{
                            font-size: 24px;
                            line-height: 34px;
                            font-weight: $fw-light;

                            @media (max-width: 904px) {
                                font-size: 20px;
                                line-height: 27px;
                            }
                        }

                        p{
                            margin-top: 7px;
                            color: $solid-80;
                            font-size: 18px;
                            line-height: 26px;
                            font-weight: $fw-light;

                            @media (max-width: 904px) {
                                margin-top: 8px;
                                font-size: 15px;
                                line-height: 22px;
                            }
                        }
                    }

                    .icon{
                        margin-left: 0;

                        @media (max-width: 904px) {
                            &--arrow-right-48-red{
                                width: 32px;
                                height: 32px;
                                background-image: url('./../images/icons/arrow/right/arrow-right-32-red.svg');
                            }

                            &--arrow-up-right-48-red{
                                width: 36px;
                                height: 36px;
                                background-image: url('./../images/icons/arrow/up-right/arrow-up-right-36-red.svg');
                            }

                            &--download{
                                width: 32px;
                                height: 32px;
                                background-image: url('./../images/icons/download/download-32-red.svg');

                                path{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
