.search-results{
  .article-header{
    padding-bottom: 42px;
  }

  &__filters{
    .search-input-wrapper{
      .icon{
        position: absolute;
        left: 50px;
        top: 25px;

        @media (max-width: 904px) {
          left: 27px;
          top: 17px;
          width: 16px;
          height: 16px;
          background-image: url('./../images/icons/search/search-16-black.svg');
        }
      }

      input[type="text"]{
        max-width: 703px;
        width: 100%;
        padding: 19px 19px 19px 92px;
        background-color: $contrast-40;
        border: none;
        outline: none;
        border-radius: 12px;
        font-size: 28px;
        line-height: 42px;
        font-weight: $fw-light;
        -webkit-appearance: none;

        @media (max-width: 904px) {
          max-width: 100%;
          padding: 12px 12px 12px 50px;
          border: 1px solid $black;
          background-color: $white;
          border-radius: 6px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .button-wrapper{
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-top: 66px;
      margin-bottom: 33px;

      @media (max-width: 904px) {
        margin-top: 54px;
        margin-bottom: 14px;
      }

      p{
        padding-right: 32px;
        font-size: 24px;
        line-height: 34px;
        font-weight: $fw-light;

        @media (max-width: 904px) {
          padding-right: 16px;
          font-size: 20px;
          line-height: 30px;
          font-weight: $fw-book;
        }
      }
    }
  }

  .article-previews{
    margin-bottom: 48px;

    @media (max-width: 904px) {
      margin-bottom: 40px;
    }

    .arrow-card{
      .arrow-link , .card-content{
        .text{
          border-color: $solid-40;

          .icon{
            &--arrow-right-32-red{
              @media (max-width: 904px) {
                width: 24px;
                height: 24px;
                background-image: url('./../images/icons/arrow/right/arrow-right-24-red.svg');
              }
            }

            &--arrow-up-right-36-red{
              @media (max-width: 904px) {
                width: 24px;
                height: 24px;
                background-image: url('./../images/icons/arrow/up-right/arrow-up-right-24-red.svg');
              }
            }
          }
        }
      }

      &__link-list{
        .row{
          .col-md-6{
              .link-list{
                  ul{
                    border-color: $solid-40;

                    li{
                          border-color: $solid-40;
                      }
                  }
              }
          }
      }
      }
    }
  }
}

.search-highlight{
  background-color: $contrast-40;
}
