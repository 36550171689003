.local-organisation{
    margin-bottom: 72px;

    @media (max-width: 904px) {
        margin-bottom: 64px;
    }

    &:last-child{
        margin-bottom: 0;
    }

    &__header{
        margin-bottom: 64px;

        @media (max-width: 904px) {
            margin-bottom: 30px;
        }

        h2{
            font-size: 32px;
            line-height: 40px;
            font-weight: $fw-light;

            @media (max-width: 904px) {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }

    &__contact-card{
        margin-bottom: 32px;
        display: flex;
        flex-direction: column;

        @media (max-width: 904px) {
            margin-bottom: 16px;
        }

        .content{
            display: flex;
            flex-direction: column;
            height: 100%;
            padding: 34px 30px;
            background-color: $warm-60;

            @media (max-width: 904px) {
                padding: 30px 24px 40px;
            }

            .role{
                margin-bottom: 5px;
                font-size: 18px;
                line-height: normal;
                font-weight: $fw-light;

                @media (max-width: 904px) {
                    margin-bottom: 8px;
                    font-size: 16px;
                }
            }

            .name{
                margin-bottom: 13px;
                font-size: 24px;
                line-height: 34px;
                font-weight: $fw-book;

                @media (max-width: 904px) {
                    margin-bottom: 15px;
                    font-size: 20px;
                    line-height: 30px;
                    font-weight: $fw-medium;
                }
            }

            .email__wrapper , .phone__wrapper{
                position: relative;
                margin-bottom: 5px;
                padding-left: 100px;
                font-size: 20px;
                font-weight: $fw-light;

                &:last-child{
                    margin-bottom: 0;
                }

                @media (max-width: 904px) {
                    margin-bottom: 24px;
                    padding-left: 0;
                    font-size: 16px;
                    line-height: 22px;
                }

                p{
                    &:first-child{
                        position: absolute;
                        left: 0;

                        @media (max-width: 904px) {
                            position: relative;
                        }
                    }
                }
            }

            .email__wrapper{
                margin-top: auto;
            }
        }
    }

    &__text{
        margin-top: 32px;

        p{
            margin-bottom: 24px;
            font-size: 20px;
            line-height: 28px;
            font-weight: $fw-light;

            @media (max-width: 904px) {
                margin-bottom: 16px;
                font-size: 16px;
                line-height: 22px;
            }
        }
    }
}
