.forum-post {
  margin-bottom: 48px;

  @media (max-width: 904px) {
    margin-bottom: 32px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .wrapper {
    padding-right: 13px;
    padding-bottom: 72px;
    padding-left: 13px;
    border-bottom: 1px solid $solid-40;

    @media (max-width: 904px) {
      padding-right: 0px;
      padding-bottom: 64px;
      padding-left: 0px;
    }

    .forum-post__header {
      margin-bottom: 36px;

      @media (max-width: 904px) {
        margin-bottom: 28px;
      }

      .header-top {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 37px;

        @media (max-width: 904px) {
          flex-direction: column;
          margin-bottom: 28px;
        }

        .user-card {
          display: inline-flex;
          margin-bottom: 0;

          @media (max-width: 904px) {
            margin-bottom: 0;
            align-items: center;
          }

          &__avatar {
            width: 54px;
            min-width: 54px;
            height: 54px;
            margin-right: 22px;
            font-size: 25px;
            line-height: 50px;
            text-transform: uppercase;

            @media (max-width: 904px) {
              width: 36px;
              min-width: 36px;
              height: 36px;
              margin-right: 17px;
              font-size: 15px;
              line-height: 34px;
            }
          }

          &__info {
            .name {
              font-size: 24px;
              line-height: 30px;
              font-weight: $fw-book;

              @media (max-width: 904px) {
                font-size: 16px;
                line-height: normal;
              }
            }

            .intro {
              font-size: 16px;
              line-height: normal;

              @media (max-width: 904px) {
                margin-top: 0;
              }
            }
          }
        }

        .comments {
          display: flex;
          align-items: center;
          font-size: 16px;
          line-height: 26px;
          font-weight: $fw-light;
          color: $solid-80;
          text-decoration: underline;

          @media (max-width: 904px) {
            margin-top: 21px;
            margin-left: 13px;
          }

          &::before {
            content: "";
            display: inline-block;
            margin-top: 4px;
            margin-right: 8px;
            background-size: contain;
            background-repeat: no-repeat;
            flex-shrink: 0;
            width: 18px;
            height: 17px;
            background-image: url("./../images/icons/chat/chat-16.svg");
          }
        }
      }

      .title {
        max-width: 615px;
        font-size: 36px;
        line-height: 46px;
        font-weight: $fw-light;

        @media (max-width: 904px) {
          font-size: 24px;
          line-height: 30px;
        }

        a {
          @media (max-width: 904px) {
            text-decoration: underline;
          }
        }
      }
    }

    .htmlArea {
      li {
        font-size: 18px;
        line-height: 30px;
      }

      p {
        margin-bottom: 18px;
        font-size: 18px;
        line-height: 28px;
        font-weight: $fw-light;

        &:last-child {
          margin-bottom: 0;
        }

        @media (max-width: 904px) {
          font-size: 16px;
          line-height: 28px;
        }
      }

      figure {
        margin-bottom: 36px;
      }
    }

    .btn-read-more {
      margin-top: 21px;
      font-size: 18px;
      line-height: 28px;

      @media (max-width: 904px) {
        font-size: 16px;
      }

      .icon {
        @media (max-width: 904px) {
          width: 18px;
          height: 18px;
        }
      }
    }
  }
}

.forum-comments {
  .forum-comments__wrapper {
    padding: 0 13px;

    @media (max-width: 904px) {
      padding: 0;
    }

    .forum-comments__header {
      margin-bottom: 38px;

      @media (max-width: 904px) {
        margin-bottom: 33px;
      }

      .title {
        @media (max-width: 904px) {
          font-size: 24px;
          line-height: 30px;
        }
      }
    }

    #discussion {
      background-color: transparent;

      ol.top-level {
        margin-top: 0;
        padding-left: 0;

        > li {
          &::before{
            display: none;
          }

          &::after{
            display: none;
          }

          > .comment-block__avatar {
            margin-right: 18px;

            @media (max-width: 904px) {
              margin-right: 8px;
            }

            &::after{
              display: none;
            }
          }
        }

        > .btn-show-answers{
          padding-left: 54px;

          @media (max-width: 904px) {
            padding-left: 32px;
          }
        }
      }

      // level 1
      ol {
        margin-top: 48px;

        @media (max-width: 904px) {
          margin-top: 28px;
        }

        // level 2
        > ol{
          padding-left: 54px;

          @media (max-width: 904px) {
            padding-left: 32px;
          }

          li{
            &:last-of-type{
              & + {
                .btn-show-answers{
                  &::after{
                    display: none;
                  }
                }
              }
            }
          }

          // level 3
          ol{
            position: relative;
            padding-left: 70px;

            @media (max-width: 904px) {
              padding-left: 32px;
            }

            &::before{
              content: "";
              position: absolute;
              top: -48px;
              left: 0;
              display: block;
              height: calc(100% + 48px);
              width: 1px;
              margin-left: 18px;
              border-left: 1px solid $solid-20;
              z-index: -1;

              @media (max-width: 904px) {
                top: -28px;
                height: calc(100% + 28px);
                margin-left: 12px;
              }
            }

            &:last-child{
              &::before{
                display: none;
              }
            }
          }

          .btn-show-answers{
            position: relative;

            &::after{
              content: "";
              position: absolute;
              top: 0px;
              left: 0;
              display: block;
              height: 100%;
              width: 1px;
              margin-left: 18px;
              border-left: 1px solid $solid-20;
              z-index: -1;

              @media (max-width: 904px) {
                margin-left: 12px;
              }
            }
          }
        }

        li {
          position: relative;
          display: flex;
          flex-direction: row;
          width: 100%;
          margin-top: 48px;
          padding: 0;
          border: none;

          @media (max-width: 904px) {
            margin-top: 28px;
          }

          &:first-child{
            margin-top: 0;
          }

          &:hover {
            background-color: transparent;
          }

          &::before {
            content: "";
            position: absolute;
            top: -48px;
            display: block;
            height: 56px;
            width: 1px;
            margin-left: 18px;
            border-left: 1px solid $solid-20;
            z-index: -1;

            @media (max-width: 904px) {
              top: -28px;
              height: 36px;
              margin-left: 12px;
            }
          }

          &::after {
            content: "";
            position: absolute;
            top: 44px;
            display: block;
            height: calc(100% - 44px);
            width: 1px;
            margin-left: 18px;
            border-left: 1px solid $solid-20;
            z-index: -1;

            @media (max-width: 904px) {
              top: 32px;
              height: calc(100% - 32px);
              margin-left: 12px;
            }
          }

          &:last-of-type {
            &::after {
              display: none;
            }
          }

          &:last-child{
            .singleComment{
              .bottom{
                .startDiscussion{
                  &::after{
                    display: none;
                  }
                }
              }
            }
          }

          .comment-block__avatar {
            &::after {
              content: "";
              position: absolute;
              left: 36px;
              display: block;
              width: 34px;
              height: 1px;
              border-top: 1px solid $solid-20;
              z-index: -1;

              @media (max-width: 904px) {
                left: 24px;
                width: 8px;
              }
            }
          }

          .singleComment {
            position: relative;
            width: 100%;
            max-width: 624px;

            .top {
              display: flex;
              flex-direction: row;
              align-items: center;
              background-color: $warm-40;
              padding: 18px 32px 0;
              border-top-right-radius: 12px;
              border-top-left-radius: 12px;

              @media (max-width: 904px) {
                flex-direction: column;
                align-items: flex-start;
                padding: 9px 48px 0 14px;
                border-top-right-radius: 6px;
                border-top-left-radius: 6px;
              }

              .name {
                padding-left: 0;
                font-size: 18px;
                line-height: 26px;
                font-weight: 500;

                @media (max-width: 904px) {
                  font-size: 15px;
                  line-height: 24px;
                }
              }

              time {
                font-size: 18px;
                line-height: 26px;
                font-weight: 300;

                @media (max-width: 904px) {
                  font-size: 15px;
                  line-height: 24px;
                }

                &::before {
                  content: "-";
                  margin: 0px 6px;

                  @media (max-width: 904px) {
                    display: none;
                  }
                }
              }

              .edit {
                display: none;
                width: 24px;
                height: 24px;
                margin-left: 12px;
                object-fit: contain;
                background-size: 24px;
                background-repeat: no-repeat;
                background-image: url("./../images/icons/pencil/note-pencil-32.svg");
                outline: none;

                @media (max-width: 904px) {
                  position: absolute;
                  top: 12px;
                  right: 16px;
                  width: 16px;
                  height: 16px;
                  margin: 0;
                  background-size: 16px;
                }

                svg {
                  display: none;
                }
              }
            }

            .text {
              padding: 26px 32px 40px;
              font-size: 18px;
              line-height: 26px;
              font-weight: 300;
              background-color: $warm-40;
              border-bottom-right-radius: 12px;
              border-bottom-left-radius: 12px;

              @media (max-width: 904px) {
                padding: 24px 14px 54px;
                font-size: 15px;
                line-height: 24px;
                border-bottom-right-radius: 6px;
                border-bottom-left-radius: 6px;
              }
            }

            .bottom {
              .respond {
                font-size: 0;
                color: transparent;
                position: absolute;
                right: 16px;
                bottom: 16px;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 36px;
                min-width: 36px;
                height: 36px;
                margin: 0;
                padding: 0;
                background-color: $white;
                border-radius: 100%;
                box-shadow: none;

                @media (max-width: 904px) {
                  right: 8px;
                  bottom: 8px;
                  width: 24px;
                  min-width: 24px;
                  height: 24px;
                }

                &::before {
                  content: "";
                  width: 24px;
                  height: 24px;
                  display: inline-block;
                  flex-shrink: 0;
                  background-size: contain;
                  background-repeat: no-repeat;
                  background-image: url("./../images/icons/reply/reply-24-red.svg");

                  @media (max-width: 904px) {
                    width: 16px;
                    height: 17px;
                    background-image: url("./../images/icons/reply/reply-16-red.svg");
                  }
                }
              }

              .startDiscussion{
                margin-top: 20px;
                padding-left: 70px;

                @media (max-width: 904px) {
                  padding-left: 32px;
                }

                &::before {
                  content: "";
                  position: absolute;
                  top: -20px;
                  left: 18px;
                  display: block;
                  height: 28px;
                  width: 1px;
                  border-left: 1px solid $solid-20;
                  z-index: -1;

                  @media (max-width: 904px) {
                    top: -20px;
                    height: 28px;
                    left: 12px;
                  }
                }

                &::after {
                  content: "";
                  position: absolute;
                  top: 44px;
                  display: block;
                  height: calc(100% - 44px);
                  width: 1px;
                  left: 18px;
                  border-left: 1px solid $solid-20;
                  z-index: -1;

                  @media (max-width: 904px) {
                    top: 32px;
                    height: calc(100% - 32px);
                    left: 12px;
                  }
                }

                &.removeAfterLine{
                  &::after{
                    display: none;
                  }
                }

                .formSub{
                  margin-top: 16px;
                }
              }
            }
          }
        }

        .btn-show-answers {
          width: 100%;
          padding-left: 70px;

          @media (max-width: 904px) {
            padding-left: 32px;
          }

          &::before {
            content: "";
            display: block;
            height: 22px;
            width: 1px;
            margin-left: 18px;
            border-left: 1px solid $solid-20;

            @media (max-width: 904px) {
              height: 19px;
              margin-bottom: -1px;
              margin-left: 12px;
            }
          }

          button {
            display: flex;
            flex-direction: row;
            align-items: center;

            .icon {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 36px;
              min-width: 36px;
              height: 36px;
              border-radius: 100%;
              background-size: 24px;
              background-position: center;
              background-color: $warm-40;
              transform: rotate(180deg);

              @media (max-width: 904px) {
                width: 24px;
                min-width: 24px;
                height: 24px;
                background-size: 17px;
                background-position: 3px 3px;
                background-image: url("./../images/icons/reply/reply-16-red.svg");
              }
            }

            .text {
              margin-left: 17px;
              font-size: 16px;
              line-height: 26px;
              font-weight: $fw-book;
              color: $solid-80;
              text-decoration: underline;

              @media (max-width: 904px) {
                font-size: 15px;
              }
            }
          }
        }

        &.hiddenComments{
          display: none;
        }
      }

      .comment-block__avatar {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 8px;
        margin-right: 34px;
        background-color: $red-80;
        border-radius: 100%;
        width: 36px;
        min-width: 36px;
        height: 36px;
        color: $white;
        font-size: 13px;
        font-weight: $fw-bold;
        text-transform: uppercase;

        @media (max-width: 904px) {
          width: 24px;
          min-width: 24px;
          height: 24px;
          margin-right: 8px;
          font-size: 10px;
          line-height: 24px;
          font-weight: $fw-bold;
        }
      }

      .startDiscussion{
        position: relative;
        display: flex;
        flex-direction: column;
        width: 100%;
        max-width: calc(624px + 54px);
        padding: 0 0 0 54px;
        margin-top: 48px;

        @media (max-width: 904px) {
          margin-top: 28px;
          padding-left: 32px;
        }

        .headline{
          display: none;
        }

        .comment-block__avatar{
          position: absolute;
          left: 0;
          margin-right: 0;
        }

        .createComment {
          width: 100%;
          max-width: 624px;
          height: 62px;
          padding: 18px 32px;
          border: none;
          border-radius: 12px;
          font-size: 18px;
          line-height: 26px;
          font-weight: 300;
          cursor: pointer;

          @media (max-width: 904px) {
            height: 40px;
            padding: 7px 14px;
            border-radius: 6px;
            font-size: 15px;
            line-height: 24px;
            font-weight: $fw-light;
          }


          &:focus {
            &::placeholder {
              color: transparent;
            }

            &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
              color: transparent;
            }

            &:-ms-input-placeholder { /* Internet Explorer 10-11 */
              color: transparent;
            }

            &::-ms-input-placeholder { /* Microsoft Edge */
              color: transparent;
            }
          }

          &::placeholder {
            color: $main-text-color;
            font-weight: $fw-medium;
            font-style: italic;

            @media (max-width: 904px) {
              font-weight: $fw-light;
            }
          }

          &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: $main-text-color;
            opacity: 1; /* Firefox */
          }

          &:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: $main-text-color;
          }

          &::-ms-input-placeholder { /* Microsoft Edge */
            color: $main-text-color;
          }

          &.active {
            height: 136px;
            cursor: text;

            & + {
              .formSub{
                display: block;
              }
            }
          }
        }

        .formSub{
          display: none;
          margin-top: 24px;
          margin-left: auto;
          background-color: $red-100;
          font-weight: $fw-book;
          color: $white;
          padding: 11px 40px;
          border-radius: 8px;
          font-size: 18px;
          line-height: 26px;
          box-shadow: none;
          text-transform: capitalize;

          @media (max-width: 904px) {
            margin-top: 16px;
            padding: 7px 30px;
            border-radius: 6px;
            font-size: 15px;
            line-height: 26px;
          }
        }
      }
    }
  }
}

.new-post-button{
  position: fixed;
  bottom: 64px;
  right: calc(1350px + ((100vw - 1600px)/2));
  z-index: 1;

  @media (max-width: 1680px) {
    right: inherit;
    left: 150px;
  }

  @media (max-width: 1440px) {
    right: calc(1230px + ((100vw - 1600px)/2));
    left: inherit;
  }

  @media (max-width: 1280px) {
    left: 20px;
    right: inherit;
  }

  @media (max-width: 904px) {
    bottom: 20px;
  }

  .btn{
    span{
      &:first-child{
        transform: translateY(-2px);

        @media (max-width: 904px) {
          transform: translateY(-1px);
        }
      }
    }

      .icon{
          @media (max-width: 904px) {
              width: 16px;
              height: 16px;
              background-image: url('./../images/icons/plus/plus-16-white.svg');
          }
      }
  }
}

.add-new-post{
  .post-editor{
    margin-top: 64px;

    @media (max-width: 904px) {
      margin-top: 32px;
    }

    .ck-content{
      min-height: 300px;
    }
  }

  .form-table{
    .button-wrapper{
      margin-top: 64px;

      @media (max-width: 904px) {
        margin-top: 48px;
      }
    }
  }

  .error-text{
    margin-top: 32px;
    padding: 16px;
    background-color: $red-20;

    @media (max-width: 904px) {
      margin-top: 24px;
    }
  }
}

.ck-editor{
  .ck-content{
    a{
      color: $red-dark-3;
      text-decoration: underline;
    }

    ul, ol{
      padding-left: 20px;
    }

    ul{
      list-style: inherit;
    }

    ol{
      list-style: auto;
    }

    p {
      margin-bottom: 18px;
    }
  }
}
