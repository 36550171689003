.forum-card{
    margin-bottom: 136px;

    @media (max-width: 599px) {
        margin-bottom: 59px;
    }

    &:last-child{
        margin-bottom: 0;
    }

    &__header{
        margin-bottom: 34px;

        h2{
            font-size: 32px;
            line-height: 40px;
            font-weight: $fw-light;

            @media (max-width: 904px) {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }

    &__see-all{
        text-align: right;
        max-height: 40px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;

        @media (max-width: 904px) {
            max-height: 30px;
            justify-content: flex-start;
        }

        @media (max-width: 599px) {
            margin-top: 16px;
            text-align: left;
            order: 3;
        }

        .arrow-link{
            font-size: 20px;
            line-height: 20px;
            font-weight: $fw-light;

            @media (max-width: 599px) {
                font-size: 15px;
                line-height: 26px;
            }
        }
    }

    .forum-card__slider{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        // justify-content: space-between;

        @media (max-width: 599px) {
            display: block;
            overflow: hidden;
            margin-right: -32px;
        }

        .slick-list{
            @media (max-width: 599px) {
                padding: 0 32px 0 0;
            }

            .slick-track{
                @media (max-width: 599px) {
                    display: flex;
                }
            }
        }

        .slide{
            width: calc(33.3% - 20px);
            margin-right: 30px;

            &:last-child{
                margin-right: 0;
            }

            @media (max-width: 904px) {
                width: calc(33.3% - 11px);
                margin-right: 16px;
            }

            @media (max-width: 599px) {
                display: inline-block;
                margin-right: 20px;
            }

            article{
                height: 100%;

                > a{
                    position: relative;
                    height: 100%;
                    display: flex;
                    flex-direction: column;
                    padding: 48px 32px;
                    background-color: $yellow-20;
                    outline: 2px solid transparent;
                    outline-offset: -2px;
                    transition: outline 0.2s;

                    @media (max-width: 904px) {
                        padding: 13px 23px 28px;
                    }

                    .icon{
                        transition: 0.2s;
                    }

                    &:hover{
                        @media (min-width: 905px) {
                            outline-color: $yellow-60;
                        }

                        .icon{
                            &--arrow-right-32-red{
                                -ms-transform: translate(10px,0px); /* IE 9 */
                                transform: translate(10px,0px); /* Standard syntax */
                            }
                        }
                    }

                    &:focus-visible{
                        @media (min-width: 905px) {
                            outline-color: $main-text-color;
                        }
                    }

                    .date{
                        margin-bottom: 16px;
                        color: $solid-80;
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: $fw-book;

                        @media (max-width: 904px) {
                            font-size: 14px;
                        }
                    }

                    h3{
                        margin-bottom: 18px;
                        font-size: 28px;
                        line-height: 38px;
                        font-weight: $fw-light;

                        @media (max-width: 904px) {
                            margin-bottom: 16px;
                            font-size: 18px;
                            line-height: 24px;
                        }
                    }

                    .comments{
                        display: inline-flex;
                        flex-direction: row;
                        margin-top: auto;

                        .num{
                            margin-left: 8px;
                            color: $solid-80;
                            font-size: 16px;
                            line-height: 26px;

                            @media (max-width: 904px) {
                                margin-left: 5px;
                                font-size: 14px;
                            }
                        }
                    }

                    > .icon{
                        position: absolute;
                        right: 30px;
                        bottom: 52px;

                        @media (max-width: 904px) {
                            right: 24px;
                            bottom: 28px;
                            width: 24px;
                            height: 24px;
                            background-image: url('./../images/icons/arrow/right/arrow-right-24-red.svg');
                        }
                    }
                }
            }
        }
    }
}
