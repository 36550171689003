.info-check__wrapper{
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: auto;
    z-index: 3;

    &.active{
        display: block;
    }

    .container{
        @media (max-width: 600px) {
            padding: 0 11px !important;
        }

        .info-check{
            position: relative;
            margin-top: 138px;
            margin-bottom: 128px;
            padding-bottom: 128px;
            background-color: $white;
            z-index: 4;

            @media (max-width: 904px) {
                margin-top: 54px;
                margin-bottom: 54px;
                padding-bottom: 48px;
            }

            .page-header{
                padding: 110px 0 50px;

                @media (max-width: 904px) {
                    padding: 45px 20px 30px;
                }
            }

            &__current-info{
                @media (max-width: 904px) {
                    padding: 0 16px;
                }

                h4{
                    &.table-title{
                        color: $red-dark-2;
                    }
                }

                .form-table{
                    &__row{
                        .action-btn--edit{
                            .icon--pencil-24{
                                @media (max-width: 600px) {
                                    opacity: 1;
                                    background-image: url('./../images/icons/arrow/right/arrow-right-24-red.svg');
                                }
                            }
                        }
                    }
                }

                .current-info__checkbox{
                    margin-top: 30px;
                }
            }

            &__edit-info{
                @media (max-width: 904px) {
                    padding: 0 16px;
                }

                .button-wrapper{
                    margin-top: 96px;
                    text-align: right;

                    @media (max-width: 904px) {
                        margin-top: 48px;
                    }
                }
            }
        }
    }
}
