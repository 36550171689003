.part-link-list{
    margin-bottom: 136px;

    @media (max-width: 904px) {
        margin-bottom: 64px;
    }

    &:last-child{
       margin-bottom: 0;
    }

    .wrapper{
        width: 100%;
        padding: 136px 0;

        @media (max-width: 904px) {
            padding: 64px 0;
        }

        .part-link-list__header{
            margin-bottom: 64px;

            @media (max-width: 904px) {
                margin-bottom: 32px;
            }

            h3{
                font-size: 56px;
                line-height: 66px;
                font-weight: $fw-light;
                letter-spacing: -0.5px;
                overflow-wrap: normal;

                @media (max-width: 904px) {
                    font-size: 30px;
                    line-height: 40px;
                }
            }

            p{
                margin-top: 24px;
                font-size: 24px;
                line-height: 34px;
                font-weight: $fw-light;

                @media (max-width: 904px) {
                    margin-top: 16px;
                    font-size: 18px;
                    line-height: 26px;
                }
            }

            .arrow-link{
                margin-top: 24px;
                font-size: 24px;
                line-height: 34px;
                font-weight: $fw-light;

                @media (max-width: 904px) {
                    margin-top: 16px;
                    margin-bottom: 8px;
                    font-size: 18px;
                    line-height: 26px;
                }

                .icon{
                    @media (max-width: 904px) {
                        width: 24px;
                        height: 24px;
                        background-image: url(./../images/icons/arrow/right/arrow-right-24-red.svg);
                    }
                }
            }
        }

        .part-link-list__col{
            &:last-child{
                .link-list{
                    ul{
                        li{
                            &:last-child{
                                @media (max-width: 904px) {
                                    border-bottom: 1px solid $solid-100;
                                }
                            }
                        }
                    }
                }
            }

            .link-list{
                ul{
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    border: none;

                    @media (max-width: 904px) {
                        flex-direction: column;
                    }

                    li{
                        width: 100%;
                        border-top: 1px solid $solid-100;
                        border-bottom: none;

                        @media (max-width: 904px) {
                            width: 100%;
                        }

                        &:last-child{
                            border-bottom: 1px solid $solid-100;

                            @media (max-width: 904px) {
                                border-bottom: none;
                            }
                        }

                        .arrow-link{
                            align-items: flex-start;
                            padding: 29px 15px 29px 0;

                            @media (max-width: 904px) {
                                padding: 18px 0 18px 0;
                            }

                            .text-wrapper{
                                display: flex;
                                justify-content: center;
                                flex-direction: column;
                                min-height: 48px;

                                @media (max-width: 904px) {
                                    min-height: 32px;
                                }

                                h4{
                                    font-size: 28px;
                                    font-weight: $fw-light;
                                    line-height: 38px;

                                    @media (max-width: 904px) {
                                        font-size: 20px;
                                        line-height: 27px;
                                    }
                                }

                                p{
                                    margin-top: 5px;
                                    font-size: 18px;
                                    line-height: 26px;
                                    font-weight: $fw-light;

                                    @media (max-width: 904px) {
                                        margin-top: 12px;
                                        font-size: 15px;
                                        line-height: 22px;
                                    }
                                }
                            }

                            .icon{
                                @media (max-width: 904px) {
                                    &--arrow-right-48-red{
                                        width: 32px;
                                        height: 32px;
                                        background-image: url('./../images/icons/arrow/right/arrow-right-32-red.svg');
                                    }
                                }

                                @media (max-width: 904px) {
                                    &--arrow-up-right-48-red{
                                        width: 36px;
                                        height: 36px;
                                        background-image: url('./../images/icons/arrow/up-right/arrow-up-right-36-red.svg');
                                    }
                                }
                            }

                            &--animation-up-right{
                                .icon{
                                    width: 48px;
                                    height: 48px;
                                    background-image: url('./../images/icons/arrow/up-right/arrow-up-right-48-red.svg');

                                    @media (max-width: 904px) {
                                        width: 36px;
                                        height: 36px;
                                        background-image: url('./../images/icons/arrow/up-right/arrow-up-right-36-red.svg');
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &--warm{
        background-color: $warm-40;
    }

    &--contrast{
        background-color: $contrast-60;
    }

    &--white{
        .wrapper{
            padding: 0;
        }
    }

    &--compact{
        &.part-link-list--warm{
            background-color: transparent;

            @media (max-width: 904px) {
                background-color: $warm-40;
            }

            .wrapper{
                background-color: $warm-40;
            }
        }

        &.part-link-list--contrast{
            background-color: transparent;

            @media (max-width: 904px) {
                background-color: $contrast-60;
            }

            .wrapper{
                background-color: $contrast-60;
            }
        }

        &.part-link-list--warm , &.part-link-list--contrast{
            .wrapper{
                @media (max-width: 1140px) {
                    padding-right: 32px;
                    padding-left: 32px;
                }

                @media (max-width: 904px) {
                    background-color: transparent;
                    padding-right: 0;
                    padding-left: 0;
                }
            }
        }
    }
}
