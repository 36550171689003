// Grid config
@import "base/grid-config";

//import bootstrap
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";
@import "node_modules/bootstrap/scss/grid";
@import "node_modules/bootstrap/scss/buttons";
@import "node_modules/bootstrap/scss/reboot";
// @import "node_modules/bootstrap/scss/alert";
// @import "node_modules/bootstrap/scss/badge";
// @import "node_modules/bootstrap/scss/breadcrumb";
// @import "node_modules/bootstrap/scss/button-group";
// @import "node_modules/bootstrap/scss/card";
// @import "node_modules/bootstrap/scss/carousel";
// @import "node_modules/bootstrap/scss/close";
// @import "node_modules/bootstrap/scss/code";
// @import "node_modules/bootstrap/scss/custom-forms";
// @import "node_modules/bootstrap/scss/dropdown";
// @import "node_modules/bootstrap/scss/forms";
// @import "node_modules/bootstrap/scss/images";
// @import "node_modules/bootstrap/scss/input-group";
// @import "node_modules/bootstrap/scss/jumbotron";
// @import "node_modules/bootstrap/scss/list-group";
// @import "node_modules/bootstrap/scss/media";
// @import "node_modules/bootstrap/scss/modal";
// @import "node_modules/bootstrap/scss/nav";
// @import "node_modules/bootstrap/scss/navbar";
// @import "node_modules/bootstrap/scss/pagination";
// @import "node_modules/bootstrap/scss/popover";
// @import "node_modules/bootstrap/scss/print";
// @import "node_modules/bootstrap/scss/progress";
// @import "node_modules/bootstrap/scss/root";
// @import "node_modules/bootstrap/scss/spinners";
// @import "node_modules/bootstrap/scss/tables";
// @import "node_modules/bootstrap/scss/toasts";
// @import "node_modules/bootstrap/scss/tooltip";
// @import "node_modules/bootstrap/scss/transitions";
//@import "node_modules/bootstrap/scss/type";
// @import "node_modules/bootstrap/scss/utilities";

// Base
@import "base/colors";
@import "base/font-face";
@import "base/typography";
@import "base/global";
@import "base/icons";
@import "base/image-aspect-ratio";
@import "base/htmlArea.scss";

// Pages
@import "pages/my-page";
@import "pages/information-check";
@import "pages/confirmation-modal";
@import "pages/my-membership";
@import "pages/e-learning";
@import "pages/documents-page";
@import "pages/front-page.scss";
@import "pages/article.scss";
@import "pages/campaign-page.scss";
@import "pages/content-page.scss";
@import "pages/registration-page.scss";
@import "pages/order-page.scss";
@import "pages/horingsuttalelser-page.scss";
@import "pages/nyheter.scss";
@import "pages/404.scss";
@import "pages/calendar-pages.scss";
@import "pages/search-results.scss";
@import "pages/contact-person.scss";
@import "pages/glossary.scss";
@import "pages/association-pages.scss";
@import "pages/sign-up.scss";
@import "pages/login.scss";
@import "pages/forum.scss";
@import "pages/mine-vervinger.scss";
@import "pages/newsletter.scss";
@import "pages/list-view-page.scss";

// Components
@import "components/buttons";
@import "components/breadcrumbs.scss";
@import "components/header";
// @import "components/footer"; OLD
@import "components/forms";
@import "components/popup-menu";
@import "components/cards";
@import "components/link-list";
@import "components/local-organisation";
@import "components/forum-card";
@import "components/card-with-coloured-background";
// @import "components/part-news"; OLD
@import "components/part-accordion-card";
@import "components/part-article-links";
@import "components/form-table";
@import "components/accordion";
@import "components/search-drawer";
@import "components/part-streik-module.scss";
@import "components/part-items-with-arrows.scss";
@import "components/part-link-list.scss";
@import "components/part-entry-cards.scss";
@import "components/part-related-news.scss";
@import "components/part-article-previews.scss";
@import "components/part-links-outside-accordion.scss";
@import "components/part-link-cards.scss";
@import "components/part-felles-module.scss";
@import "components/macro-testimonials.scss";
@import "components/part-salary-calculator.scss";
@import "components/part-illustration-card.scss";
@import "components/part-link-set.scss";
@import "components/part-text-and-media.scss";
@import "components/footer.scss";
@import "components/macro-image-gallery.scss";
@import "components/featured-hero.scss";
@import "components/article-hero.scss";
@import "components/calendar.scss";
@import "components/accordion-contact.scss";
@import "components/part-accordion-card-link.scss";
@import "components/macro-table-logo.scss";
@import "components/cookie-banner.scss";
@import "components/part-membership-table.scss";
@import "components/part-anonymous-notification.scss";
@import "components/part-card.scss";
@import "components/part-table.scss";
@import "components/part-contact-table.scss";
@import "components/form-builder.scss";
@import "components/part-contact-card.scss";
@import "components/for-video-player.scss";
@import "components/datepicker.scss";
