// page
.page.contact-person{
  .htmlArea__container{
    .htmlArea{
      h4{
        @media (min-width: 905px) {
          margin-bottom: 32px;
        }
      }

      hr{
        margin: 32px 0 40px;

        @media (max-width: 904px) {
          margin: 40px 0 16px;
        }

        &:last-child{
          margin-bottom: 0;
        }
      }

      .contact-person__card{
        &+hr{
          @media (min-width: 905px) {
            margin-top: -16px;
          }
        }
      }
    }
  }
}

// card
.contact-person__card{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 48px;

  @media (max-width: 599px) {
    flex-direction: column;
    margin-bottom: 40px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  .image-wrapper{
    width: 35%;

    @media (max-width: 904px) {
      width: 36%;
    }

    @media (max-width: 599px) {
      width: 73%;
      margin-bottom: 16px;
    }
  }

  .text{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    width: calc(65% - 32px);
    padding-bottom: 7px;

    @media (max-width: 904px) {
      width: calc(64% - 16px);
    }

    @media (max-width: 599px) {
      width: 100%;
    }

    p{
      font-size: 18px;
      line-height: 28px;
      font-weight: $fw-light;

      @media (max-width: 904px) {
        font-size: 18px;
        line-height: 26px;
      }
    }

    h6{
      font-size: 22px;
      line-height: 32px;
      font-weight: $fw-medium;

      @media (max-width: 904px) {
        font-size: 18px;
        line-height: 26px;
      }

      @media (max-width: 599px) {
        font-weight: $fw-semi-bold;
      }
    }

    .contact-info{
      margin: 28px 0;

      @media (max-width: 599px) {
        margin: 26px 0;
      }

      p{
        .label{
          margin-right: 1px;
          font-weight: $fw-medium;

          @media (max-width: 599px) {
            font-weight: $fw-semi-bold;
          }
        }
      }
    }
  }
}
