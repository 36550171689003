// Site header
.site-header{
    transition: background-color 0.2s;
    background-color: $white;

    &--coloured , &--warm-40{
        background-color: $warm-40;
    }

    &--yellow-10{
        background-color: $yellow-10;
    }

    &--blue-20{
        background-color: $blue-20;
    }

    &--contrast-20{
        background-color: $contrast-20;
    }

    &--moss-green-10{
        background-color: $moss-green-10;
    }

    &__content{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: -50px;
        margin-right: -50px;
        padding: 78px 0 9px;

        @media (max-width: 1280px) {
            margin-left: 0;
            margin-right: 0;
        }

        @media (max-width: 904px) {
            padding: 40px 0 23px;
        }

        .site-logo{
            a{
                img{
                    width: 136px;

                    @media (max-width: 904px) {
                        width: 103px;
                    }
                }
            }
        }
    }

    .desktop-nav, .mobile-nav{
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: auto;
        margin-right: 50px;

        @media (max-width: 904px) {
            display: none;
        }

        > li{
            margin-right: 40px;

            &:last-child{
                margin-right: 0;
            }

            > button , > a{
                display: flex;
                flex-direction: row;
                align-items: flex-start;
                font-size: 20px;
                line-height: 28px;
                font-weight: $fw-book;
                color: $main-text-color;
            }

            &.user , &.search{
                button , a{
                    &:hover{
                        @media (min-width: 905px) {
                            .text{
                                position: relative;

                                &:after{
                                    content: "";
                                    display: block;
                                    width: 100%;
                                    height: 2px;
                                    background: $main-text-color;
                                    position: absolute;
                                    top: calc(100% - 1px);
                                }
                            }
                        }
                    }

                    .icon{
                        margin-top: 2px;
                        margin-left: 10px;
                    }
                }
            }

            &.user{
                position: relative;

                .user-avatar{
                    display: none;
                }

                &--logged-in{
                    > .open__popup-menu{
                        align-items: center;
                        text-align: right;
                        padding-left: 10px;

                        .icon{
                            display: none;
                        }
                    }

                    .popup-menu{
                        &.active{
                            display: flex;
                            z-index: 10;
                        }
                    }

                    .user-avatar{
                        display: block;
                    }
                }

                .popup-menu{
                    display: none;

                    li{
                        &:last-child{
                            border: none;
                            text-align: right;
                        }

                        .btn-logout{
                            margin-top: 56px;
                            margin-left: auto;
                            font-size: 18px;
                            line-height: normal;
                            font-weight: $fw-book;
                        }
                    }
                }
            }

            &.language{
                display: flex;
                flex-direction: row;
                align-items: flex-start;

                a{
                    color: $solid-60;

                    &.active{
                        position: relative;
                        color: $main-text-color;

                        &::after{
                            content: "";
                            display: block;
                            width: 100%;
                            height: 2px;
                            background: $main-text-color;
                            position: absolute;
                            top: calc(100% - 1px);

                            @supports (-moz-appearance:none) {
                                top: calc(100% - 0px);
                            }
                        }
                    }
                }

                span{
                    margin-top: 2px;
                    margin-left: 3px;
                    margin-right: 3px;
                }
            }
        }
    }

    &__btn-menu{
        padding: 14px 31px;

        &:focus-visible{
            padding: 13px 30px;
        }

        @media (max-width: 904px) {
            margin-left: auto;
            padding: 7px 15px;
            font-size: 15px;
            line-height: 26px;

            &:active{
                @media (min-width: 905px) {
                    outline: none;
                    box-shadow: none;
                    background-color: transparent;
                    border-color: transparent;
                }
            }
        }

        &.active{
            .icon{
                background-image: url('./../images/icons/x/x-24.svg');
            }
        }

        .icon{
            margin-top: 1px;
        }
    }

    .close-search{
        display: none;
    }

    &__menu{
        display: none;
        position: fixed;
        background-color: $white;
        width: 100%;
        transition: opacity 0.15s;
        opacity: 0;
        z-index: 1;

        .overflow-wrapper{
            max-height: calc(100vh - 143px);
            overflow: auto;
            padding: 102px 0 100px;

            @media (max-width: 904px) {
                max-height: calc(100vh - 105px);
                padding: 24px 0 108px;
            }

            .mobile-nav{
                display: none;

                @media (max-width: 904px) {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 59px;
                }
            }

            .button-wrapper{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                @media (max-width: 904px) {
                    margin-bottom: 25px;
                }

                .btn{
                    margin-bottom: 56px;

                    @media (max-width: 904px) {
                        margin-bottom: 25px;
                    }

                    &:first-child{
                        @media (max-width: 904px) {
                            margin-right: 24px;
                        }

                        @media (max-width: 375px) {
                            margin-right: 10px;
                        }
                    }

                    &--user{
                        display: none;

                        @media (max-width: 904px) {
                            display: inline-flex;
                            padding-right: 18px;
                            padding-left: 20px;
                            font-size: 18px;
                        }

                        @media (max-width: 375px) {
                            padding: 11px 9px;
                            font-size: 16px;
                        }
                    }

                    &--become-member{
                        @media (max-width: 904px) {
                            padding: 11px 13px;
                            border-radius: 8px;
                            font-size: 18px;
                            line-height: 26px;
                        }

                        @media (max-width: 375px) {
                            padding: 11px 9px;
                            font-size: 16px;
                        }

                        .icon{
                            @media (max-width: 904px) {
                                width: 24px;
                                height: 24px;
                                background-image: url(./../images/icons/arrow/right/arrow-right-24-white.svg);
                            }
                        }
                    }
                }
            }

            .user-nav{
                display: none;
            }

            .link-list__col{
                &:last-child{
                    @media (max-width: 904px) {
                        .link-list{
                            ul{
                                border-top: none;
                            }
                        }
                    }
                }

                .link-list{
                    ul{
                        li{
                            a{
                                padding-right: 14px;

                                @media (max-width: 904px) {
                                    padding-right: 0;
                                }

                                .text-wrapper{
                                    padding-right: 8px;

                                    @media (max-width: 904px) {
                                        padding-right: 2px;
                                    }
                                }

                                .icon{
                                    @media (max-width: 904px) {
                                        width: 32px;
                                        height: 32px;
                                        background-image: url(./../images/icons/arrow/right/arrow-right-32-red.svg);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.active{
            opacity: 1;
            box-shadow: 0px 18px 25px -10px #00000021;
        }

        &--logged-in{
            .overflow-wrapper{
                .button-wrapper{
                    .btn--user{
                        display: none;
                    }
                }

                .user-nav{
                    @media (max-width: 904px) {
                        display: block;

                        .wrapper{
                            width: 100%;
                            margin-bottom: 40px;
                            padding: 31px 34px;
                            background-color: $warm-40;

                            .user-nav__name{
                                display: flex;
                                flex-direction: row;
                                margin-bottom: 18px;

                                p{
                                    font-size: 20px;
                                    line-height: 27px;
                                    font-weight: $fw-light;
                                }
                            }

                            .link-list{
                                ul{
                                    li{
                                        .arrow-link{
                                            padding-top: 12px;
                                            padding-bottom: 12px;

                                            .icon{
                                                &--arrow-right-32-red{
                                                    @media (max-width: 904px) {
                                                        width: 32px;
                                                        height: 32px;
                                                        background-image: url('./../images/icons/arrow/right/arrow-right-32-red.svg');
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .btn-logout{
                                margin-top: 25px;
                                font-size: 20px;
                                line-height: 27px;
                                font-weight: $fw-light;
                            }
                        }
                    }
                }
            }
        }
    }

    &.open-menu{
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 9;
        background-color: $white;
    }

    &.open-search{
        position: fixed;
        top: 0;
        width: 100vw;
        z-index: 9;
        background-color: $white;

        .site-header__content{
            .desktop-nav{
                display: none;
            }
        }

        .site-header__btn-menu{
            display: none;
        }

        .close-search{
            display: inline-flex;
            margin-left: auto;
            padding: 14px 38px;

            &:focus-visible{
                padding: 13px 37px;
            }
        }
    }

    &__search{
        display: none;
        position: fixed;
        width: 100%;
        padding: 148px 0 134px;
        background-color: $white;
        transition: opacity 0.15s;
        opacity: 0;
        z-index: 1;

        &.active{
            opacity: 1;
            box-shadow: 0px 18px 25px -10px #00000021;
        }

        form{
            .icon--search-24{
                position: absolute;
                top: 12px;
            }

            input[type="text"]{
                width: 100%;
                padding-left: 46px;
                border: none;
                outline: none;
                color: $solid-80;
                font-size: 28px;
                line-height: 42px;
                font-weight: $fw-light;
            }

            .button-wrapper{
                margin-top: 22px;
                padding-top: 38px;
                border-top: 1px solid $solid-40;
                text-align: right;

                .btn{
                    padding: 15px 60px;

                    &:focus-visible{
                        padding: 14px 59px;
                    }
                }
            }
        }
    }

    .user-avatar{
        display: block;
        min-width: 32px;
        width: 32px;
        height: 32px;
        margin-left: 10px;
        padding-top: 3px;
        border-radius: 100%;
        background-color: transparent;
        color: $white;
        font-size: 14px;
        line-height: 24px;
        font-weight: $fw-bold;
        text-align: center;
    }
}

// Page header
.page-header{
    margin-top: 84px;
    margin-bottom: 73px;

    @media (max-width: 904px) {
        margin-top: 20px;
        margin-bottom: 30px;
    }

    &__header{
        h1{
            font-size: 64px;
            line-height: 82px;
            font-weight: $fw-light;
            text-align: center;

            @media (max-width: 1280px) {
                font-size: 46px;
                line-height: 56px;
            }

            @media (max-width: 904px) {
                font-size: 35px;
                line-height: 45px;
                text-align: left;
            }
        }
    }

    &--coloured{
        margin-top: 0;
        margin-bottom: 96px;
        padding: 110px 0 50px;
        background-color: $warm-40;
        text-align: center;

        @media (max-width: 904px) {
            margin-bottom: 34px;
            padding: 45px 0px 36px;
            text-align: left;
        }

        .page-header__header{
            .navigation{
                margin-bottom: 54px;
                font-size: 24px;
                line-height: 34px;
                font-weight: $fw-book;

                @media (max-width: 904px) {
                    margin-bottom: 46px;
                    font-size: 18px;
                    line-height: 24px;
                    font-weight: $fw-light;
                }

                .arrow-link{
                    font-size: 18px;
                    line-height: 26px;

                    @media (max-width: 904px) {
                        font-size: 14px;
                        line-height: 24px;
                        font-weight: $fw-book;
                    }

                    .user-avatar{
                        display: none;
                        width: 24px;
                        height: 24px;
                        margin-right: 10px;
                        border-radius: 100%;
                        background-color: $red-80;
                        color: $white;
                        font-family: Arial, Helvetica, sans-serif;
                        font-size: 12px;
                        line-height: 24px;
                        font-weight: $fw-bold;
                        text-align: center;

                        @media (max-width: 904px) {
                            display: block;
                        }
                    }
                }
            }

            h1{
                .user-name{
                    @media (max-width: 904px) {
                        display: block;
                    }
                }
            }

            h2{
                margin-top: 25px;
                margin-bottom: 16px;
                font-size: 24px;
                line-height: 34px;
                font-weight: $fw-light;

                @media (max-width: 1280px) {
                    font-size: 20px;
                    line-height: 30px;
                }

                @media (max-width: 904px) {
                    margin-bottom: 0;
                    margin-top: 21px;
                    font-size: 16px;
                    line-height: 24px;
                }
            }
        }
    }

    &--forum{
        &.page-header--coloured{
            margin-bottom: 96px;
            padding: 110px 0 62px;

            @media (max-width: 904px) {
                margin-bottom: 48px;
                padding: 30px 0 43px;
            }

            .navigation{
                margin-bottom: 0;

                @media (max-width: 904px) {
                    margin-bottom: 0;
                }
            }

            h1{
                margin-top: 61px;

                @media (max-width: 904px) {
                    margin-top: 46px;
                }

                &:last-child{
                    padding-bottom: 70px;

                    @media (max-width: 904px) {
                        padding-bottom: 6px;
                    }
                }
            }
        }
    }
}
