.app-cookie-line {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: $contrast-40;
  padding: 62px 0 72px;
  box-shadow: 0px 12px 30px #00000066;
  z-index: 99;

  @media (max-width: 904px) {
    padding: 32px 0;
  }

  &.is-hidden{
    display: none;
  }

  .app-cookie-line-inner {
    position: relative;
    width: 100%;
    padding-left: 200px !important;
    padding-right: 200px !important;
    margin-right: auto;
    margin-left: auto;

    @media (min-width: 320px) {
      max-width: 100%;
    }

    @media (min-width: 600px) {
      max-width: 100%;
    }

    @media (min-width: 905px) {
      max-width: 100%;
    }

    @media (min-width: 1140px) {
      max-width: 1040px;
    }

    @media (min-width: 1440px) {
      max-width: 1680px;
    }

    @media (max-width: 1439px) {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    @media (max-width: 1139px) {
      padding-left: 50px !important;
      padding-right: 50px !important;
    }

    @media (max-width: 904px) {
      padding-left: 32px !important;
      padding-right: 32px !important;
    }

    .app-cookie-line-title {
      font-size: 28px;
      line-height: 38px;
      font-weight: $fw-light;

      @media (max-width: 904px) {
        font-size: 24px;
        line-height: 23px;
      }
    }

    .app-cookie-line-intro {
      margin-top: 15px;
      font-size: 18px;
      line-height: 26px;
      font-weight: $fw-light;

      @media (max-width: 904px) {
        margin-top: 12px;
        font-size: 16px;
        line-height: 22px;
      }
    }

    .app-cookie-line-title,
    .app-cookie-line-intro {
      max-width: 57%;

      @media (max-width: 1139px) {
        max-width: 48%;
      }

      @media (max-width: 904px) {
        max-width: 100%;
      }
    }

    .app-cookie-line-buttons {
      position: absolute;
      top: 0px;
      right: 200px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @media (max-width: 1439px) {
        right: 0;
      }

      @media (max-width: 1139px) {
        right: 50px;
      }

      @media (max-width: 904px) {
        position: relative;
        right: 0;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        margin-top: 24px;
      }

      a {
        &.mod-read-more {
          margin-right: 57px;
          font-size: 20px;
          line-height: 28px;
          font-weight: $fw-medium;
          border-bottom: 1px solid $main-text-color;

          @media (max-width: 1139px) {
            margin-right: 32px;
          }

          @media (max-width: 904px) {
            font-size: 16px;
            line-height: 22px;
          }

          &:last-child{
            margin-right: 0;
            margin-left: 57px;

            @media (max-width: 904px) {
              margin-left: 32px;
            }
          }
        }

        &.mod-accept {
          background-color: $red-100;
          color: $white;
          padding: 19px 30px;
          border-radius: 12px;
          border: 1px solid transparent;
          font-size: 24px;
          font-weight: $fw-light;
          line-height: 34px;

          &:focus {
            outline: none;
            box-shadow: none;
          }

          &:focus-visible {
            padding: 18px 29px;
            box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.2);
            border-width: 2px;
          }

          &:active {
            outline: none;
            box-shadow: none;
            background-color: $red-dark-2;
            border-color: $red-dark-2;
          }

          &:hover {
            color: $white;
          }

          @media (min-width: 905px) {
            padding: 14px 60px;
            font-weight: $fw-book;

            &:focus-visible {
              padding: 13px 59px;
            }
          }

          @media (max-width: 904px) {
            font-size: 19px;
            line-height: 19px;
            padding: 16px 24px;
          }
        }
      }
    }
  }
}
