.page.login-page {
  height: 100%;
  min-height: 100vh;
  padding-top: 30px;
  padding-bottom: 56px;
  background-color: $warm-40;

  .login-card {
    display: flex;
    flex-direction: column;
    background-color: $white;
    border-radius: 23px;
    padding: 58px 77px;

    @media (min-width: 905px) {
      min-height: 800px;
    }

    @media (max-width: 904px) {
      padding: 40px;
      border-radius: 16px;
    }

    @media (max-width: 599px) {
      padding: 32px;
    }

    @media (max-width: 475px) {
      padding: 32px 24px;
    }

    &__header {
      a {
        display: inline-block;
        margin-bottom: 60px;

        @media (max-width: 904px) {
          margin-bottom: 32px;
        }

        figure {
          width: 152px;
          max-height: 59px;

          @media (max-width: 904px) {
            width: 103px;
            max-height: 40px;
          }

          img {
            width: 100%;
          }
        }
      }

      .title {
        @media (max-width: 904px) {
          font-size: 24px;
          line-height: 30px;
        }
      }

      & + .login-card__form--sms {
        margin-top: 32px;

        @media (max-width: 904px) {
          margin-top: 24px;
        }
      }

      & + .login-card__alert {
        margin-top: 32px;
        margin-bottom: 32px;

        @media (max-width: 904px) {
          margin-top: 24px;
          margin-bottom: 24px;
        }

        &.login-card__alert--hide{
          & + .login-card__form {
            margin-top: 50px;

            @media (max-width: 904px) {
              margin-top: 32px;
            }
          }
        }
      }
      h1.title + p {
        margin-top: 50px;

        @media (max-width: 992px) {
          margin-top: 32px;
        }
      }
    }

    &__loading{
      display: none;
    }

    &__form {
      margin-bottom: 66px;

      @media (max-width: 904px) {
        margin-bottom: 56px;
      }

      .form-table {
        &__row {
          .row {
            .cell {
              &--label {
                padding-left: 0;
                padding-right: 0;

                .table-label {
                  @media (min-width: 905px) {
                    font-size: 18px;
                    line-height: normal;
                  }
                }
              }

              &--input {
                input[type="password"] {
                  padding-right: 56px;
                }

                .show-password {
                  position: absolute;
                  right: -30px;
                  top: 6px;
                  font-size: 18px;
                  line-height: normal;
                  font-weight: $fw-light;
                  text-decoration: underline;

                  @media (max-width: 904px) {
                    right: -24px;
                  }

                  @media (max-width: 599px) {
                    right: 0px;
                    top: 7px;
                    font-size: 13px;
                  }
                }
              }
            }
          }
        }

        .for-form {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          margin-top: 38px;

          @media (max-width: 904px) {
            margin-top: 24px;
          }

          .input-group {
            margin-bottom: 0;

            label {
              padding-right: 32px;

              @media (min-width: 905px) {
                font-size: 18px;
                line-height: normal;
              }

              @media (max-width: 904px) {
                padding-right: 16px;
                font-size: 16px;
                line-height: 20px;
              }

              @media (max-width: 599px) {
                padding-right: 16px;
                padding-left: 26px;
              }

              &:before {
                @media (min-width: 905px) {
                  top: 4px;
                }

                @media (max-width: 904px) {
                  top: 1px;
                }
              }

              &:after {
                @media (min-width: 905px) {
                  top: 9px;
                }

                @media (max-width: 904px) {
                  top: 6px;
                }
              }
            }
          }

          p {
            font-weight: $fw-light;

            @media (min-width: 905px) {
              font-size: 18px;
              line-height: normal;
            }

            @media (max-width: 904px) {
              line-height: 24px;
            }

            @media (max-width: 599px) {
              text-align: right;
              white-space: nowrap;
            }
          }
        }
      }

      .login-instruction {
        margin-bottom: 24px;
        font-size: 18px;
        font-weight: $fw-light;

        @media (max-width: 904px) {
          font-size: 16px;
        }
      }

      &--sms {
        @media (min-width: 905px) {
          margin-bottom: 74px;
        }

        .form-table {
          .sms-inputs {
            display: flex;
            flex-direction: row;
            margin: 24px 0 0;

            input {
              width: 100%;
              margin-right: 22px;
              height: 71px;
              padding: 0;
              background-color: $solid-10;
              border: none;
              border-radius: 6px;
              font-size: 40px;
              line-height: 40px;
              font-weight: $fw-light;
              text-align: center;
              box-shadow: inset 1px 1px 4px rgba(0, 0, 0, 0.25);

              /* Chrome, Safari, Edge, Opera */
              &::-webkit-outer-spin-button,
              &::-webkit-inner-spin-button {
                -webkit-appearance: none;
                margin: 0;
              }

              /* Firefox */
              &[type="number"] {
                -moz-appearance: textfield;
              }

              @media (max-width: 904px) {
                margin-right: 8px;
              }

              @media (max-width: 599px) {
                height: 56px;
                font-size: 32px;
              }

              &:last-child {
                margin-right: 0;
              }
            }

            & + .for-form {
              margin-top: 24px;
            }
          }

          .button-wrapper {
            @media (min-width: 905px) {
              margin-top: 74px;
            }
          }
        }
      }
    }

    .btn-resend-code {
      margin-top: 56px;
      font-size: 18px;
      line-height: normal;

      @media (max-width: 904px) {
        align-items: flex-start;
        margin-top: 32px;
        font-size: 16px;
      }

      & + .login-card__footer {
        margin-top: 16px;
      }

      &.btn-resend-email {
        margin-top: 10px;
      }
    }

    .button-wrapper {
      margin-top: auto;
      text-align: right;

      .btn-cancel {
        margin-right: 32px;
        font-size: 18px;
        line-height: 26x;
        font-weight: $fw-light;
        border-bottom: 1px solid $main-text-color;

        @media (max-width: 904px) {
          font-size: 16px;
          line-height: 20px;
        }

        @media (max-width: 375px) {
          margin-right: 16px;
        }
      }
    }

    &__footer {
      margin-top: 40px;
      padding-top: 16px;
      border-top: 1px solid $solid-60;

      p {
        font-size: 16px;
        line-height: normal;
        font-weight: $fw-light;

        strong {
          font-weight: $fw-semi-bold;
        }
      }
    }

    &--loading{
      height: 800px;

      @media (max-width: 904px) {
        min-height: 500px;
        height: calc(100vh - 112px);
      }

      > * , .login-card__alert {
        display: none;
      }

      .login-card__header{
        display: block;

        a{
          margin-bottom: 0;
          pointer-events: none;
        }

        .title{
          display: none;
        }
      }

      .login-card__loading{
        display: block;
        overflow: hidden;
        margin-top: 182px;

        @media (max-width: 904px) {
          margin-top: 124px;
        }
      }
    }

    &--change-password{
      .login-card__form{
        margin-top: 32px;

        @media (max-width: 904px) {
          margin-top: 24px;
        }

        .login-instruction{
          margin-bottom: 24px;
        }

        .login-card__alert{
          margin-bottom: 24px;
        }
      }
    }
  }
}

.login-card__alert {
  display: flex;
  flex-direction: row;
  border: 1px solid $red-error;

  &::before {
    content: "!";
    display: flex;
    align-items: center;
    justify-content: center;
    width: 44px;
    min-width: 44px;
    height: auto;
    color: $white;
    background-color: $red-error;
    font-size: 26px;
  }

  p {
    padding: 12px 14px 11px 14px;
    color: $red-error;
    font-size: 14px;
    font-weight: $fw-light;

    @media (max-width: 904px) {
      padding: 8px 16px 8px;
    }
  }

  &--blue {
    border-color: $blue-100;

    &::before {
      content: "i";
      background-color: $blue-100;
    }

    p {
      color: $blue-100;
    }
  }

  &--hide {
    display: none;
  }
}
