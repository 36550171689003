.association-page__info{
  margin-bottom: 136px;

  @media (max-width: 904px) {
    margin-bottom: 56px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  .info-col{
    @media (max-width: 904px) {
      margin-bottom: 64px;
    }

    &:last-child{
      margin-bottom: 0;
    }

    header{
      margin-bottom: 51px;

      @media (max-width: 1139px) {
        margin-bottom: 24px;
      }

      h3{
        font-size: 36px;
        line-height: 46px;
        font-weight: $fw-light;

        @media (max-width: 904px) {
          font-size: 26px;
          line-height: 36px;
        }
      }
    }

    .htmlArea{
      p{
        margin-bottom: 28px;
        font-size: 20px;
        line-height: 28px;

        @media (max-width: 1139px) {
          margin-bottom: 26px;
          font-size: 18px;
          line-height: 26px;
        }

        &:last-child{
          margin-bottom: 0;
        }
      }

      ul, ol{
        margin: 28px 0;

        @media (max-width: 1139px) {
          margin: 26px 0;
        }

        &:last-child{
          margin-bottom: 0;
        }

        li{
          font-size: 20px;
          line-height: 28px;

          @media (max-width: 1139px) {
            font-size: 18px;
            line-height: 26px;
          }

          &::before{
            line-height: 28px;

            @media (max-width: 1139px) {
              line-height: 26px;
            }
          }
        }
      }

      table{
        width: 100%;

        tr{
          @media (max-width: 904px) {
            display: flex;
            flex-direction: column;
            margin-bottom: 8px;
          }

          &:last-child{
            @media (max-width: 904px) {
              margin-bottom: 0;
            }
          }

          td{
            padding-right: 32px;
            font-size: 22px;
            line-height: 40px;
            font-weight: $fw-light;
            vertical-align: baseline;

            &:last-child{
              padding-right: 0;

              @media (max-width: 904px) {
                padding-bottom: 0;
              }
            }

            @media (max-width: 1139px) {
              font-size: 18px;
              line-height: 26px;
            }

            @media (max-width: 904px) {
              width: 100%;
              padding-right: 0;
              padding-bottom: 4px;
            }

            strong{
              font-weight: $fw-medium;
            }

            a{
              color: $red-dark-3;
              text-decoration: underline;
            }
          }
        }
      }
    }

    .arrow-link{
      margin-top: 40px;
      margin-bottom: 28px;

      &:first-child{
        margin-top: 0;
      }

      &:last-child{
        margin-bottom: 0;
      }

      @media (max-width: 904px) {
        margin-top: 26px;
      }
    }
  }
}

// our-assosiation page ( Våre foreninger )
.our-associations{
  .featured-hero{
    padding-bottom: 128px;

    @media (max-width: 904px) {
      padding-bottom: 56px;
    }

    .intro{
      @media (max-width: 904px) {
        margin-top: 53px;
      }
    }

    .featured-hero__image{
      @media (max-width: 904px) {
        margin-top: 53px;
      }
    }
  }
}

// Association header
.association-header{
  background-color: $moss-green-10;
  padding-top: 100px;
  padding-bottom: 72px;

  @media (max-width: 904px) {
    padding-top: 24px;
    padding-bottom: 24px;
  }

  .breadcrumbs__wrapper{
    margin-bottom: 72px;

    @media (max-width: 904px) {
      margin-bottom: 55px;
    }
  }

  .arrow-link{
    @media (max-width: 904px) {
      align-items: flex-start;
    }

    .icon{
      margin-right: 35px;

      @media (max-width: 904px) {
        margin-top: 3px;
        margin-right: 8px;
      }

      &--arrow-left-48-red{
        @media (max-width: 904px) {
          width: 24px;
          height: 24px;
          background-image: url('./../images/icons/arrow/left/arrow-left-24-red.svg');
        }
      }
    }
  }

  &--warm-40{
    background-color: $warm-40;
  }
}

.top-filter-header{
  margin-top: 96px;
  margin-bottom: 40px;

  @media (max-width: 904px) {
    margin-top: 50px;
    margin-bottom: 24px;
  }

  .filter-header__wrapper{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    @media (max-width: 904px) {
      align-items: baseline;
    }

    .title{
      padding-right: 32px;

      @media (max-width: 904px) {
        padding-right: 16px;
      }
    }

    .btn-wrapper{
      margin-left: auto;

      .btn{
        margin-right: 16px;

        &:last-child{
          margin-right: 0;
        }
      }
    }
  }
}

.association-article-header{
  margin-top: 96px;
  margin-bottom: 96px;

  @media (max-width: 904px) {
    margin-top: 50px;
    margin-bottom: 40px;
  }

  p{
    margin-top: 48px;

    @media (max-width: 1280px) {
      margin-top: 32px;
      font-size: 26px;
      line-height: 36px;
    }

    @media (max-width: 904px) {
      margin-top: 24px;
      font-size: 18px;
      line-height: 26px;
    }
  }
}
