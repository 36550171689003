.for-video-player {
    .play-btn {
        position: absolute;
        top: calc(50% - 65px);
        left: calc(50% - 65px);
        opacity: 0.7;

        @media (max-width: 904px) {
            top: calc(50% - 48px);
            left: calc(50% - 48px);
        }

        .icon {
            @media (max-width: 904px) {
                width: 96px;
                height: 96px;
            }
        }
    }

    iframe {
        display: none;
    }

    &.ready {
        figure.img {
            padding: 0;

            iframe {
                position: relative;
                top: 0;
                left: 0;
                object-fit: contain;
                object-position: top left;
                transform: none;
                border: none;
                background-color: $solid-10;
            }
        }
    }

    &.no-image{
        &.ready{
            figure.img{
                height: 38vw;
                max-height: 700px;

                @media (max-width: 1439px) {
                    height: 42vw;
                }

                @media (max-width: 904px) {
                    height: 52vw;
                    min-height: 215px;
                }
            }
        }
    }
}
