.datepicker {
    width: 100%;
    z-index: 1;

    &__wrapper {
        width: 100%;
    }

    .datepicker-picker {
        background-color: transparent;
        gap: 8px;

        @media (max-width: 904px) {
            gap: 4px;
        }

        .datepicker-header {

            .datepicker-controls {
                align-items: center;
                padding: 0 0 16px 0;
                border-bottom: 1px solid $solid-100;

                @media (max-width: 904px) {
                    padding: 0;
                    border: none;
                }

                &>* {
                    background-color: transparent;
                }

                .button {
                    &:not(.view-switch) {
                        width: 24px;
                        height: 24px;
                        flex: none;
                        background-color: $red-100;
                        border-radius: 4px;
                        color: transparent;
                        background-image: url('./../images/icons/arrow/up/arrow-up-16-white.svg');
                        background-repeat: no-repeat;
                        background-position: center;
                        background-size: 16px;

                        &.prev-button {
                            transform: rotate(-90deg);
                        }

                        &.next-button {
                            transform: rotate(90deg);
                        }
                    }

                    &.view-switch {
                        height: auto;
                        padding: 0;
                        pointer-events: none;
                        color: $main-text-color;
                        font-weight: 600;
                        text-transform: capitalize;

                    }
                }
            }
        }

        .datepicker-main {
            padding: 0;

            .datepicker-view {
                width: 100%;

                .days {
                    @media (max-width: 904px) {
                        gap: 8px;
                    }

                    .days-of-week {
                        @media (max-width: 904px) {
                            border-top: 1px solid $solid-20;
                            border-bottom: 1px solid $solid-20;
                        }

                        .dow {
                            height: auto;
                            padding: 12px 0;
                            color: $solid-80;
                            font-weight: 600;

                            @media (max-width: 904px) {
                                padding: 0;
                                line-height: 1.2;
                            }

                        }
                    }

                    .datepicker-grid {
                        row-gap: 8px;

                        .day {
                            background-color: transparent;
                            height: auto;
                            padding: 8px 0;
                            color: $main-text-color;
                            z-index: 1;
                            font-weight: 600;


                            &.disabled {
                                color: $solid-60;
                                font-weight: 400;
                            }

                            &.selected,
                            &.focused,
                            &.first-available-date,
                            &.current-date {
                                position: relative;

                                &::before {
                                    content: "";
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    width: 40px;
                                    height: 40px;
                                    border-radius: 4px;
                                    z-index: -1;
                                }
                            }

                            &.selected {
                                color: white;


                                &::before {
                                    background-color: $red-100;
                                }
                            }

                            &.next,
                            &.prev {
                                color: $red-40;
                            }

                            &.focused {
                                &:not(.selected):not(.current-date) {
                                    &::before {
                                        // background-color: $red-60;
                                    }

                                    &.first-available-date {
                                        // color: colors.$text-primary;

                                        &::before {
                                            // background-color: colors.$pink-400;
                                        }
                                    }
                                }


                                // &.selected, &.first-available-date, &.current-date{
                                //     &::before{
                                //         outline: 2px solid colors.$buttons-enabled;
                                //         outline-offset: 2px;
                                //     }
                                // }
                            }

                            &.hidden {
                                display: none;
                            }

                            &.current-date {
                                &::before {
                                    border: 1px solid $solid-100;
                                }
                            }

                            &.first-available-date {
                                &:not(.selected) {
                                    // color: colors.$orange-900;

                                    // &:hover{
                                    //     &::before{
                                    //         background-color: colors.$peach-300;
                                    //     }
                                    // }

                                    // &::before{
                                    //     background-color: colors.$peach-200;
                                    // }
                                }
                            }
                        }
                    }
                }
            }
        }

        .datepicker-footer {
            display: none;
        }
    }

    &__caption {
        padding: 8px 16px;
        border-radius: 6px;
        cursor: default;

        &--first-day {}

        &--disabled {
            // color: colors.$text-placeholder;
        }
    }
}

.datepicker__wrapper {
    max-width: 22rem;
    margin-inline: auto;
}
