.link-cards{
    margin-bottom: 96px;

    @media (max-width: 904px) {
        margin-bottom: 56px;
    }

    &:last-child{
       margin-bottom: -40px;

        @media (max-width: 904px) {
            margin-bottom: -32px;
        }
    }

    &__header{
        margin-bottom: 32px;

        @media (max-width: 904px) {
            margin-bottom: 30px;
        }

        h3{
            font-size: 40px;
            line-height: 50px;
            font-weight: $fw-light;

            @media (max-width: 904px) {
                font-size: 30px;
                line-height: 40px;
            }
        }
    }

    &__card{
        margin-bottom: 40px;

        @media (max-width: 904px) {
            margin-bottom: 32px;
        }

        &:last-child{
            @media (max-width: 904px) {
                margin-bottom: 0px;
            }
        }

        .card-wrapper{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            height: 100%;
            background-color: $warm-60;
            padding: 48px 47px 36px 46px;
            word-break: break-word;

            @media (max-width: 904px) {
                padding: 40px 28px 30px;
            }

            .text{
                position: relative;
                height: 100%;
                display: flex;
                flex-direction: column;

                .title{
                    @media (max-width: 904px) {
                        font-size: 20px;
                        line-height: 28px;
                    }
                }

                p{
                    margin-top: 12px;
                    font-size: 20px;
                    line-height: 28px;
                    font-weight: $fw-light;

                    @media (max-width: 904px) {
                        margin-top: 8px;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }

            .icon{
                margin-top: 24px;

                @media (max-width: 904px) {
                    margin-top: 17px;
                }

                &--arrow-right-48-red{
                    @media (max-width: 904px) {
                        width: 32px;
                        height: 32px;
                        background-image: url('./../images/icons/arrow/right/arrow-right-32-red.svg');
                    }
                }

                &--arrow-up-right-48-red , &--arrow-up-right-32-red , &--arrow-up-right-36-red{
                    @media (max-width: 904px) {
                        width: 36px;
                        height: 36px;
                        background-image: url('./../images/icons/arrow/up-right/arrow-up-right-36-red.svg');
                    }
                }

                &--download{
                    @media (max-width: 904px) {
                        width: 32px;
                        height: 32px;
                        background-image: url('./../images/icons/download/download-32-red.svg');
                    }

                    path{
                        @media (max-width: 904px) {
                            display: none;
                        }
                    }
                }
            }

            .arrow-link{
                @media (min-width: 905px) {
                    margin-top: 40px !important;
                }

                @media (max-width: 904px) {
                    margin-top: 17px;
                }

                .icon{
                    margin-top: 0 !important;

                    &--arrow-up-right-48-red , &--arrow-up-right-32-red , &--arrow-up-right-36-red{
                        @media (max-width: 904px) {
                            width: 24px;
                            height: 24px;
                            background-image: url('./../images/icons/arrow/up-right/arrow-up-right-24-red.svg');
                        }
                    }

                    &--download{
                        @media (max-width: 904px) {
                            width: 24px;
                            height: 24px;
                            background-image: url('./../images/icons/download/download-24-red.svg');
                        }

                        path{
                            @media (max-width: 904px) {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        &--yellow-20{
            .card-wrapper{
                background-color: $yellow-20;
            }
        }

        &--yellow-60{
            .card-wrapper{
                background-color: $yellow-60;
            }
        }

        &--green{
            .card-wrapper{
                background-color: $moss-green-10;
            }
        }

        &--white{
            @media (max-width: 904px) {
                margin-bottom: 44px;
            }

            .card-wrapper{
                background-color: $white;
                padding: 0 !important;
            }
        }
    }

    &--4clm{
        .link-cards__card{
            .card-wrapper{
                @media (max-width: 1140px) {
                    padding: 40px 28px 30px;
                }

                .arrow-link{
                    @media (min-width: 905px) {
                        padding-bottom: 12px;
                    }
                }
            }
        }
    }

    &--6clm{
        .link-cards__card{
            .card-wrapper{
                padding: 48px 44px 28px 46px;

                @media (max-width: 904px) {
                    padding: 40px 28px 30px;
                }

                .arrow-link{
                    @media (min-width: 905px) {
                        padding-bottom: 20px;
                    }
                }
            }
        }
    }

    &--lg{
        &.link-cards--6clm{
            .link-cards__card{
                .card-wrapper{
                    padding: 80px 110px 64px 108px;

                    @media (max-width: 1140px) {
                        padding: 40px 28px 30px;
                    }

                    .text{
                        height: auto;

                        p{
                            margin-top: 16px;

                            @media (max-width: 904px) {
                                margin-top: 8px;
                            }
                        }
                    }

                    .arrow-link{
                        @media (min-width: 905px) {
                            padding-bottom: 0;
                        }
                    }

                    .icon{
                        margin-top: 32px;

                        @media (max-width: 904px) {
                            margin-top: 17px;
                        }
                    }
                }
            }
        }
    }

    &--xl{
        &.link-cards--6clm{
            .link-cards__card{
                .card-wrapper{
                    justify-content: center;
                    padding: 80px 77px 72px 75px;

                    @media (max-width: 1140px) {
                        padding: 40px 28px 30px;
                    }

                    .text{
                        height: auto;

                        .title{
                            font-size: 40px;
                            line-height: 50px;

                            @media (max-width: 1140px) {
                                font-size: 48px;
                                line-height: 58px;
                            }

                            @media (max-width: 904px) {
                                font-size: 30px;
                                line-height: 40px;
                            }
                        }

                        p{
                            margin-top: 24px;
                            font-size: 24px;
                            line-height: 34px;
                            font-weight: $fw-light;

                            @media (max-width: 904px) {
                                margin-top: 8px;
                                font-size: 16px;
                                line-height: 24px;
                            }
                        }
                    }

                    .arrow-link{
                        @media (min-width: 905px) {
                            padding-bottom: 0;
                        }
                    }
                }
            }
        }
    }
}
