.illustration-card{
    margin-bottom: 136px;

    @media (max-width: 904px) {
        margin-bottom: 56px;
    }

    &:last-child{
       margin-bottom: 0;
    }

    .wrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 904px) {
            flex-direction: column;
            flex-wrap: nowrap;
        }

        figure{
            width: 31%;
            height: 340px;

            @media (max-width: 904px) {
                width: 58%;
                margin-bottom: 24px;
                height: 154px;
            }

            img{
                width: 100%;
                height: 100%;
                object-fit: contain;
            }
        }

        .text{
            width: 59%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;

            @media (max-width: 904px) {
                width: 100%;
                text-align: center;
                flex-direction: column;
                align-items: center;
            }

            .title{
                width: 100%;

                &--h2{
                    @media (max-width: 904px) {
                        font-size: 26px;
                        line-height: 36px;
                        letter-spacing: -0.23px;
                    }
                }
            }

            p{
                width: 100%;
                margin-top: 24px;
                font-size: 24px;
                line-height: 34px;
                font-weight: $fw-light;

                @media (max-width: 904px) {
                    margin-top: 18px;
                    font-size: 18px;
                    line-height: 26px;
                }
            }

            .arrow-link{
                margin-top: 40px;
                margin-right: 48px;

                @media (max-width: 904px) {
                    margin-top: 24px;
                    margin-right: 0;
                }

                &:last-of-type{
                    margin-right: 0;
                }
            }
        }

        &.arrow-link{
            .text{
                .icon{
                    margin-top: 24px;
                    margin-left: 0;

                    @media (max-width: 904px) {
                        width: 32px;
                        height: 32px;
                        background-image: url('./../images/icons/arrow/right/arrow-right-32-red.svg');
                    }
                }
            }
        }
    }

    &--large{
        .wrapper{
            padding: 96px 8.6%;

            @media (max-width: 904px) {
                padding: 40px 20px;
            }
        }
    }

    &--small{
        .wrapper{
            padding: 72px 8.6%;

            @media (max-width: 904px) {
                padding: 40px 20px;
            }

            figure{
                width: 24%;
                height: 230px;

                @media (max-width: 904px) {
                    width: 58%;
                    height: 154px;
                }
            }

            .text{
                width: 69%;

                @media (max-width: 904px) {
                    width: 100%;
                }

                .arrow-link{
                    margin-top: 24px;
                }
            }
        }

        &.extra-padding{
            .wrapper{
                @media (min-width: 905px) {
                    padding-top: 96px;
                    padding-bottom: 96px;
                }

                .text{
                    @media (min-width: 905px) {
                        width: 58.6%;
                    }
                }
            }
        }
    }

    &--right{
        .wrapper{
            flex-direction: row-reverse;

            @media (max-width: 904px) {
                flex-direction: column;
            }
        }
    }

    &--full{
        .wrapper{
            @media (min-width: 905px) {
                padding: 120px 6% 120px 0;
            }

            figure{
                @media (min-width: 905px) {
                    width: 33.6%;
                    height: 300px;
                }
            }

            .text{
                @media (min-width: 905px) {
                    width: 57%;
                }

                .arrow-link{
                    margin-top: 40px;

                    @media (max-width: 904px) {
                        margin-top: 16px;
                    }

                    &:first-of-type{
                        @media (max-width: 904px) {
                            margin-top: 24px;
                        }
                    }
                }
            }
        }
    }

    &--contrast{
        .wrapper{
            background-color: $contrast-60;
        }

        &.illustration-card--full{
            @media (min-width: 905px) {
                background-color: $contrast-60;
            }
        }
    }

    &--warm{
        .wrapper{
            background-color: $warm-60;
        }

        &.illustration-card--full{
            @media (min-width: 905px) {
                background-color: $warm-60;
            }
        }
    }

    &--yellow{
        .wrapper{
            background-color: $yellow-20;
        }

        &.illustration-card--full{
            @media (min-width: 905px) {
                background-color: $yellow-20;
            }
        }
    }

    &--white{
        .wrapper{
            background-color: $white;
        }

        &.illustration-card--full{
            @media (min-width: 905px) {
                background-color: $white;
            }
        }
    }
}
