.membership-table{
  margin-bottom: 136px;

  @media (max-width: 904px) {
    margin-bottom: 56px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  &__row{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-bottom: 19px;
    padding: 34px 5.35%;
    background-color: $yellow-20;

    @media (max-width: 904px) {
      margin-bottom: 16px;
      padding: 19px 15px;
    }

    &:last-child{
      margin-bottom: 0;
    }

    .membership-table__col{
      &--left{
        width: 76%;

        @media (max-width: 904px) {
          width: 70%;
        }
      }

      &--right{
        width: 19%;

        @media (max-width: 904px) {
          width: 24%;
        }
      }

      h3{
        font-size: 36px;
        line-height: 46px;
        font-weight: $fw-light;
        color: $black;

        @media (max-width: 904px) {
          font-size: 16px;
          line-height: 22px;
        }
      }

      h4{
        font-size: 24px;
        line-height: 34px;
        font-weight: $fw-semi-bold;
        color: $black;

        @media (max-width: 904px) {
          font-size: 14px;
          line-height: 20px;
          font-weight: $fw-bold;
        }
      }

      p{
        color: $black;
        font-size: 22px;
        line-height: 32px;
        font-weight: $fw-light;

        @media (max-width: 904px) {
          font-size: 14px;
          line-height: 20px;
        }
      }
    }

    &.membership-table__header{
      background-color: $yellow-50;
      margin-bottom: 12px;

      @media (max-width: 904px) {
        margin-bottom: 16px;
      }
    }
  }

  .htmlArea__container{
    margin-top: 81px;

    @media (max-width: 904px) {
      margin-top: 56px;
    }
  }
}
