// ---------------------------------------------------------------------- //
// Font: AcuminPro                                                        //
// ---------------------------------------------------------------------- //
// Thin         (100)
// ExtraLight   (200)
// Light        (300)
// Book         (400)
// Medium       (500)
// Semibold     (600)
// Bold         (700)
// Black        (800)
// UltraBlack   (900)
// ---------------------------------------------------------------------- //

html, body {
  font-family: 'acumin-pro';
	font-size: 16px;
  font-weight: 400;
  color: $main-text-color;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  hyphens: none;
  -moz-hyphens: none !important;
  -ms-hyphens: none !important;
  -webkit-hyphens: none !important;
  word-wrap: break-word;
}

a{
  text-decoration: none;
  color: $main-text-color;

  &:hover{
    text-decoration: none;
    color: $main-text-color;
  }
}

// Font weights
$fw-thin: 100;
$fw-extra-light: 200;
$fw-light: 300;
$fw-book: 400;
$fw-medium: 500;
$fw-semi-bold: 600;
$fw-bold: 700;
$fw-black: 800;
$fw-ultra-black: 900;

p{
  > a{
    color: $red-dark-3;
    text-decoration: underline;

    &:hover{
      color: $red-dark-3;
      text-decoration: underline;
    }
  }
}

.title{
  font-weight: $fw-light;

  &--h1{
    font-size: 72px;
    line-height: 82px;
    letter-spacing: -1px;

    @media (max-width: 1280px) {
      font-size: 46px;
      line-height: 56px;
    }

    @media (max-width: 904px) {
      font-size: 34px;
      line-height: 44px;
      letter-spacing: -0.5px;
    }
  }

  &--h2{
    font-size: 56px;
    line-height: 66px;
    letter-spacing: -0.5px;

    @media (max-width: 904px) {
      font-size: 30px;
      line-height: 40px;
    }
  }

  &--h3{
    font-size: 48px;
    line-height: 58px;

    @media (max-width: 904px) {
      font-size: 26px;
      line-height: 36px;
      letter-spacing: -0.23px;
    }
  }

  &--h4{
    font-size: 40px;
    line-height: 50px;

    @media (max-width: 904px) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  &--h5{
    font-size: 32px;
    line-height: 42px;

    @media (max-width: 904px) {
      font-size: 20px;
      line-height: 27px;
    }
  }

  &--h6{
    font-size: 28px;
    line-height: 38px;
  }
}
