.table-logo{
  // margin-top: 80px;
  margin-bottom: 32px;

  @media (max-width: 904px) {
    // margin-top: 56px;
    margin-bottom: 24px;
  }

  &:first-child{
    margin-top: 0;
  }

  &:last-child{
    margin-bottom: 0;
  }

  &__header{
    margin-bottom: 56px;

    @media (max-width: 904px) {
      margin-bottom: 24px;
    }

    h3{
      color: $red-dark-3;
      font-size: 30px;
      line-height: 40px;
      font-weight: $fw-medium;

      @media (max-width: 904px) {
        font-size: 20px;
        line-height: 30px;
        font-weight: 400;
      }
    }
  }

  &__table{
    width: 100%;
    font-size: 18px;
    line-height: 28px;
    border: 1px solid $solid-40;

    @media (max-width: 904px) {
      font-size: 14px;
      line-height: 18px;
    }

    thead{
      tr{
        th{
          padding: 16px 20px;
          border: 1px solid $solid-40;
          font-weight: $fw-semi-bold;

          @media (max-width: 904px) {
            padding: 10px 6px;
          }
        }
      }
    }

    tbody{
      tr{
        td{
          padding: 16px 20px;
          border: 1px solid $solid-40;
          font-weight: $fw-light;

          @media (max-width: 904px) {
            padding: 10px 6px;
            font-weight: $fw-book;
          }

          a{
            color: $red-dark-3;
            text-decoration: underline;
          }

          &.table-link{
            text-align: center;
          }
        }
      }
    }

    &.no-border{
      border: none;

      tr{
        th, td{
          border: none;
        }
      }
    }
  }
}
