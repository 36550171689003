.salary-calculator{
    margin-bottom: 136px;

    @media (max-width: 904px) {
        margin-bottom: 56px;
    }

    &:last-child{
        margin-bottom: 0;
    }

    .wrapper{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        background-color: $warm-40;
        padding: 95px 109px;

        @media (max-width: 1140px) {
            padding: 36px 24px;
        }

        .salary-calculator__header{
            width: 100%;
            margin-bottom: 64px;

            @media (max-width: 904px) {
                margin-bottom: 38px;
            }

            .title{
                margin-bottom: 11px;

                @media (max-width: 904px) {
                    font-size: 26px;
                    line-height: 30px;
                }
            }

            p{
                font-size: 18px;
                line-height: 26px;
                font-weight: $fw-light;

                @media (max-width: 904px) {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }

        .salary-calculator__input{
            width: calc(50% - 16px);
            border-bottom: 1px solid $main-text-color;

            &:last-child{
                margin-bottom: 0;
            }

            @media (max-width: 904px) {
                width: 100%;
                margin-bottom: 16px;
            }

            .form-table__row{
                padding: 0;
                border: none;

                &:first-child{
                    border-top: none;
                }

                .row{
                    margin: 0 !important; // "!important" needed to overwrite bootstrap styles
                    padding: 0;
                }
            }

            &--position{
                .form-table__row{
                    .row{
                        .cell{
                            &--select{
                                padding: 0 0 21px;

                                @media (max-width: 904px) {
                                    padding: 0 0 14px !important; // "!important" needed to overwrite bootstrap styles
                                }

                                .selected-item{
                                    position: relative;
                                    top: 0;
                                    height: auto;
                                    width: 100%;
                                    border: none;

                                    p{
                                        width: 100%;
                                        padding-right: 60px;
                                        font-size: 28px;
                                        line-height: 38px;

                                        @media (max-width: 904px) {
                                            padding-right: 30px;
                                            font-size: 16px;
                                            line-height: 22px;
                                        }
                                    }
                                }

                                .icon{
                                    top: 6px;
                                    right: 21px;

                                    @media (max-width: 904px) {
                                        top: 5px;
                                        right: 10px;
                                        width: 12px;
                                        height: 12px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &--salary{
                border-color: $solid-60;

                .form-table__row{
                    .row{
                        .cell{
                            &--label{
                                padding-top: 4px;
                                padding-bottom: 0;
                                padding-left: 0;
                                margin-bottom: 0;

                                @media (max-width: 904px) {
                                    padding-top: 0;
                                    padding-bottom: 16px;
                                    padding-left: 0 !important; // "!important" needed to overwrite bootstrap styles
                                }

                                .table-label{
                                    font-size: 20px;
                                    line-height: 28px;
                                    white-space: nowrap;
                                    cursor: default;

                                    @media (max-width: 904px) {
                                        font-size: 14px;
                                        line-height: 20px;
                                    }
                                }
                            }

                            &--input{
                                border: none;

                                input{
                                    top: -27px;
                                    height: calc(100% + 55px);
                                    width: calc(100% - 16px);
                                    border-bottom: 1px solid $main-text-color;
                                    font-size: 28px;
                                    line-height: 38px;

                                    @media (max-width: 904px) {
                                        top: -12px;
                                        height: 49px;
                                        width: calc(100% - 8px);
                                        font-size: 16px;
                                        line-height: 22px;
                                    }

                                    @media (max-width: 600px) {
                                        position: absolute;
                                    }

                                    // Placeholder color
                                    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                                        color: $solid-80;
                                        opacity: 1; /* Firefox */
                                      }

                                    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                                        color: $solid-80;
                                    }

                                    &::-ms-input-placeholder { /* Microsoft Edge */
                                        color: $solid-80;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        .salary-calculator__result{
            width: 100%;
            margin-top: 40px;

            @media (max-width: 904px) {
                margin-top: 14px;
            }

            .slider-wrapper{
                margin-top: 40px;

                @media (max-width: 904px) {
                    display: flex;
                    flex-direction: column;
                    margin-top: 16px;
                }

                &:first-child{
                    margin-top: 0px;
                }

                .title{
                    @media (max-width: 904px) {
                        margin-bottom: 4px;
                        font-size: 16px;
                        line-height: 22px;
                    }
                }

                .for-form{
                    position: relative;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-end;
                    flex-wrap: wrap;

                    @media (max-width: 904px) {
                        flex-direction: column;
                        align-items: flex-start;
                    }

                    .current-value{
                        font-size: 36px;
                        line-height: 66px;
                        font-weight: $fw-medium;
                        white-space: nowrap;

                        @media (max-width: 904px) {
                            margin-bottom: 4px;
                            font-size: 24px;
                            line-height: 24px;
                        }
                    }

                    .value-description{
                        max-width: 56%;
                        margin-left: 36px;
                        margin-right: 32px;
                        padding-bottom: 11px;
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: $fw-light;

                        @media (max-width: 904px) {
                            order: 4;
                            max-width: 100%;
                            margin-top: 16px;
                            margin-right: 0;
                            margin-left: 0;
                            padding-bottom: 0;
                            font-size: 14px;
                            line-height: 20px;
                        }
                    }

                    .max-value{
                        margin-left: auto;
                        padding-bottom: 11px;
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: $fw-light;
                        white-space: nowrap;

                        @media (max-width: 904px) {
                            display: none;
                        }
                    }

                    .input-group{
                        width: 100%;
                        margin-bottom: 0;

                        .rangeslider{
                            height: 10px;
                            border-radius: 0;
                            background: $white;
                            pointer-events: none;

                            @media (max-width: 904px) {
                                height: 8px;
                            }

                            &__fill{
                                border-radius: 0;
                                background: $red-80;
                            }

                            &__handle{
                                display: none;
                            }
                        }
                    }
                }
            }

            footer{
                margin-top: 32px;

                @media (max-width: 904px) {
                    margin-top: 24px;
                }

                .summary{
                    font-size: 28px;
                    line-height: 38px;
                    font-weight: $fw-light;

                    @media (max-width: 904px) {
                        font-size: 18px;
                        line-height: 26px;
                    }

                    strong{
                        font-weight: $fw-medium;
                    }
                }

                .disclaimer{
                    margin-top: 8px;
                    font-size: 18px;
                    line-height: 26px;
                    font-weight: $fw-light;

                    @media (max-width: 904px) {
                        margin-top: 4px;
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }
}
