.newsletter-signup{
  .form-table{
    .radio-wrapper{
      margin-top: 54px;
      margin-bottom: 60px;

      @media (max-width: 904px) {
        margin: 32px 0;
      }

      p{
        margin-top: 4px;
        padding-left: 30px;

        @media (max-width: 904px) {
          padding-left: 34px;
        }
      }

      .for-form{
        .input-group{
          .custom-radio{
            & + label{
              @media (min-width: 905px) {
                font-size: 22px;
              }
            }
          }
        }
      }
    }

    .button-wrapper{
      margin-top: 0px;

      .btn {
        @media (max-width: 904px) {
          padding: 11px 24px;
          font-size: 18px;
          line-height: 26px;
          font-weight: $fw-light;
          border-radius: 8px;
        }

        .icon {
          &--arrow-right-32-white {
            @media (max-width: 904px) {
              width: 24px;
              height: 24px;
              background-image: url("./../images/icons/arrow/right/arrow-right-24-white.svg");
            }
          }
        }
      }
    }
  }
}
