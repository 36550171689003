.sign-up{
  background-color: $warm-40;
  padding-bottom: 217px;

  @media (max-width: 1139px) {
    padding-bottom: 124px;
  }

  &__text{
    padding-top: 263px;

    @media (max-width: 1139px) {
      padding-top: 84px;
    }

    .title{
      @media (max-width: 1139px) {
        font-size: 34px;
        line-height: 44px;
      }
    }

    .contact-message{
      margin-top: 39px;
      padding-top: 21px;
      border-top: 1px solid $solid-40;
      font-size: 18px;
      line-height: 26px;
      font-weight: $fw-light;

      @media (max-width: 904px) {
        margin-top: 16px;
        padding-top: 16px;
        font-size: 16px;
        line-height: 22px;
      }
    }
  }

  &__form{
    padding-top: 266px;

    @media (max-width: 1139px) {
      padding-top: 48px;
    }

    .form-table{
      @media (min-width: 1680px) {
        max-width: 85%;
        margin-left: auto;
      }

      .for-form{
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 22px;

        @media (max-width: 904px) {
          margin-top: 32px;
        }

        .input-group{
          margin-bottom: 0;

          label{
            @media (max-width: 904px) {
              font-size: 16px;
              line-height: 22px;
            }

            &:before{
              @media (max-width: 904px) {
                top: 2px;
              }
            }

            &:after{
              @media (max-width: 904px) {
                top: 7px !important;
              }
            }
          }
        }

        p{
          padding-left: 32px;
          font-size: 20px;
          line-height: 28px;
          font-weight: $fw-light;

          @media (max-width: 904px) {
            padding-left: 16px;
            font-size: 16px;
            line-height: 22px;
          }
        }
      }

      .button-wrapper{
        margin-top: 40px;

        @media (max-width: 904px) {
          margin-top: 48px;
        }

        .btn{
          @media (max-width: 904px) {
            padding: 11px 24px;
            font-size: 18px;
            line-height: 26px;
            font-weight: $fw-light;
            border-radius: 8px;
          }

          .icon{
            &--arrow-right-32-white{
              @media (max-width: 904px) {
                width: 24px;
                height: 24px;
                background-image: url('./../images/icons/arrow/right/arrow-right-24-white.svg');
              }
            }
          }
        }
      }
    }
  }
}
