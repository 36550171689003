.article-header{
  padding: 108px 0 80px;

  @media (max-width: 904px) {
    padding: 56px 0 40px;
  }

  .breadcrumbs__wrapper{
    margin-bottom: 136px;

    @media (max-width: 904px) {
      margin-bottom: 56px;
    }
  }

  h2{
    margin-top: 48px;

    @media (max-width: 1280px) {
      margin-top: 32px;
      font-size: 26px;
      line-height: 36px;
    }

    @media (max-width: 904px) {
      margin-top: 24px;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0px;
    }
  }
}
