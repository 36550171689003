.page-404{
  min-height: 100vh;
  padding: 200px 0 147px !important;
  background-color: $warm-40;

  @media (max-width: 904px) {
    padding: 75px 0 136px !important;
  }

  &__content{

    .title{
      &.obs{
        margin-bottom: 5px;
        color: $red-dark-3;
      }
    }

    p{
      margin-top: 19px;
      font-size: 28px;
      line-height: 42px;
      font-weight: $fw-light;

      @media (max-width: 904px) {
        font-size: 20px;
        line-height: 30px;
      }
    }

    .button-wrapper{
      margin-top: 51px;

      @media (max-width: 904px) {
        margin-top: 0px;
      }

      .btn{
        margin-right: 39px;

        @media (max-width: 1139px) {
          margin-right: 32px;
          font-size: 19px;
          line-height: 19px;
          padding: 16px 24px;
        }

        @media (max-width: 904px) {
          margin-right: 16px;
          margin-top: 32px;
        }

        @media (max-width: 599px) {
          margin-right: 10px;
          padding-left: 10px;
          padding-right: 10px;
          font-size: 16px;
          line-height: 18px;
        }

        &:last-child{
          margin-right: 0;
        }

        .icon{
          @media (max-width: 1139px) {
            &--arrow-right-32-white{
              width: 24px;
              height: 24px;
              background-image: url('./../images/icons/arrow/right/arrow-right-24-white.svg');
            }

            &--arrow-up-right-36-white{
              width: 24px;
              height: 24px;
              background-image: url('./../images/icons/arrow/up-right/arrow-up-right-24-white.svg');
            }
          }
        }
      }
    }
  }

  &__image{
    margin-top: 46px;

    @media (max-width: 904px) {
      display: none;
    }

    figure{
      display: flex;
      align-items: flex-start;
      width: 100%;
      height: 577px;

      img{
        width: 100%;
        height: 100%;
        object-fit: contain;
        object-position: center;
      }
    }

    &--mobile{
      display: block;
      margin: 32px 0;

      @media (min-width: 905px) {
        display: none;
      }

      figure{
        height: 360px;
      }
    }
  }
}
