.campaign-page{
  &__hero{
    margin-bottom: 96px;

    @media (max-width: 904px) {
      margin-bottom: 56px;
    }

    .hero-text{
      padding-top: 108px;
      padding-bottom: 280px;
      background-color: $yellow-10;

      @media (max-width: 904px) {
        padding-top: 25px;
        padding-bottom: 228px;
      }

      .breadcrumbs__wrapper{
        margin-bottom: 138px;

        @media (max-width: 904px) {
          margin-bottom: 52px;
        }
      }

      p{
        margin-top: 48px;
        font-size: 28px;
        line-height: 42px;
        font-weight: $fw-light;

        @media (max-width: 1280px) {
          margin-top: 32px;
          font-size: 24px;
          line-height: 34px;
        }

        @media (max-width: 904px) {
          margin-top: 30px;
          font-size: 18px;
          line-height: 26px;
        }
      }
    }

    .hero-image{
      margin-top: -200px;

      @media (max-width: 904px) {
        margin-top: -168px;
      }

      .image-wrapper{
        .share-list{
          top: -32px;
          right: 44px;

          @media (max-width: 904px) {
            top: calc(100% - 24px);
            right: 21px;
          }
        }

        figure{
          @media (max-width: 904px) {
            padding-top: calc((200 / 292) * 100%);
          }
        }
      }
    }

    &--warm-40{
      .hero-text{
        background-color: $warm-40;
      }
    }

    &--blue-20{
      .hero-text{
        background-color: $blue-20;
      }
    }

    &--yellow-10{
      .hero-text{
        background-color: $yellow-10;
      }
    }

    &--contrast-20{
      .hero-text{
        background-color: $contrast-20;
      }
    }
  }
}
