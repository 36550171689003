.for-form {
    margin: 0;

    .input-group {
        margin-bottom: 32px;

        @media (max-width: 904px) {
            margin-bottom: 16px;
        }

        &--column{
            display: flex;
            flex-direction: column;
        }

        // Custom checkbox
        .custom-checkbox {
            position: absolute; // take it out of document flow
            opacity: 0; // hide it
            width: 1px;
            height: 1px;
            pointer-events: none;

            & + label {
                position: relative;
                margin-bottom: 16px;
                padding: 0 0 0 32px;
                cursor: pointer;
                color: inherit;
                font-size: 20px;
                line-height: 28px;
                font-weight: $fw-light;

                &:last-child {
                    margin-bottom: 0;
                }

                @media (max-width: 904px) {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            // Box.
            & + label:before {
                content: "";
                position: absolute;
                top: 7px;
                left: 0;
                vertical-align: text-top;
                width: 18px;
                height: 18px;
                border-radius: 3px;
                margin-right: 17px;
                border: 1px solid $solid-40;

                @media (max-width: 904px) {
                    top: 4px;
                }
            }

            // Box focus
            &:focus-visible + label:before {
                box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
            }

            // Disabled state label.
            &:disabled + label {
                color: #b8b8b8;
                cursor: auto;
            }

            // Disabled box.
            &:disabled + label:before {
                box-shadow: none;
                background: #ddd;
            }

            // Checkmark image
            &:checked + label:after {
                content: "";
                position: absolute;
                left: 0px;
                top: 7px;
                width: 18px;
                height: 18px;
                border-radius: 3px;
                background-color: $contrast-dark-1;
                background-repeat: no-repeat;
                background-size: contain;
                background-image: url("./../images/icons/check/check-24-white.svg");

                @media (max-width: 904px) {
                    top: 4px;
                }
            }
        }

        // Custom RADIO
        .custom-radio {
            position: absolute; // take it out of document flow
            opacity: 0; // hide it
            pointer-events: none;
            width: 1px;
            height: 1px;

            & + label {
                position: relative;
                margin-bottom: 16px;
                padding: 0 0 0 30px;
                cursor: pointer;
                color: $main-text-color;
                font-size: 20px;
                line-height: 28px;
                font-weight: $fw-light;

                &:last-child {
                    margin-bottom: 0;
                }

                @media (max-width: 904px) {
                    padding-left: 34px;
                }

                a{
                    color: $red-dark-3;
                    border-bottom: 1px solid;
                }
            }

            // Box.
            & + label:before {
                content: "";
                position: absolute;
                top: 5px;
                left: 0;
                vertical-align: text-top;
                width: 20px;
                height: 20px;
                border-radius: 100%;
                border: 2px solid $solid-60;
            }

            // Box focus
            &:focus-visible + label:before {
                box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
            }

            // Disabled state label.
            &:disabled + label {
                color: #b8b8b8;
                cursor: auto;
            }

            // Disabled box.
            &:disabled + label:before {
                box-shadow: none;
                background: #ddd;
            }

            // Checkmark image
            &:checked + label:before {
                content: "";
                border-color: $red-100;
            }

            &:checked + label:after {
                content: "";
                position: absolute;
                top: 10px;
                left: 5px;
                width: 10px;
                height: 10px;
                border-radius: 100%;
                background-color: $red-100;
                background-repeat: no-repeat;
            }
        }


        // Rounded Switch
        .switch {
            position: relative;
            display: inline-block;
            width: 54px;
            height: 28px;
            margin: 0;

            .label-description {
                display: none;
            }

            input {
                opacity: 0;
                width: 0;
                height: 0;

                &:checked {
                    + .slider {
                        background-color: $contrast-dark-1;

                        &::before {
                            -webkit-transform: translateX(26px);
                            -ms-transform: translateX(26px);
                            transform: translateX(26px);
                        }
                    }
                }

                &:focus-visible {
                    + .slider {
                        box-shadow: 0 0 1px 2px #000;
                    }
                }
            }

            .slider {
                position: absolute;
                cursor: pointer;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                background-color: #ccc;
                -webkit-transition: 0.4s;
                transition: 0.4s;

                &.round {
                    border-radius: 34px;

                    &::before {
                        border-radius: 50%;
                    }
                }

                &::before {
                    position: absolute;
                    content: "";
                    height: 19px;
                    width: 19px;
                    left: 4px;
                    bottom: 5px;
                    background-color: white;
                    -webkit-transition: 0.4s;
                    transition: 0.4s;
                }
            }
        }

        // Search input
        &--search{
            position: relative;

            input{
                width: 100%;
                padding: 18px 52px 15px;
                font-size: 24px;
                line-height: 24px;
                font-weight: $fw-light;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                border-radius: 12px;
                border: 1px solid $solid-100;

                @media (max-width: 904px) {
                    padding: 12px 45px 10px;
                    font-size: 16px;
                }

                &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                    color: $solid-60;
                    opacity: 1; /* Firefox */
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: $solid-60;
                }

                &::-ms-input-placeholder { /* Microsoft Edge */
                    color: $solid-60;
                }

                &:focus-visible{
                    outline: none;
                }
            }

            .icon--search-24-black{
                position: absolute;
                top: 23px;
                left: 16px;
                pointer-events: none;

                @media (max-width: 904px) {
                    width: 16px;
                    height: 16px;
                    top: 16px;
                    left: 16px;
                }
            }

            .btn-clear{
                position: absolute;
                top: 26px;
                right: 20px;
                width: 18px;
                height: 18px;
                border-radius: 100%;
                background-color: $solid-40;
                display: flex;
                align-items: center;
                justify-content: center;

                @media (max-width: 904px) {
                    top: 16px;
                    right: 16px;
                }
            }
        }

        &--slider{
            margin-bottom: 82px;

            .range-labels{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-bottom: 16px;

                span{
                    font-size: 14px;
                    font-weight: $fw-light;
                    color: $solid-60;

                    &::after{
                        content: "%";
                        display: inline;
                        font-size: 10px;
                        margin-left: 1px;
                    }
                }
            }

            .rangeslider{
                height: 6px;
                background: $solid-20;
                box-shadow: none;

                .rangeslider__fill{
                    background: $contrast-dark-1;
                    box-shadow: none;
                }

                .rangeslider__handle{
                    top: -13px;
                    width: 32px;
                    height: 32px;
                    background-image: none;
                    box-shadow: none;
                    border: 2px solid $contrast-dark-1;

                    &::after{
                        display: none;
                    }

                    .val{
                        position: absolute;
                        top: 47px;
                        width: 64px;
                        left: -12px;
                        font-size: 32px;
                        line-height: 24px;
                        text-align: center;
                        font-weight: $fw-light;
                        white-space: nowrap;

                        &::after{
                            content: "%";
                            display: inline;
                            font-size: 16px;
                            line-height: 24px;
                        }
                    }
                }
            }
        }
    }
}

textarea{
    padding: 19px 25px;
    height: 189px;
    background-color: $warm-40;
    border: 1px solid $warm-80;
    color: $solid-80;
    font-size: 18px;
    line-height: 34px;
    font-weight: $fw-light;
    border-radius: 0;
    -webkit-appearance: none;

    @media (max-width: 600px) {
        padding: 19px;
        font-size: 15px;
        line-height: 22px;
    }
}
