.arrow-card-wrapper{
    display: flex;
    flex-direction: row;

    @media (max-width: 904px) {
        flex-direction: column;
    }

    &--list{
        flex-direction: column;

        .arrow-card{
            width: 100%;
            margin-right: 0;

            @media (max-width: 904px) {
                margin-bottom: 0;
            }

            > a , .card-content {
                .image-wrapper{
                    display: none;
                }

                .text{
                    position: relative;
                    width: 100%;
                    padding: 32px 14%;
                    border-top: 1px solid $solid-100;

                    @media (max-width: 904px) {
                        padding: 16px 0;
                    }

                    .pubdate , time[pubdate]{
                        position: absolute;
                        left: 0;
                        margin-top: 10px;

                        @media (max-width: 904px) {
                            position: relative;
                            margin-top: 0;
                        }
                    }

                    p{
                        margin-top: 8px;

                        @media (max-width: 904px) {
                            margin-top: 6px;
                        }
                    }

                    .folder-label{
                        position: absolute;
                        left: 0;
                        margin-top: 35px;
                        color: $solid-80;
                        padding: 5px 9px 7px;
                        line-height: 1;
                        font-weight: 600;
                        border-radius: 6px;
                        font-size: 12px;
                        letter-spacing: 1px;

                        @media (max-width: 904px) {
                            margin-top: 0;
                            top: 14px;
                            left: 78px;
                        }

                        &--local-team{
                            background-color: $red-40;
                        }

                        &--association{
                            background-color: $contrast-60;
                        }
                    }
                }
            }

            > a{
                .icon{
                    position: absolute;
                    right: 15px;
                    margin: 0;
                    top: 30px; // fallback
                    margin-top: 0 !important;

                    &.icon--arrow-right-32-red{
                        top: calc(50% - 16px);
                    }

                    &.icon--arrow-right-48-red{
                        top: calc(50% - 24px);
                    }

                    &.icon--arrow-up-right-36-red{
                        top: calc(50% - 18px);
                    }

                    &.icon--arrow-up-right-48-red{
                        top: calc(50% - 24px);
                    }

                    @media (max-width: 904px) {
                        position: relative;
                        top: inherit;
                        right: inherit;
                        left: inherit;
                        margin-top: 8px !important;
                    }

                    &--arrow-right-32-red,
                    &--arrow-right-48-red {
                      @media (max-width: 904px) {
                        width: 32px;
                        height: 32px;
                        background-image: url("./../images/icons/arrow/right/arrow-right-32-red.svg");
                      }
                    }

                    &--arrow-up-right-36-red,
                    &--arrow-up-right-48-red {
                      @media (max-width: 904px) {
                        width: 36px;
                        height: 36px;
                        background-image: url("./../images/icons/arrow/up-right/arrow-up-right-36-red.svg");
                      }
                    }
                }
            }

            &:last-child{
                a{
                    .text{
                        border-bottom: 1px solid $solid-100;
                    }
                }
            }

            &__link-list{
                margin-top: 24px;

                .row{
                    .link-list-col{
                        @media (max-width: 904px) {
                            &:nth-child(2){
                                .link-list{
                                    ul{
                                        border-top: none;
                                    }
                                }
                            }
                        }

                        .link-list{
                            ul{
                                li{
                                    .arrow-link{
                                        padding: 18px 14px 18px 0;

                                        .text-wrapper{
                                            .title{
                                                font-size: 22px;
                                                line-height: 32px;

                                                @media (max-width: 904px) {
                                                    font-size: 20px;
                                                    line-height: 27px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

.arrow-card{
    margin-right: 32px;

    @media (max-width: 904px) {
        margin-bottom: 48px;
    }

    &:last-child{
        margin-right: 0;

        @media (max-width: 904px) {
            margin-bottom: 0;
        }
    }

    > a , .card-content{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        height: 100%;

        .image-wrapper{
            width: 100%;
        }

        .text{
            padding-top: 32px;

            @media (max-width: 904px) {
                padding-top: 24px;
            }

            .title--h6{
                @media (max-width: 904px) {
                    font-size: 20px;
                    line-height: 27px;
                }
            }

            p{
                margin-top: 16px;
                font-size: 20px;
                line-height: 28px;
                font-weight: $fw-light;

                @media (max-width: 904px) {
                    margin-top: 6px;
                    font-size: 16px;
                    line-height: 22px;
                }
            }
        }
    }

    > a {
        .text{
            .icon{
                display: block;
                margin-top: 16px;
                margin-left: 0;

                @media (max-width: 904px) {
                    margin-top: 8px;
                    width: 32px;
                    height: 32px;
                    background-image: url('./../images/icons/arrow/right/arrow-right-32-red.svg');
                }

                &--arrow-right-32-red,
                &--arrow-right-48-red {
                  @media (max-width: 904px) {
                    width: 32px;
                    height: 32px;
                    background-image: url("./../images/icons/arrow/right/arrow-right-32-red.svg");
                  }
                }

                &--arrow-up-right-36-red,
                &--arrow-up-right-48-red {
                  @media (max-width: 904px) {
                    width: 36px;
                    height: 36px;
                    background-image: url("./../images/icons/arrow/up-right/arrow-up-right-36-red.svg");
                  }
                }
            }
        }
    }

    &--4clm{
        width: 33.33%;
    }

    &--6clm{
        width: 50%;
    }

    &--full{
        width: 100%;
    }

    &--4clm, &--6clm, &--full{
        @media (max-width: 904px) {
            width: 100%;
            margin-right: 0;
        }

        a , .card-content{
            .image-wrapper{
                figure{
                    @media (max-width: 904px) {
                        padding-top: calc((166 / 291) * 100%);
                    }
                }
            }
        }
    }
}
