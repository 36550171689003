.my-membership{
    .for-accordion{
        &__accordion{
            .hidden-content{
                .form-table{
                    &__row{
                        &:first-child{
                            border-top: none;
                        }

                        .cell{
                            .btn-add-position{
                                font-size: 18px;
                                line-height: 28px;
                                font-weight: $fw-light;

                                @media (max-width: 904px) {
                                    margin-top: 12px;
                                    font-size: 14px;
                                    line-height: 22px;
                                }

                                .icon{
                                    @media (max-width: 904px) {
                                        width: 16px;
                                        height: 16px;
                                        background-image: url('./../images/icons/plus/plus-16-red.svg');
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &--edit-email{
        margin-bottom: 120px;

        @media (max-width: 904px) {
            margin-bottom: 64px;
        }
    }

    &--edit-workplace{
        margin-bottom: 120px;

        @media (max-width: 904px) {
            margin-bottom: 64px;
        }
    }

    &--edit-position{
        margin-bottom: 120px;

        @media (max-width: 904px) {
            margin-bottom: 64px;
        }
    }
}

.avatar-edit{
    &__content{
        display: flex;
        flex-direction: column;
        height: 100%;

        .table-title{
            @media (max-width: 904px) {
                margin-bottom: 16px;
            }
        }

        .wrapper{
            position: relative;
            display: flex;
            align-items: center;
            height: 100%;
            padding: 48px 0;
            border-top: 1px solid $solid-40;
            border-bottom: 1px solid $solid-40;

            @media (max-width: 904px) {
                padding: 32px 0 48px 0;
                border-bottom: none;
            }
        }
    }

    &__input{
        display: flex;
        flex-direction: row;
        align-items: center;

        input[type="file"]{
            position: absolute;
            width: 1px;
            height: 1px;
            font-size: 1px;
            opacity: 0;
            pointer-events: none;

            & + label{
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: 0;
                font-size: 16px;
                font-weight: $fw-light;
                cursor: pointer;

                &::before{
                    content: "";
                    display: block;
                    min-width: 124px;
                    width: 124px;
                    height: 124px;
                    margin-right: 54px;
                    border-radius: 100%;
                    background-color: $warm-40;
                    background-image: url('./../images/icons/user/user-58.svg');
                    background-position: center;
                    background-repeat: no-repeat;
                    color: transparent;

                    @media (max-width: 904px) {
                        margin-right: 24px;
                    }
                }

                img{
                    display: none;
                    position: absolute;
                    min-width: 124px;
                    width: 124px;
                    height: 124px;
                    border-radius: 100%;
                    object-fit: cover;
                }
            }

            &:focus-visible{
                & + label{
                    outline: 2px solid black;
                }
            }
        }

        &.hasImage{
            input[type="file"]{
                & + label{
                    &::after{
                        content: "";
                        display: block;
                        position: absolute;
                        left: -7px;
                        min-width: 138px;
                        width: 138px;
                        height: 138px;
                        border: 2px solid $contrast-dark-1;
                        border-radius: 100%;
                    }

                    img{
                        display: block;

                        & + span {
                            display: none;
                        }
                    }
                }
            }

            .avatar-edit__actions{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }
        }
    }

    &__color-picker{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        gap: 28px;
        max-width: 332px;

        @media (max-width: 904px) {
            max-width: 100%;
        }

        li{
            position: relative;
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            input{
                position: absolute;
                width: 1px;
                height: 1px;
                opacity: 0;
                pointer-events: none;

                & + label{
                    width: 32px;
                    height: 32px;
                    margin: 0;
                    border-radius: 100%;
                    color: $white;
                    font-size: 1px;
                    color: transparent;
                    cursor: pointer;
                }

                &:checked{
                    & + label{
                        &::after{
                            content: "";
                            position: absolute;
                            left: -5px;
                            top: -5px;
                            width: calc(100% + 10px);
                            height: calc(100% + 10px);
                            border: 2px solid $contrast-dark-1;
                            border-radius: 100%;
                        }
                    }
                }

                &:focus-visible{
                    & + label{
                        border: 2px solid black;
                    }
                }

                &#colorWarm{
                    & + label{
                        background-color: $warm-dark-3;
                    }
                }

                &#colorContrastDark{
                    & + label{
                        background-color: $contrast-dark-1;
                    }
                }

                &#colorContrast{
                    & + label{
                        background-color: $contrast-100;
                    }
                }

                &#colorAqua{
                    & + label{
                        background-color: $aqua-100;
                    }
                }

                &#colorRed{
                    & + label{
                        background-color: $red-80;
                    }
                }

                &#colorYellow80{
                    & + label{
                        background-color: $yellow-80;
                    }
                }

                &#colorBlue{
                    & + label{
                        background-color: $blue-50;
                    }
                }

                &#colorMocha{
                    & + label{
                        background-color: $mocha-100;
                    }
                }

                &#colorMossGreen100{
                    & + label{
                        background-color: $moss-green-100;
                    }
                }

                &#colorMossGreen60{
                    & + label{
                        background-color: $moss-green-60;
                    }
                }

                &#colorYellow100{
                    & + label{
                        background-color: $yellow-100;
                    }
                }
            }

            span{
                position: absolute;
                color: $white;
                font-weight: $fw-bold;
                pointer-events: none;
                font-size: 14px;
            }
        }
    }

    &__actions{
        display: none;

        button{
            margin-bottom: 12px;
            font-size: 15px;
            font-weight: $fw-light;
            text-decoration: underline;

            &:last-child{
                margin-bottom: 0;
            }
        }
    }

    .button-wrapper{
        padding-top: 100px;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;

        @media (max-width: 904px) {
            padding-top: 24px;
        }
    }

    .error-message{
        width: 100%;
        margin-top: 16px;
        text-align: right;
        color: $red-100;
        font-size: 18px;
        line-height: 26px;

        @media (max-width: 904px) {
            font-size: 14px;
            line-height: 20px;
        }
    }
}
