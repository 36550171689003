.documents-page{
    .page-header{
        &__header{
            h2{
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }
        }
    }

    .document-list{
        margin-bottom: 140px;

        @media (max-width: 904px) {
            margin-bottom: 100px;
        }
    }
}

.document-list{
    margin-bottom: 36px;

    @media (max-width: 992px) {
        margin-bottom: 24px;
    }

    &__header{
        display: flex;
        flex-direction: row;
        text-align: center;
        justify-content: space-between;
        padding-bottom: 38px;
        border-bottom: 1px solid $solid-40;

        @media (max-width: 904px) {
            align-items: center;
            padding-bottom: 27px;
        }

        h3{
            padding-right: 20px;
            font-size: 32px;
            line-height: normal;
            font-weight: $fw-light;

            @media (max-width: 904px) {
                font-size: 24px;
                line-height: normal;
            }

            .hide-mobile{
                @media (max-width: 904px) {
                    display: none;
                }
            }
        }

        .btn-filter{
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            padding: 7px 13px;
            background-color: $contrast-40;
            border-radius: 6px;
            font-size: 15px;
            line-height: 28px;

            @media (max-width: 904px) {
                align-items: center;
                padding: 5px 13px;
                font-size: 14px;
                line-height: 24px;
                border-radius: 100px;
            }

            .applied{
                margin-right: 10px;
            }

            .filter-txt{
                margin-right: 10px;

                @media (max-width: 904px) {
                    display: none;
                }
            }

            .icon{
                @media (max-width: 904px) {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }

    &__document{
        a{
            position: relative;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: -1px;
            padding: 32px 0;
            border-top: 1px solid transparent;
            border-bottom: 1px solid $solid-40;
            transition: 0.05s;

            @media (max-width: 599px) {
                flex-direction: column;
                padding: 16px 0;
            }

            .document-type{
                width: 9%;
                margin-top: 5px;
                margin-right: 34px;
                text-align: center;

                @media (max-width: 599px) {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    width: 82%;
                    margin-top: 0;
                    margin-right: 0;
                    text-align: left;
                }

                .label{
                    display: inline-block;
                    margin-bottom: 9px;
                    padding: 7px 9px;
                    border-radius: 6px;
                    background-color: $solid-20;
                    color: $solid-80;
                    font-size: 10px;
                    line-height: 10px;
                    font-weight: $fw-semi-bold;
                    text-transform: uppercase;
                    letter-spacing: 1px;

                    @media (max-width: 599px) {
                        margin-right: 8px;
                        margin-bottom: 0;
                        font-size: 8px;
                    }
                }

                .document-size{
                    color: $solid-80;
                    font-size: 12px;
                    line-height: 14px;
                    font-weight: $fw-light;

                    @media (max-width: 599px) {
                        font-size: 15px;
                        line-height: 15px;
                    }

                    span{
                        display: block;

                        @media (max-width: 599px) {
                            display: inline;
                            margin-left: 3px;
                        }
                    }
                }
            }

            .description-text{
                width: 74%;
                margin-right: 34px;

                @media (max-width: 599px) {
                    order: -1;
                    width: 82%;
                    margin-right: 0;
                }

                h4{
                    margin-bottom: 5px;
                    font-size: 24px;
                    line-height: 34px;
                    font-weight: $fw-light;

                    @media (max-width: 599px) {
                        margin-bottom: 8px;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }

                p{
                    font-size: 16px;
                    line-height: 24px;
                    color: $solid-60;

                    @media (max-width: 599px) {
                        display: none;
                    }
                }
            }

            > .icon{
                margin: auto;
                width: calc(17% - 68px);
                transition: 0.2s;
                background-position: center;
                align-self: center;
                position: relative;
                top: -5px;

                @media (max-width: 599px) {
                    width: 24px;
                    position: absolute;
                    top: calc(50% - 12px);
                    right: 8px;
                }
            }

            &:hover{
                @media (min-width: 600px) {
                    > .icon{
                        // Download arrow hover effect
                        // &--download{
                        //     @include download-icon-hover;
                        // }

                        // Arrow Right hover effect
                        &.arrow-right{
                            -ms-transform: translate(10px,0px); /* IE 9 */
                            transform: translate(10px,0px); /* Standard syntax */
                        }

                        // Arrow Up-Right hover effect
                        &.arrow-up-right{
                            -ms-transform: translate(5px,-5px); /* IE 9 */
                            transform: translate(5px,-5px); /* Standard syntax */
                        }
                    }
                }
            }
        }

        &--pdf{
            a{
                &:hover{
                    @media (min-width: 905px) {
                        border-color: $red-40;
                        background-color: transparentize($red-100, 0.9);
                    }
                }

                .document-type{
                    .label{
                        background-color: $red-40;
                    }
                }
            }
        }

        &--doc{
            a{
                &:hover{
                    @media (min-width: 905px) {
                        background-color: transparentize($blue-100, 0.9);
                        border-color: $blue-30;
                    }
                }

                .document-type{
                    .label{
                        background-color: $blue-30;
                    }
                }
            }
        }

        &--xls{
            a{
                &:hover{
                    @media (min-width: 905px) {
                        background-color: $contrast-20;
                        border-color: $contrast-60;
                    }
                }

                .document-type{
                    .label{
                        background-color: $contrast-60;
                    }
                }
            }
        }

        &--ppt{
            a{
                &:hover{
                    @media (min-width: 905px) {
                        border-color: #FFCBAC;
                        background-color: transparentize(#FFCBAC, 0.8);
                    }
                }

                .document-type{
                    .label{
                        background-color: #FFCBAC;
                    }
                }
            }
        }

        &--htm{
            a{
                &:hover{
                    @media (min-width: 905px) {
                        background-color: transparentize($solid-100, 0.95);
                    }
                }

                .document-type{
                    .label{
                        background-color: $solid-20;
                    }
                }
            }
        }
    }

    &--12clm{
        .document-list__document{
            .arrow-link{
                align-items: baseline;
                border-color: $solid-100;
                justify-content: space-between;
                align-items: flex-start;

                @media (max-width: 904px) {
                    padding: 18px 0;
                    flex-direction: column;
                }

                .document-type{
                    width: 6%;
                    margin-top: 8px;
                    margin-right: 0;

                    @media (max-width: 904px) {
                        width: 82%;
                        margin-top: 0;
                        margin-bottom: 8px;
                        text-align: left;
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                    }

                    .label{
                        @media (max-width: 904px) {
                            margin-right: 8px;
                            margin-bottom: 0;
                            padding: 6px 11px;
                            font-size: 10px;
                            line-height: 10px;
                        }
                    }

                    .document-size{
                        white-space: nowrap;
                        font-size: 12px;
                        line-height: 14px;

                        span{
                            display: inline;
                            margin-left: 3px;
                        }
                    }
                }

                .description-text{
                    width: 82%;
                    margin-right: 0;

                    @media (max-width: 904px) {
                        order: 0;
                    }

                    h4 , p{
                        max-width: 840px;
                    }

                    h4{
                        margin-bottom: 8px;
                        font-size: 24px;
                        line-height: 34px;
                        font-weight: $fw-light;

                        @media (max-width: 904px) {
                            margin-bottom: 0;
                            font-size: 20px;
                            line-height: 27px;
                        }
                    }

                    p{
                        margin-top: 8px;
                        font-size: 18px;
                        line-height: 26px;
                        color: $solid-80;

                        @media (max-width: 904px) {
                            margin-top: 4px;
                            font-size: 15px;
                            line-height: 22px;
                        }

                        @media (max-width: 904px) {
                            display: block;
                        }
                    }
                }

                .icon{
                    width: 7%;
                    margin: 0;

                    @media (max-width: 904px) {
                        position: absolute;
                        top: 18px;
                        right: 0;
                    }
                }

                &--animation-download{
                    .icon{
                        @media (max-width: 904px) {
                            width: 32px;
                            height: 32px;
                            background-image: url('./../images/icons/download/download-32-red.svg');

                            path{
                                display: none;
                            }
                        }
                    }
                }
            }
        }
    }
}
