.hidden-content {

  .contact {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    padding: 45px 6% 56px 0;
    width: 100%;

    @media (max-width: 1439px) {
      padding-right: 5.2%;
    }

    @media (max-width: 1000px) {
      padding-right: 0;
    }

    @media (max-width: 904px) {
      align-items: flex-start;
      padding: 10px 0 24px;
      flex-direction: column;
    }

    &--person {
      .contact__image-wrapper {
        min-width: 124px;
        width: 15.4%;

        @media (max-width: 904px) {
          width: 100%;
          min-width: 138px;
          margin-bottom: 24px;
        }

        &--image {
          width: 124px;

          @media (max-width: 904px) {
            width: 100%;
            max-width: 256px;
            // min-width: 138px;
            // width: 23%;
            // margin: 0 auto;
          }

          @media (max-width: 599px) {
            width: 48%;
            // margin: 0;
          }

          .img {
            border-radius: 50%;
            overflow: hidden;
            width: 100%;
          }
        }
      }
    }

    &--company {
      @media (max-width: 904px) {
        padding: 0 0 24px 0;
      }

      .contact__image-wrapper {
        width: 15.4%;
        height: auto;

        @media (max-width: 904px) {
          width: 100%;
        }

        &--image {
          width: 100%;
          height: 188px;

          @media (max-width: 904px) {
            width: 100%;
            max-width: 390px;
            // width: 39%;
            // min-width: 215px;
            padding: 22px 0 40px;
            // margin: 0 auto;
          }

          @media (max-width: 599px) {
            max-width: 74%;
            // margin: 0;
          }

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;

            @media (max-width: 904px) {
              object-position: center left;
            }
          }
        }
      }
    }

    &__info {
      width: 54%;
      font-size: 22px;
      line-height: 40px;
      padding-left: 48px;

      @media (max-width: 1139px) {
        font-size: 18px;
        line-height: 26px;
      }

      @media (max-width: 904px) {
        width: auto;
        margin-bottom: 24px;
        padding-left: 0;
        // text-align: center;
      }

      @media (max-width: 599px) {
        text-align: left;
      }

      &--item {
        display: flex;
        justify-content: flex-start;
        width: 100%;

        p, a {
          @media (max-width: 904px) {
            display: inline-block;
          }
        }

        @media (max-width: 904px) {
          flex-direction: column;
          margin-bottom: 8px;

          &:last-child {
            margin-bottom: 0px;
          }

          // &.first {
          //   .title {
          //     display: block;
          //   }
          // }
        }

        &.title {
          font-weight: $fw-medium;
          width: 35.5%;

          @media (max-width: 904px) {
            // display: none;
            margin-bottom: 4px;
            width: 100%;
          }
        }



        &.main {
          font-weight: $fw-light;
          width: 58%;

          a {
            color: $red-100;
            text-decoration: underline;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          @media (max-width: 904px) {
            width: 100%;
            margin-bottom: 0px;
          }
        }
      }
    }

    &__button {
      align-self: flex-end;
      margin-left: auto;

      @media (max-width: 1139px) {
        align-self: center;
      }

      @media (max-width: 904px) {
        margin-left: 0;
        align-self: flex-start;
      }

      @media (max-width: 904px) {
        // align-self: center;
      }

      @media (max-width: 599px) {
        align-self: flex-start;
      }

      .btn {
        white-space: nowrap;

        @media (max-width: 599px) {
          padding: 8px 16px;
        }
      }
    }
  }
}
