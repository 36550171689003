button{
    padding: 0;
    border: none;
    background: none;
}

.btn{
    -webkit-appearance: none !important;

    &--primary{
        padding: 11px 40px;
        border: 1px solid $solid-100;
        border-radius: 8px;

        font-size: 18px;
        line-height: 26px;
        font-weight: $fw-book;

        &:focus{
            outline: none;
            box-shadow: none;
        }

        &:focus-visible{
            box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.2);
            border-width: 2px;
            padding: 10px 39px;
        }

        &:active{
            outline: none;
            box-shadow: none;
            background-color: $contrast-100;
            border-color: $contrast-100;
        }

        &.disabled{
            pointer-events: none;
            background-color: transparent;
            border-color: $solid-60 !important;
            color: $solid-60;

            &:focus-visible{
                box-shadow: none;
            }
        }
    }

    &--secondary{
        background-color: $red-100;
        padding: 11px 40px;
        border-radius: 8px;
        font-size: 18px;
        line-height: 26px;
        font-weight: $fw-book;
        color: $white;

        &:focus{
            outline: none;
            box-shadow: none;
        }

        &:focus-visible{
            box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.2);
            border-width: 2px;
            padding: 10px 39px;
        }

        &:active{
            outline: none;
            box-shadow: none;
            background-color: $red-dark-2;
            border-color: $red-dark-2;
        }

        &:hover{
            color: $white;
        }

        &.disabled{
            pointer-events: none;
            background-color: $solid-20;
            border-color: $solid-20 !important;
            color: $solid-80;

            &:focus-visible{
                box-shadow: none;
            }

            .icon{
                filter: brightness(0.6);
            }
        }
    }

    &.btn--lg{
        padding: 19px 30px;
        border-radius: 12px;
        font-size: 24px;
        font-weight: $fw-light;
        line-height: 34px;

        &:focus-visible{
            padding: 18px 29px;
        }
    }

    &.btn--md{
        padding: 11px 40px;
        border-radius: 8px;
        font-size: 18px;
        line-height: 26px;
        font-weight: $fw-book;

        @media (max-width: 904px) {
            padding: 7px 40px;
            font-size: 15px;
            line-height: 26px;
        }

        &:focus-visible{
            padding: 10px 39px;
        }

        &.btn--icon-right{
            padding: 11px 24px;

            &:focus-visible{
                padding: 10px 19px;
            }
        }

        &.btn--green-border{
            &:focus-visible{
                padding: 11px 40px;
            }
        }
    }

    &.btn--sm{
        padding: 7px 30px;
        border-radius: 6px;
        font-size: 15px;
        line-height: 26px;
        font-weight: $fw-book;

        &:focus-visible{
            padding: 6px 29px;
        }

        &.btn--icon-right{
            padding: 7px 15px;

            &:focus-visible{
                padding: 6px 14px;
            }
        }
    }

    &.btn--icon-right{
        display: inline-flex;
        align-items: center;

        .icon{
            transition: transform 0.2s;
            margin-left: 10px;
        }

        &:hover{
            .icon{
                // if you need to add "right animation" on hover, please add class "btn--animation-right"
                // there is no need to add new classes below
                &--arrow-right-16-red,
                &--arrow-right-24,
                &--arrow-right-24-red,
                &--arrow-right-24-white,
                &--arrow-right-32-red,
                &--arrow-right-32-white,
                &--arrow-right-48-red,
                &--arrow-right-48-white,
                &--arrow-right-64-red,
                &--arrow-right-64-white{
                    @media (min-width: 905px) {
                        -ms-transform: translate(15px,0px); /* IE 9 */
                        transform: translate(15px,0px); /* Standard syntax */
                    }
                }

                // if you need to add "up right animation" on hover, please add class "btn--animation-right"
                // there is no need to add new classes below
                &--arrow-up-right-24-red,
                &--arrow-up-right-24-white,
                &--arrow-up-right-36,
                &--arrow-up-right-36-red,
                &--arrow-up-right-36-white,
                &--arrow-up-right-48-red,
                &--arrow-up-right-48-white,
                &--arrow-up-right-64,
                &--arrow-up-right-64-red,
                &--arrow-up-right-64-white {
                    @media (min-width: 905px) {
                        -ms-transform: translate(10px,-10px); /* IE 9 */
                        transform: translate(10px,-10px); /* Standard syntax */
                    }
                }
            }
        }
    }

    &.btn--icon-left{
        display: inline-flex;
        align-items: center;

        .icon{
            transition: transform 0.2s;
            margin-right: 10px;
        }
    }

    &.btn--order{
        padding: 4px 32px;
        font-size: 15px;
        white-space: nowrap;

        .icon{
            display: none;
        }

        &.active{
            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 5px 15px;
            background-color: $contrast-dark-2;
            color: $white;
            border: none;

            &:focus-visible{
                padding: 4px 15px;
            }

            .icon{
                margin-left: 10px;
                display: inline-block;
            }
        }

        &:focus-visible{
            box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.2);
            border-width: 2px;
            padding: 3px 32px;
        }
    }

    &.btn--animation-right{
        &:hover{
            .icon{
                @media (min-width: 905px) {
                    -ms-transform: translate(15px,0px); /* IE 9 */
                    transform: translate(15px,0px); /* Standard syntax */
                }
            }
        }
    }

    &.btn--animation-left{
        &:hover{
            .icon{
                @media (min-width: 905px) {
                    -ms-transform: translate(-15px,0px); /* IE 9 */
                    transform: translate(-15px,0px); /* Standard syntax */
                }
            }
        }
    }

    &.btn--animation-up-right{
        &:hover{
            .icon{
                @media (min-width: 905px) {
                    -ms-transform: translate(10px,-10px); /* IE 9 */
                    transform: translate(10px,-10px); /* Standard syntax */
                }
            }
        }
    }

    &.btn--green-border{
        border: 2px solid $contrast-dark-1;

        &:disabled{
            border-color: $solid-60 !important;
            color: $solid-60;
        }
    }

    &--wide{
        padding: 19px 60px;
        background-color: $yellow-80;
        border-radius: 12px;
        font-size: 24px;
        line-height: 34px;
        font-weight: $fw-light;
        color: $main-text-color;
        border: 2px solid transparent;
        transition: border-color 0.2s;

        @media (max-width: 904px) {
            padding: 14px 44px;
            border-radius: 8px;
            font-size: 18px;
            line-height: 26px;
            font-weight: $fw-book;
        }

        &:hover{
            @media (min-width: 905px) {
                border-color: $solid-60;
            }
        }

        &:active{
            background-color: $yellow-100;
        }

        &:focus{
            border: 2px solid $solid-60;
            box-shadow: none;
        }

        &:focus-visible{
            border: 2px solid $main-text-color;
            box-shadow: none;
        }
    }

    &--filter{
        font-size: 15px;
        line-height: 26px;
        white-space: nowrap;

        @media (max-width: 904px) {
            &.btn--sm{
                font-size: 12px;
                line-height: normal;
                font-weight: $fw-medium;

                .icon{
                    width: 16px;
                    height: 16px;
                    background-image: url('./../images/icons/filter/filter-16.svg');
                }
            }
        }

        &.hasFilter{
            background-color: $contrast-20;
            border-color: $contrast-dark-1;
        }
    }

    &--view{
        width: 42px;
        height: 42px;
        border: 1px solid $solid-40;
        border-radius: 0;
        box-shadow: none !important;
        padding: 20px;

        &:focus-visible{
            box-shadow: -3px 3px 8px rgba(0, 0, 0, 0.2);
            border-width: 2px;
            padding: 0;
            border-color: $solid-100;
        }

        &.active{
            border-color: $contrast-dark-1;
            background-color: $contrast-40;
        }
    }

    &--loading{
        position: relative;
        pointer-events: none;

        &::after{
            content: "";
            border-radius: 100%;
            width: 0px;
            height: 0px;
            margin-left: 16px;
            border: 2px solid rgba(255,255,255,0.5);
            border-left-color: #FFF;
            border-top-color: #FFF;
            animation: spin .6s infinite linear, grow .3s forwards ease-out;

            @media (max-width: 904px) {
                margin-left: 12px;
                animation: spin .6s infinite linear, grow-mobile .3s forwards ease-out;
            }
        }

        .icon{
            display: none;
        }
    }

    @keyframes spin {
        to {
            transform: rotate(359deg);
        }
    }
    @keyframes grow {
        to {
            width: 24px;
            height: 24px;
        }
    }
    @keyframes grow-mobile {
        to {
            width: 16px;
            height: 16px;
        }
    }

    &--green{
        color: $main-text-color;
        outline: none;
        box-shadow: none;
        background-color: $contrast-100;
        border-color: $contrast-100;
    }
}

// Link with arrow
.arrow-link{
    display: inline-flex;
    align-items: center;

    .icon{
        margin-left: 8px;
    }

    &--icon-left{
        .icon{
            margin-right: 8px;
            margin-left: 0;
        }
    }

    &--animation-right{
        .icon , .arrow{
            transition: 0.2s;
        }

        &:hover{
            .icon , .arrow{
                @media (min-width: 904px) {
                    -ms-transform: translate(15px,0px); /* IE 9 */
                    transform: translate(15px,0px); /* Standard syntax */
                }
            }
        }
    }

    &--animation-down{
        .icon , .arrow{
            transition: 0.2s;
        }

        &:hover{
            .icon , .arrow{
                @media (min-width: 904px) {
                    -ms-transform: translate(0px,15px); /* IE 9 */
                    transform: translate(0px,15px); /* Standard syntax */
                }
            }
        }
    }

    &--animation-left{
        .icon , .arrow{
            transition: 0.2s;
        }

        &:hover{
            .icon , .arrow{
                @media (min-width: 904px) {
                    -ms-transform: translate(-15px,0px); /* IE 9 */
                    transform: translate(-15px,0px); /* Standard syntax */
                }
            }
        }
    }

    &--animation-up-right{
        .icon , .arrow{
            transition: 0.2s;
        }

        &:hover{
            .icon , .arrow{
                @media (min-width: 904px) {
                    -ms-transform: translate(10px,-10px); /* IE 9 */
                    transform: translate(10px,-10px); /* Standard syntax */
                }
            }
        }
    }

    &--animation-download{
        &:hover{
            .icon{
                @include download-icon-hover;
            }
        }
    }

    &--a{
        font-size: 28px;
        line-height: 42px;

        &.arrow-link--animation-right{
            .icon{
                width: 32px;
                height: 32px;
                background-image: url('./../images/icons/arrow/right/arrow-right-32-red.svg');
            }
        }

        &.arrow-link--animation-up-right{
            .icon{
                width: 36px;
                height: 36px;
                background-image: url('./../images/icons/arrow/up-right/arrow-up-right-36-red.svg');
            }
        }
    }

    &--b{
        font-size: 24px;
        line-height: 34px;

        &.arrow-link--animation-right{
            .icon{
                width: 32px;
                height: 32px;
                background-image: url('./../images/icons/arrow/right/arrow-right-32-red.svg');
            }
        }

        &.arrow-link--animation-up-right{
            .icon{
                width: 36px;
                height: 36px;
                background-image: url('./../images/icons/arrow/up-right/arrow-up-right-36-red.svg');
            }
        }
    }

    &--c{
        font-size: 20px;
        line-height: 28px;

        &.arrow-link--animation-right{
            .icon{
                width: 24px;
                height: 24px;
                background-image: url('./../images/icons/arrow/right/arrow-right-24-red.svg');
            }
        }

        &.arrow-link--animation-up-right{
            .icon{
                width: 24px;
                height: 24px;
                background-image: url('./../images/icons/arrow/up-right/arrow-up-right-24-red.svg');
            }
        }
    }

    &--a, &--b, &--c{
        font-weight: $fw-light;

        @media (max-width: 904px) {
            font-size: 18px;
            line-height: 26px;
        }

        &.arrow-link--animation-right{
            .icon{
                @media (max-width: 904px) {
                    width: 24px;
                    height: 24px;
                    background-image: url('./../images/icons/arrow/right/arrow-right-24-red.svg');
                }
            }
        }

        &.arrow-link--animation-up-right{
            .icon{
                @media (max-width: 904px) {
                    width: 24px;
                    height: 24px;
                    background-image: url('./../images/icons/arrow/up-right/arrow-up-right-24-red.svg');
                }
            }
        }
    }


}

.button-wrapper{
    .btn{
        margin-right: 32px;

        @media (max-width: 904px) {
            margin-right: 22px;
        }

        &:last-child{
            margin-right: 0;
        }
    }
}
