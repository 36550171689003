.breadcrumbs__wrapper{
  .breadcrumbs{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: -6px;

    @media (max-width: 904px) {
      margin-bottom: 0;
    }

    li{
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 6px;

      @media (max-width: 904px) {
        margin-bottom: 0;
      }

      &::after{
        content: '';
        display: block;
        width: 16px;
        height: 16px;
        margin: 0 8px;
        background-image: url('./../images/icons/chevron/chevron-right-16-gray.svg');
        background-repeat: no-repeat;
        background-size: contain;

        @media (max-width: 904px) {
          width: 12px;
          height: 12px;
          margin: 0 4px;
          transform: translate(0px, 2px);
        }
      }

      &:last-child{
        &::after{
          display: none;
        }

        a{
          pointer-events: none;
        }
      }

      a{
        span{
          font-size: 18px;
          line-height: 22px;
          font-weight: $fw-book;
          color: $solid-80;
          // text-overflow: ellipsis;
          // display: -webkit-box;
          // -webkit-box-orient: vertical;
          // -webkit-line-clamp: 1;
          // overflow: hidden;

          @media (max-width: 904px) {
            font-size: 12px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
