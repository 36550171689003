.modal-body {
  p {
    font-size: 24px;
    font-weight: 300;
    line-height: 34px;
  }

  button {
    margin-top: 32px;
  }
}
