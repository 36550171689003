.items-with-arrows{
    margin-bottom: 136px;

    @media (max-width: 904px) {
        margin-bottom: 56px;
    }

    &:last-child{
        margin-bottom: 0;
    }

    .link-list{
        > ul{
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            border: none;

            @media (max-width: 904px) {
                flex-direction: column;
            }

            li{
                width: calc(33.33% - 22px);
                border-top: 1px solid;
                border-color: $solid-100;

                @media (max-width: 904px) {
                    width: 100%;
                    border-bottom: none;
                }

                &:last-child{
                    @media (max-width: 904px) {
                        border-bottom: 1px solid;
                    }
                }

                .arrow-link{
                    padding-right: 15px;
                    font-size: 28px;
                    font-weight: $fw-light;
                    line-height: 38px;

                    @media (max-width: 904px) {
                        padding-right: 0;
                        font-size: 20px;
                        line-height: 27px;
                    }

                    .icon{
                        @media (max-width: 904px) {
                            &--arrow-right-48-red{
                                width: 32px;
                                height: 32px;
                                background-image: url('./../images/icons/arrow/right/arrow-right-32-red.svg');
                            }
                        }

                        @media (max-width: 904px) {
                            &--arrow-up-right-48-red{
                                width: 36px;
                                height: 36px;
                                background-image: url('./../images/icons/arrow/up-right/arrow-up-right-36-red.svg');
                            }
                        }
                    }
                }
            }
        }
    }
}
