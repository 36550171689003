.mine-vervinger{
  .assign-friend{
    &__header{
      margin-bottom: 40px;

      h3{
        font-size: 36px;
        line-height: 46px;
        font-weight: $fw-light;

        @media (max-width: 904px) {
          font-size: 26px;
          line-height: 36px;
        }
      }

      p{
        margin-top: 24px;
        font-size: 18px;
        line-height: 28px;
        font-weight: $fw-light;

        @media (max-width: 904px) {
          margin-top: 16px;
          font-size: 16px;
          line-height: 24px;
        }
      }
    }

    .form-table{
      .button-wrapper{
        .btn{
          @media (max-width: 904px) {
            padding: 8px 16px;
            border-radius: 6px;
            font-size: 15px;
            line-height: 26px;
            font-weight: 400;
          }
        }
      }
    }
  }
}

.recruitment-list{
  margin-bottom: 96px;

  @media (max-width: 904px) {
    margin-bottom: 56px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  ul{
    li{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      border-top: 1px solid $solid-40;
      padding: 24px 16px;

      @media (max-width: 904px) {
        flex-wrap: wrap;
        padding: 12px 0;
      }

      p{
        font-weight: $fw-light;
        color: $solid-60;

        &.status{
          width: 21%;
          font-size: 20px;
          line-height: 28px;

          @media (max-width: 904px) {
            width: 100%;
            font-size: 13px;
            line-height: 24px;
          }
        }

        &.value{
          width: 16%;
          font-size: 20px;
          line-height: 28px;
          text-align: right;

          @media (max-width: 904px) {
            width: 30%;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }

      .name{
        display: flex;
        flex-direction: column;
        width: 57%;

        @media (max-width: 904px) {
          width: 70%;
          padding-right: 16px;
        }

        p{
          font-size: 24px;
          line-height: 34px;

          @media (max-width: 904px) {
            font-size: 16px;
            line-height: 24px;
          }

          &.membership-type{
            font-size: 18px;
            line-height: 28px;

            @media (max-width: 904px) {
              font-size: 13px;
              line-height: 24px;
            }
          }
        }
      }

      &.active{
        p{
          color: $main-text-color;
        }
      }

      &.total{
        justify-content: flex-end;
        padding-left: 25%;

        @media (max-width: 904px) {
          padding-left: 0;
        }

        a{
          margin-right: auto;
          color: $red-dark-3;
          font-weight: $fw-light;
          font-size: 20px;
          line-height: 28px;
          display: inline;
          border-bottom: 1px solid $red-dark-3;

          @media (max-width: 904px) {

            font-size: 16px;
            line-height: 24px;
          }
        }

        .total-value{
          width: 40%;
          color: $main-text-color;
          text-align: right;
          font-size: 20px;
          line-height: 28px;

          @media (max-width: 904px) {
            padding-left: 16px;
            font-size: 16px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
