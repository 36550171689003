.order-page {
  &__order {
    margin-bottom: 113px;

    @media (max-width: 904px) {
      margin-bottom: 96px;
    }

    &:last-child{
      margin-bottom: 0;
    }

    header {
      .title {
        margin-bottom: 25px;
        font-size: 36px;
        line-height: 46px;

        @media (max-width: 904px) {
          margin-bottom: 17px;
          font-size: 26px;
          line-height: 36px;
        }
      }
    }

    ul {
      border-top: 1px solid $solid-40;

      li {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 20px 15px 24px 0;
        border-bottom: 1px solid $solid-40;

        @media (max-width: 904px) {
          padding: 29px 0 27px 0;
          flex-direction: column;
        }

        .order-description {
          p {
            padding-right: 32px;
            font-size: 18px;
            line-height: 26px;
            font-weight: $fw-light;

            @media (max-width: 904px) {
              padding-right: 0;
              font-size: 15px;
              line-height: 22px;
            }
          }
        }

        .order-amount {
          display: flex;
          flex-direction: row;
          align-items: center;

          @media (max-width: 904px) {
            margin-top: 30px;
          }

          .icon--trash-24{
            margin-left: 38px;

            @media (max-width: 904px) {
              margin-left: auto;
            }
          }
        }
      }
    }
  }

  &__form{
    header {
      margin-bottom: 29px;

      .title {
        margin-bottom: 8px;
        font-size: 36px;
        line-height: 46px;

        @media (max-width: 904px) {
          font-size: 26px;
          line-height: 36px;
        }
      }

      p{
        font-size: 18px;
        line-height: 26px;
        font-weight: $fw-light;

        @media (max-width: 904px) {
          font-size: 15px;
          line-height: 22px;
        }

        span{
          margin-right: 3px;
          color: $red-100;
        }
      }
    }

    .form-table{
      .button-wrapper{
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        margin-top: 24px;
        padding-top: 0;

        @media (max-width: 904px) {
          flex-direction: column;
          align-items: flex-end;
        }

        p{
          margin-right: 60px;
          font-size: 18px;
          line-height: 26px;
          font-weight: $fw-light;
          text-align: left;

          @media (max-width: 904px) {
            margin-right: 0;
            margin-bottom: 24px;
            text-align: right;
            font-size: 15px;
            line-height: 22px;
          }
        }

        .btn{
          @media (max-width: 904px) {
            padding: 8px 16px;
            font-size: 15px;
            line-height: 26px;
            border-radius: 6px;
          }

          .icon{
            &--arrow-right-32-white{
              @media (max-width: 904px) {
                width: 24px;
                height: 24px;
                background-image: url('./../images/icons/arrow/right/arrow-right-24-white.svg');
              }
            }
          }

          &.finished{
            background-color: $contrast-dark-2;
            pointer-events: none;

            .icon{
              background-image: url('./../images/icons/check/check-32-white.svg');

              @media (max-width: 904px) {
                width: 24px;
                height: 24px;
                background-image: url('./../images/icons/check/check-24-white.svg');
              }
            }
          }
        }
      }
    }
  }
}

.input-amount-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;

  button {
    height: 37px;
    color: $red-100;
    font-size: 23px;
    line-height: 30px;
    font-weight: $fw-light;
  }

  input {
    width: 46px;
    height: 37px;
    margin: 0 9px;
    background-color: $white;
    border-radius: 5px !important;
    border: 1px solid $solid-40;
    text-align: center;
    font-size: 18px;
    font-weight: $fw-book;
    -webkit-appearance: none;
    -moz-appearance: textfield;
  }
}
