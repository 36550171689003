.streik-module{
    margin-bottom: 120px;
    
    @media (max-width: 904px) {
        margin-bottom: 54px;
    }

    a{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        
        @media (max-width: 904px) {
            flex-direction: column;
        }
        
        .streik-module__img-wrapper{
            width: 35.8%;
            
            @media (max-width: 904px) {
                width: 100%;
            }
            
            figure{
                height: 100%;
            }
        }
        
        .streik-module__text{
            position: relative;
            display: flex;
            align-items: center;
            width: 64.2%;
            padding: 80px 187px 80px 92px;
            background-color: $red-100;

            @media (max-width: 1280px) {
                padding: 40px 132px 40px 40px;
            }

            @media (max-width: 904px) {
                display: block;
                width: 100%;
                padding: 28px 34px 25px;
            }
            
            h2{
                font-size: 40px;
                line-height: 50px;
                font-weight: $fw-light;
                color: $warm-20;
                
                @media (max-width: 904px) {
                    margin-bottom: 12px;
                    font-size: 30px;
                    line-height: 40px;
                    letter-spacing: -0.5px;
                }
            }
    
            .icon{
                position: absolute;
                right: 80px;
                top: calc(50% - 32px);
                transition: 0.2s;

                @media (max-width: 1280px) {
                    right: 40px;
                }

                @media (max-width: 904px) {
                    position: relative;
                    right: inherit;
                    top: inherit;
                    width: 32px;
                    height: 32px;
                    background-image: url('./../images/icons/arrow/right/arrow-right-32-white.svg');
                }
            }
        }

        &.arrow-link--animation-up-right{
            .streik-module__text{
                .icon{
                    width: 64px;
                    height: 64px;
                    background-image: url('./../images/icons/arrow/up-right/arrow-up-right-64-white.svg');

                    @media (max-width: 904px) {
                        width: 36px;
                        height: 36px;
                        background-image: url('./../images/icons/arrow/up-right/arrow-up-right-36-white.svg');
                    }
                }
            }
        }
    }

    &--contrast{
        a{
            .streik-module__text{
                background-color: $contrast-40;
            }
        }
    }

    &--warm{
        a{
            .streik-module__text{
                background-color: $warm-40;
            }
        }
    }

    &--contrast , &--warm{
        a{
            .streik-module__text{
                h2{
                    color: $main-text-color;
                }

                .icon{
                    background-image: url('./../images/icons/arrow/right/arrow-right-64.svg');

                    @media (max-width: 904px) {
                        width: 32px;
                        height: 32px;
                        background-image: url('./../images/icons/arrow/right/arrow-right-32.svg');
                    }
                }
            }

            &.arrow-link--animation-up-right{
                .streik-module__text{
                    .icon{
                        width: 64px;
                        height: 64px;
                        background-image: url('./../images/icons/arrow/up-right/arrow-up-right-64.svg');

                        @media (max-width: 904px) {
                            width: 36px;
                            height: 36px;
                            background-image: url('./../images/icons/arrow/up-right/arrow-up-right-36.svg');
                        }
                    }
                }
            }
        }
    }
}