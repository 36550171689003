.for-accordion{
    margin-bottom: 96px;

    @media (max-width: 904px) {
        margin-bottom: 64px;
    }

    &:last-child{
        margin-bottom: 0;
    }

    &__accordion{
        .content{
            .btn-show{
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;
                padding: 40px 0 36px;
                border-bottom: 1px solid $solid-40;
                font-size: 32px;
                line-height: 40px;
                text-align: left;
                font-weight: $fw-light;

                @media (max-width: 904px) {
                    padding: 18px 0;
                    font-size: 20px;
                    line-height: 30px;
                }

                .accordion-title {
                    padding-right: 32px;
                    font-weight: $fw-light;

                    @media (max-width: 904px) {
                        padding-right: 16px;
                    }
                }

                .icon{
                    transition: 0.2s;

                    @media (max-width: 904px) {
                        width: 32px;
                        height: 32px;
                        background-image: url('./../images/icons/plus/plus-32-red.svg');
                    }
                }

                &--avatar{
                    padding-left: 90px;

                    @media (max-width: 904px) {
                        padding: 28px 0 28px 82px;;
                    }

                    .user-card__avatar{
                        position: absolute;
                        left: 16px;
                        margin: 0;

                        @media (max-width: 904px) {
                            left: 8px;
                        }

                        &:hover{
                            color: $white;
                            opacity: 0.8;
                        }

                        &::after{
                            content: "";
                            display: block;
                            position: absolute;
                            width: 28px;
                            height: 28px;
                            background-color: $white;
                            bottom: -6px;
                            right: -6px;
                            border-radius: 100%;
                            box-shadow: 0px 2px 2px 0px #00000040;
                            background-image: url('./../images/icons/pencil/pencil-16-gray.svg');
                            background-repeat: no-repeat;
                            background-position: center;

                            @media (max-width: 904px) {
                                width: 22px;
                                height: 22px;
                                bottom: -3px;
                                right: -3px;
                                background-size: 70%;
                            }
                        }
                    }
                }
            }

            &--disabled {
                flex-direction: row;
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                font-size: 32px;
                line-height: 40px;
                padding: 40px 0 36px;
                border-bottom: 1px solid $solid-40;
                font-weight: $fw-light;
                color: $solid-60;

                a {
                    font-size: 16px;
                    color: $solid-60;
                }

                @media (max-width: 904px) {
                    padding: 18px 0;
                    font-size: 20px;
                    line-height: 30px;

                    a {
                        font-size: 14px;
                    }
                }
            }
        }

        .hidden-content{
            display: none;
        }

        &.active{
            margin-bottom: 12px;

            .content{
                .btn-show{
                    .icon{
                        transform: rotate(-45deg);
                    }
                }
            }
        }

        &--calendar{
            border-bottom: 1px solid $solid-100;

            &:first-of-type{
                .content{
                    .btn-show{
                        border-top: 1px solid $solid-100;
                    }
                }
            }

            .content{
                .btn-show{
                    border-bottom: none;
                    padding: 43px 19px 43px 0;
                    align-items: flex-start;

                    @media (max-width: 904px) {
                        padding: 22px 0;
                    }

                    .icon {
                        @media (max-width: 904px) {
                            min-height: 30px;
                            background-position: center;
                        }
                    }

                    .accordion-title {
                        @media (min-width: 905px) {
                            display: flex;
                            align-items: center;
                            min-height: 48px;
                        }
                    }
                }
            }

            &.active{
                .hidden-content{
                    padding-bottom: 64px;

                    @media (max-width: 904px) {
                        padding-bottom: 40px;
                    }
                }
            }
        }

        &--contact {
            border-bottom: 1px solid $solid-100;

            &:first-of-type{
                .content{
                    .btn-show{
                        border-top: 1px solid $solid-100;
                    }
                }
            }

            .content{
                .btn-show{
                    border-bottom: none;
                    align-items: flex-start;

                    @media (min-width: 905px) {
                        padding: 40px 19px 40px 0;
                    }

                    @media (max-width: 904px) {
                        padding-right: 0;
                    }

                    .accordion-title {
                        @media (min-width: 905px) {
                            min-height: 48px;
                            display: flex;
                            align-items: center;
                        }
                    }

                    .icon {
                        @media (max-width: 904px) {
                            background-position: center;
                            min-height: 30px;

                        }
                    }
                }
            }

            &.active{
                .hidden-content{
                    padding-bottom: 0px;

                    @media (max-width: 904px) {
                        padding-bottom: 0px;
                    }
                }
            }
        }
    }

    &--short{
        margin: 32px 0;

        &:first-child{
            margin-top: 0;
        }

        &:last-child{
            margin-bottom: 0;
        }

        .for-accordion__accordion{
            border-bottom: 1px solid $solid-100;

            &:first-of-type{
                .content{
                    .btn-show{
                        border-top: 1px solid $solid-100;
                    }
                }
            }

            .content{
                .btn-show{
                    align-items: flex-start;
                    padding: 40px 0;
                    border-color: $solid-100;
                    border: none;

                    @media (max-width: 904px) {
                        padding: 18px 0;
                    }

                    .title{
                        display: flex;
                        align-items: center;
                        min-height: 48px;
                        text-align: left;
                        padding-right: 30px;

                        @media (max-width: 904px) {
                            min-height: 32px;
                            padding-right: 10px;
                        }
                    }

                    .icon{
                        @media (max-width: 904px) {
                            width: 32px;
                            height: 32px;
                            background-image: url(./../images/icons/plus/plus-32-red.svg);
                        }
                    }
                }
            }

            .hidden-content{
                padding: 64px 77px;
                background-color: $warm-20;

                @media (max-width: 1140px) {
                    padding: 38px 25px;
                }

                >*{
                    &:last-child{
                        margin-bottom: 0;
                    }
                }

                p{
                    margin-bottom: 32px;
                    font-size: 22px;
                    line-height: 32px;
                    font-weight: $fw-light;

                    @media (max-width: 904px) {
                        margin-bottom: 22px;
                        font-size: 15px;
                        line-height: 22px;
                    }

                    a{
                        color: $red-dark-3;
                        text-decoration: underline;
                    }

                    & + ul, & + ol{
                        margin-top: -32px;

                        @media (max-width: 904px) {
                            margin-top: -22px;
                        }
                    }
                }

                table{
                    tr{
                        td{
                            a{
                                color: $red-dark-3;
                                text-decoration: underline;
                            }
                        }
                    }
                }

                ul, ol{
                    margin-top: 0;
                    margin-bottom: 32px;

                    @media (max-width: 904px) {
                        margin-bottom: 22px;
                    }

                    li{
                        // padding-left: 0;

                        p{
                            display: inline-block;
                            margin-bottom: 0;
                        }

                        > a{
                            text-decoration: underline;
                        }
                    }

                    & + p{
                        margin-top: -32px;

                        @media (max-width: 904px) {
                            margin-top: -22px;
                        }
                    }
                }

                ul{
                    list-style: none;

                    // li{
                    //     display: flex;
                    //     flex-direction: row;
                    //     align-items: flex-start;

                    //     &::before{
                    //         content: "■";
                    //         color: $solid-80;
                    //         font-weight: bold;
                    //         display: inline-block;
                    //         margin-right: 24px;
                    //         margin-left: 0;
                    //         font-size: 16px;
                    //         line-height: 40px;

                    //         @media (max-width: 904px) {
                    //             margin-right: 23px;
                    //             font-size: 10px;
                    //             line-height: 22px;
                    //         }
                    //     }
                    // }
                }

                ol{
                    list-style: none;
                    counter-reset: items;

                    // li{
                    //     display: flex;
                    //     flex-direction: row;
                    //     align-items: baseline;
                    //     counter-increment: items;
                    //     font-size: 22px;
                    //     line-height: 40px;
                    //     font-weight: $fw-light;

                    //     @media (max-width: 904px) {
                    //         font-size: 15px;
                    //         line-height: 22px;
                    //     }

                    //     &::before{
                    //         content: counter(items) ".";
                    //         margin-right: 12px;
                    //         vertical-align: top;
                    //         line-height: 40px;
                    //         min-width: 24px;

                    //         @media (max-width: 904px) {
                    //             margin-right: 5px;
                    //             line-height: 22px;
                    //         }
                    //     }
                    // }
                }

                &--program{
                    .program-title{
                        margin-bottom: 40px;
                        font-size: 24px;
                        line-height: 40px;
                        font-weight: $fw-medium;

                        @media (max-width: 904px) {
                            font-size: 18px;
                            line-height: 28px;
                            margin-bottom: 24px;
                        }
                    }

                    table{
                        width: 100%;
                        margin-bottom: 32px;

                        @media (max-width: 904px) {
                            margin-bottom: 22px;
                        }

                        &:last-child{
                            margin-bottom: 0;
                        }

                        tbody{
                            tr{
                                &:last-of-type{
                                    td{
                                        padding-bottom: 0;
                                    }
                                }

                                td{
                                    font-size: 22px;
                                    line-height: 32px;
                                    font-weight: $fw-light;
                                    vertical-align: baseline;

                                    @media (max-width: 904px) {
                                        padding-bottom: 22px;
                                        font-size: 15px;
                                        line-height: 22px;
                                    }

                                    &:first-child{
                                        width: 21%;
                                        min-width: 145px;
                                        padding-right: 16px;

                                        @media (max-width: 904px) {
                                            width: 10%;
                                            min-width: 110px;
                                        }

                                        @media (max-width: 600px) {
                                            width: 22%;
                                        }
                                    }

                                    > * {
                                        &:last-child{
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &--links{
                    .link-list{
                        ul{
                            margin: 0;

                            li{
                                padding-left: 0;

                                &::before{
                                    display: none;
                                }

                                a{
                                    align-items: flex-start;
                                    padding-right: 11px;
                                    text-decoration: none;

                                    @media (max-width: 904px) {
                                        padding-right: 0;
                                    }

                                    .text-wrapper{
                                        padding-right: 28px;
                                        min-height: 48px;
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: center;

                                        @media (max-width: 904px) {
                                            min-height: 36px;
                                            padding-right: 10px;
                                        }

                                        h4{
                                            font-size: 24px;
                                            line-height: 34px;
                                            font-weight: $fw-light;

                                            @media (max-width: 904px) {
                                                font-size: 20px;
                                                line-height: 27px;
                                            }
                                        }

                                        p{
                                            color: $solid-80;
                                            font-size: 18px;
                                            line-height: 26px;
                                            font-weight: $fw-light;

                                            &:first-of-type{
                                                margin-top: 7px;
                                            }

                                            @media (max-width: 904px) {
                                                margin-top: 8px;
                                                font-size: 15px;
                                                line-height: 22px;
                                            }
                                        }
                                    }

                                    .icon{
                                        margin-left: 0;

                                        @media (max-width: 904px) {
                                            &--arrow-right-48-red{
                                                width: 32px;
                                                height: 32px;
                                                background-image: url('./../images/icons/arrow/right/arrow-right-32-red.svg');
                                            }

                                            &--arrow-up-right-48-red{
                                                width: 36px;
                                                height: 36px;
                                                background-image: url('./../images/icons/arrow/up-right/arrow-up-right-36-red.svg');
                                            }

                                            &--download{
                                                width: 32px;
                                                height: 32px;
                                                background-image: url('./../images/icons/download/download-32-red.svg');

                                                path{
                                                    display: none;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                &--recruitment{
                    .recruitment-list{
                        ul{
                            margin-top: 0;
                            margin-bottom: 0;

                            li{
                                padding-left: 0;

                                &::before{
                                    display: none;
                                }

                                &:only-child{
                                    border-bottom: 1px solid $solid-40;
                                }
                            }
                        }
                    }
                }

                &--testimonials , &--text{
                    .testimonials{
                        &__item{
                            &--image{
                                align-self: baseline;
                            }

                            &--text{
                                @extend .testimonials__item--text;

                                p{
                                    font-size: 22px;
                                    line-height: 34px;
                                    font-weight: $fw-light;

                                    @media (max-width: 1139px) {
                                        font-size: 15px;
                                        line-height: 22px;
                                    }

                                    @media (max-width: 599px) {
                                      font-size: 14px;
                                      line-height: 20px;
                                    }

                                    &:first-child {
                                        font-weight: bolder !important;
                                    }

                                    &:last-child{
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    &--long{
        margin-bottom: 136px;

        @media (max-width: 904px) {
            margin-bottom: 64px;
        }

        .for-accordion__accordion{
            margin-bottom: 40px;
            background-color: $warm-60;

            @media (max-width: 904px) {
                margin-bottom: 28px;
            }

            &:last-child{
                margin-bottom: 0;
            }

            .content{
                .btn-show{
                    align-items: flex-start;
                    padding: 64px 6% 64px 8.5%;
                    border-bottom: none;

                    @media (max-width: 904px) {
                        padding: 19px;
                    }

                    .text{
                        max-width: 87%;
                        padding-right: 10px;
                        text-align: left;

                        .title{
                            display: flex;
                            align-items: center;
                            min-height: 64px;
                            font-size: 40px;
                            line-height: 50px;

                            @media (max-width: 904px) {
                                min-height: 32px;
                                font-size: 20px;
                                line-height: 27px;
                            }
                        }

                        p{
                            margin-top: 24px;
                            font-size: 20px;
                            line-height: 28px;
                            font-weight: $fw-light;

                            @media (max-width: 904px) {
                                margin-top: 9px;
                                font-size: 15px;
                                line-height: 22px;
                            }
                        }
                    }

                    .icon{
                        @media (max-width: 904px) {
                            width: 32px;
                            height: 32px;
                            background-image: url('./../images/icons/plus/plus-32-red.svg');
                        }
                    }
                }
            }

            .hidden-content{
                padding: 80px 8.5%;
                background-color: $warm-20;

                @media (max-width: 904px) {
                    padding: 19px;
                }

                .htmlArea{
                    margin-bottom: 40px;

                    @media (max-width: 904px) {
                        margin-bottom: 22px;
                    }

                    &:last-child{
                        margin-bottom: 0;
                    }
                }

                p{
                    margin-bottom: 40px;
                    font-size: 22px;
                    line-height: 40px;
                    font-weight: $fw-light;

                    &:last-child{
                        margin-bottom: 0;
                    }

                    @media (max-width: 904px) {
                        margin-bottom: 22px;
                        font-size: 15px;
                        line-height: 22px;
                    }
                }

                .document-list{
                    border-top: 1px solid $solid-100;

                    &__document{
                        .arrow-link{
                            align-items: baseline;
                            border-color: $solid-100;
                            justify-content: space-between;
                            align-items: flex-start;

                            @media (max-width: 904px) {
                                padding: 18px 0;
                                flex-direction: column;
                            }

                            &:hover{
                                background-color: $warm-60;

                                @media (max-width: 904px) {
                                    background-color: transparent;
                                }
                            }

                            .document-type{
                                width: 7%;
                                margin-top: 8px;
                                margin-right: 0;

                                @media (max-width: 904px) {
                                    width: 82%;
                                    margin-top: 0;
                                    margin-bottom: 8px;
                                    text-align: left;
                                    display: flex;
                                    flex-direction: row;
                                    align-items: center;
                                }

                                .label{
                                    @media (max-width: 904px) {
                                        margin-right: 8px;
                                        margin-bottom: 0;
                                        padding: 6px 11px;
                                        font-size: 10px;
                                        line-height: 10px;
                                    }
                                }

                                .document-size{
                                    white-space: nowrap;
                                    font-size: 12px;
                                    line-height: 14px;

                                    span{
                                        display: inline;
                                        margin-left: 3px;
                                    }
                                }
                            }

                            .description-text{
                                width: 79.5%;
                                margin-right: 0;

                                @media (max-width: 904px) {
                                    width: 82%;
                                    order: 0;
                                }

                                h6{
                                    font-size: 24px;
                                    line-height: 34px;
                                    font-weight: $fw-light;

                                    @media (max-width: 904px) {
                                        font-size: 20px;
                                        line-height: 27px;
                                    }
                                }

                                p{
                                    margin-top: 8px;
                                    font-size: 18px;
                                    line-height: 26px;
                                    color: $solid-80;

                                    @media (max-width: 904px) {
                                        margin-top: 4px;
                                        font-size: 15px;
                                        line-height: 22px;
                                    }

                                    @media (max-width: 904px) {
                                        display: block;
                                    }
                                }
                            }

                            .icon{
                                width: 7%;
                                margin: 0;

                                @media (max-width: 904px) {
                                    position: absolute;
                                    top: 18px;
                                    right: 0;
                                }
                            }

                            &--animation-download{
                                .icon{
                                    @media (max-width: 904px) {
                                        width: 32px;
                                        height: 32px;
                                        background-image: url('./../images/icons/download/download-32-red.svg');

                                        path{
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }

                        &--xls {
                            .label {
                                background-color: $contrast-40;
                            }
                        }
                    }

                }
            }
        }
    }

    &--order{
        margin-bottom: 113px;

        @media (max-width: 904px) {
            margin-bottom: 80px;
        }

        &:last-child{
            margin-bottom: 0;
        }

        .for-accordion__accordion{
            border-bottom: 1px solid $solid-100;

            &:first-of-type{
                .content{
                    .btn-show{
                        border-top: 1px solid $solid-100;
                    }
                }
            }

            .content{
                .btn-show{
                    border-bottom: none;
                    align-items: flex-start;

                    @media (min-width: 905px) {
                        padding: 40px 16px 40px 0px;
                    }

                    @media (max-width: 904px) {
                        padding-right: 0;
                    }

                    .icon {
                        @media (max-width: 904px) {
                            width: 32px;
                            height: 32px;
                            background-image: url('./../images/icons/plus/plus-32-red.svg');
                        }
                    }

                    .accordion-title {
                        @media (min-width: 905px) {
                            display: flex;
                            align-items: center;
                            min-height: 48px;
                        }
                    }
                }
            }

            .hidden-content{
                background-color: $warm-20;
                padding: 40px 9% 58px 9%;

                @media (max-width: 904px) {
                    background-color: $white;
                    padding: 34px 0px;
                }

                .product-list{
                    li{
                        display: flex;
                        flex-direction: row;
                        min-height: 132px;
                        margin-bottom: 24px;
                        padding-bottom: 24px;
                        border-bottom: 1px solid $solid-40;

                        @media (max-width: 904px) {
                            flex-wrap: wrap;
                            margin-bottom: 34px;
                            padding-bottom: 36px;
                        }

                        &:last-child{
                            margin-bottom: 0;

                            @media (max-width: 904px) {
                                margin-bottom: 0;
                                padding-bottom: 0;
                                border-bottom: none;
                            }
                        }

                        &:last-child{
                            &:first-child{
                                padding-bottom: 0;
                                border: none;
                            }
                        }

                        .product-list__image{
                            display: flex;
                            align-items: flex-start;
                            justify-content: center;
                            width: 13.5%;
                            min-width: 74px;

                            @media (max-width: 904px) {
                                width: 26%;
                                max-width: 140px;
                            }

                            img{
                                width: 100%;
                            }
                        }

                        .product-list__description{
                            // width: 70.5%;
                            flex: 1;
                            padding-left: 36px;
                            padding-right: 36px;

                            @media (max-width: 904px) {
                                width: 74%;
                                padding: 0 0 0 19px;
                            }

                            h6{
                                font-size: 22px;
                                line-height: 32px;
                                font-weight: $fw-light;

                                @media (max-width: 904px) {
                                    font-size: 20px;
                                    line-height: 27px;
                                }
                            }

                            p{
                                font-size: 18px;
                                line-height: 26px;
                                font-weight: $fw-light;

                                @media (max-width: 904px) {
                                    margin-top: 4px;
                                    font-size: 15px;
                                    line-height: 22px;
                                }
                            }

                            a{
                                margin-top: 4px;
                                color: $red-dark-3;
                                font-size: 14px;
                                line-height: 22px;
                                font-weight: $fw-book;
                                text-decoration: underline;

                                @media (max-width: 904px) {
                                    display: block;
                                    margin-top: 16px;
                                }
                            }
                        }

                        .product-list__amount{
                            display: flex;
                            flex-direction: column;
                            align-items: center;
                            // width: 16%;

                            @media (max-width: 904px) {
                                width: 100%;
                                flex-direction: row;
                                align-items: flex-start;
                                margin-top: 36px;
                            }

                            @media (max-width: 599px) {
                                justify-content: space-between;
                            }

                            .input-amount-wrapper{
                                margin-bottom: 16px;

                                @media (max-width: 904px) {
                                    margin-bottom: 0;
                                    margin-right: 24px;
                                }

                                @media (max-width: 599px) {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }

            &.active{
                .hidden-content{
                    @media (min-width: 905px) {
                        border-top: 1px solid $solid-100;
                    }
                }
            }
        }
    }

    &__alpha-nav{
        @media (min-width: 905px) {
            width: 0;
            flex: 0;
            padding: 0;
        }

        .aplha-nav-wrapper{

            .inner-wrapper-sticky{
                .sidebar__inner{
                    .alpha-nav{
                        transform: translate(111px, 0px);

                        @media (max-width: 1439px) {
                            transform: translate(56px, 0px);
                        }

                        @media (max-width: 904px) {
                            transform: translate(0px, 0px);
                        }

                        @media (max-width: 599px) {
                            transform: translate(20px, 0px);
                        }
                    }
                }
            }
        }
    }
}

.macro-files{
    margin-bottom: 32px;
}
