.registration-page{
  &__header{
    margin-bottom: 96px;
    padding: 104px 0 56px;
    background-color: $warm-40;

    @media (max-width: 904px) {
      margin-bottom: 32px;
      padding: 24px 0;
    }

    .breadcrumbs__wrapper{
      margin-bottom: 136px;

      @media (max-width: 904px) {
        margin-bottom: 56px;
      }
    }
  }

  .work-situation{
    margin-top: 153px;

    @media (max-width: 904px) {
      margin-top: 48px;
    }

    form{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      margin-top: 42px;

      @media (max-width: 904px) {
        flex-direction: column;
        margin-top: 24px;
      }

      label{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(33.3% - 27px);
        height: 149px;
        margin-left: 40px;
        margin-bottom: 37px;
        padding: 16px;
        background-color: $warm-40;
        border-radius: 12px;
        cursor: pointer;
        color: $red-dark-3;
        font-size: 22px;
        line-height: 26px;
        font-weight: $fw-semi-bold;
        text-align: center;

        @media (max-width: 904px) {
          width: 100%;
          height: 96px;
          margin-bottom: 14px;
          margin-left: 0;
        }

        &:first-child{
          margin-left: 0;
        }

        &:last-of-type{
          @media (max-width: 904px) {
            margin-bottom: 29px;
          }
        }

        input[type="radio"]{
          position: absolute;
          width: 1px;
          height: 1px;
          left: 0;
          top: 0;
          opacity: 0;
          cursor: pointer;

          &:checked{
            ~.checkmark{
              opacity: 1;
            }
          }

          &:hover {
            ~.label-shadow{
              transform: translate(7px,6px);
            }
          }
        }

        .checkmark{
          opacity: 0;
          position: absolute;
          top: 0;
          right: 0;
          display: flex;
          align-items: center;
          justify-content: center;
          width: 40px;
          height: 40px;
          background-color: $red-100;
          border-bottom-left-radius: 12px;
          border-top-right-radius: 12px;
          transition: opacity 0.1s;

          &::after{
            content: '';
            display: block;
            width: 24px;
            height: 24px;
            background-image: url('./../images/icons/check/check-24-white.svg');
          }
        }

        .label-shadow{
          position: absolute;
          width: 100%;
          height: 100%;
          display: block;
          background-color: $warm-80;
          transform: translate(0px, 0px);
          border-radius: 12px;
          z-index: -1;
          transition: transform 0.1s;

          @media (max-width: 904px) {
            display: none;
          }
        }
      }

      .button-wrapper{
        width: 100%;
        border-top: 1px solid $solid-40;
        padding-top: 29px;
        text-align: right;

        @media (max-width: 904px) {
          padding-top: 26px;
        }

        .btn{
          @media (max-width: 904px) {
            padding: 11px 24px;
            border-radius: 8px;
            font-size: 18px;
            line-height: 26px;
            font-weight: $fw-light;

            .icon{
              &--arrow-right-32-white{
                @media (max-width: 904px) {
                  width: 24px;
                  height: 24px;
                  background-image: url('./../images/icons/arrow/right/arrow-right-24-white.svg');
                }
              }
            }
          }
        }
      }
    }

    .help-text{
      margin-top: 68px;

      @media (max-width: 904px) {
        margin-top: 32px;
      }
    }
  }

  &__steps{
    margin-bottom: 90px;

    @media (max-width: 904px) {
      margin-bottom: 56px;
    }

    .wrapper{
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;

      @media (max-width: 904px) {
        flex-wrap: nowrap;
        justify-content: space-between;
      }

      .step{
        position: relative;

        @media (max-width: 904px) {
          display: flex;
          justify-content: flex-start;
          width: 100%;
        }

        &:last-child{
          @media (max-width: 904px) {
            width: 32px;
          }

          .step__number{
            @media (max-width: 904px) {
              &::after{
                display: none;
              }
            }
          }

          p{
            padding-right: 0;
          }
        }

        &__number{
          display: flex;
          align-items: center;
          justify-content: center;
          width: 32px;
          height: 32px;
          background-color: $solid-20;
          border-radius: 100%;


          &::after{
            content: "";
            display: block;
            position: absolute;
            height: 3px;
            width: calc(100% - 16px);
            background-color: $solid-20;
            left: 16px;
            z-index: -1;
          }

          span{
            color: $white;
            font-size: 18px;
            line-height: 14px;
            font-weight: $fw-bold;

            @supports (-moz-appearance:none) {
              transform: translate(0px,-2px);
            }
          }
        }

        p{
          margin-top: 13px;
          padding-right: 32px;
          color: $solid-80;
          font-size: 15px;
          line-height: 26px;
          font-weight: $fw-book;

          @media (max-width: 904px) {
            display: none;
          }
        }

        &.active{
          .step__number{
            background-color: $red-100;

            &::after{
              background-color: $red-100;
            }
          }

          p{
            color: $main-text-color;
          }
        }
      }
    }

    &--working{
      .wrapper{
        .step{
          @media (min-width: 905px) {
            &:nth-child(1){
              width: 27%;
            }

            &:nth-child(2){
              width: 22%;
            }

            &:nth-child(3){
              width: 19%;
            }

            &:nth-child(4){
              width: 19%;
            }

            &:nth-child(5){
              width: 13%;

              p{
                white-space: nowrap;
              }
            }
          }
        }
      }
    }

    &--not-working{
      .wrapper{
        .step{
          @media (min-width: 905px) {
            &:nth-child(1){
              width: 34%;
            }

            &:nth-child(2){
              width: 26%;
            }

            &:nth-child(3){
              width: 28%;
            }

            &:nth-child(4){
              width: 12%;

              p{
                white-space: nowrap;
              }
            }
          }
        }
      }
    }

    &--student{
      .wrapper{
        .step{
          @media (min-width: 905px) {
            &:nth-child(1){
              width: 31%;
            }

            &:nth-child(2){
              width: 23%;
            }

            &:nth-child(3){
              width: 24%;
            }

            &:nth-child(4){
              width: 22%;

              p{
                white-space: nowrap;
              }
            }
          }
        }
      }
    }
  }

  &__form{
    .form-table{
      margin-bottom: 96px;

      @media (max-width: 904px) {
        margin-bottom: 64px;
      }

      &:last-child{
        margin-bottom: 0;
      }

      & + .button-wrapper{
        margin-top: -48px;
        margin-bottom: 96px;

        @media (max-width: 904px) {
          margin-top: -34px;
          margin-bottom: 64px;
        }
      }
    }

    .button-wrapper{
      display: flex;
      justify-content: flex-end;
      align-items: flex-start;
      margin-top: 48px;

      @media (max-width: 599px) {
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 30px;
      }

      .btn{
        @media (max-width: 904px) {
          padding: 8px 16px;
          border-radius: 6px;
          font-size: 15px;
          line-height: 26px;
          font-weight: $fw-book;
        }

        .icon{
          &--arrow-right-32-white{
            @media (max-width: 904px) {
              width: 24px;
              height: 24px;
              background-image: url('./../images/icons/arrow/right/arrow-right-24-white.svg');
            }
          }

          &--arrow-left-32-white{
            @media (max-width: 904px) {
              width: 24px;
              height: 24px;
              background-image: url('./../images/icons/arrow/left/arrow-left-24-white.svg');
            }
          }
        }
      }

      .extra-form-action{
        display: inline-flex;
        flex-direction: row;
        align-items: center;
        margin-right: auto;
        padding-right: 32px;
        text-align: left;
        font-size: 18px;
        line-height: 26px;
        font-weight: $fw-light;

        @media (max-width: 904px) {
          font-size: 15px;
          line-height: 26px;
        }

        @media (max-width: 599px) {
          width: 100%;
          margin-bottom: 24px;
          padding-right: 0;
        }

        .icon{
          margin-right: 16px;
          transform: translate(0px, 2px);

          @media (max-width: 904px) {
            &--plus-24-red{
              margin-right: 8px;
              width: 16px;
              height: 16px;
              background-image: url('./../images/icons/plus/plus-16-red.svg');
            }

            &--minus-24-red{
              margin-right: 8px;
              width: 16px;
              height: 16px;
              background-image: url('./../images/icons/minus/minus-16-red.svg');
            }
          }
        }
      }
    }

    .summary{
      margin-bottom: 24px;

      &__header{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
        padding: 24px 0 28px;
        border-top: 1px solid $solid-40;
        border-bottom: 1px solid $solid-40;

        @media (max-width: 904px) {
          padding: 16px 0;
        }

        .title{
          padding-right: 32px;

          @media (max-width: 904px) {
            padding-right: 16px;
            font-size: 20px;
            line-height: 27px;
          }
        }

        a{
          font-size: 18px;
          line-height: 24px;
          font-weight: $fw-light;
          color: $red-dark-3;
          border-bottom: 1px solid $red-dark-3;

          @media (max-width: 904px) {
            font-size: 15px;
            line-height: 20px;
            font-weight: $fw-book;
          }
        }
      }

      ul{
        margin-top: 24px;

        @media (max-width: 904px) {
          margin-top: 16px;
        }

        li{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          align-items: flex-start;
          justify-content: space-between;
          margin-bottom: 8px;

          @media (max-width: 904px) {
            margin-bottom: 12px;
          }

          @media (max-width: 599px) {
            flex-direction: column;
            margin-bottom: 16px;
          }

          &:last-child{
            margin-bottom: 0;
          }

          p{
            font-weight: $fw-light;

            &.label{
              width: 22%;
              font-size: 20px;
              line-height: 28px;

              @media (max-width: 904px) {
                width: 100%;
                font-size: 14px;
                line-height: 20px;
              }
            }

            &.value{
              width: calc(78% - 32px);
              font-size: 24px;
              line-height: 34px;

              @media (max-width: 904px) {
                font-size: 18px;
                line-height: 26px;
              }

              @media (max-width: 599px) {
                width: 100%;
                margin-top: 4px;
              }
            }
          }
        }
      }

      &#summary_comments {
        margin-bottom: 0;

        .summary__header {
          border-bottom: none;
        }
      }
    }

    .terms-input{
      margin-bottom: 62px;
      padding-top: 37px;
      border-top: 1px solid $solid-40;

      @media (max-width: 904px) {
        padding-top: 16px;
      }

      .for-form{
        .input-group{
          label{
            @media (max-width: 904px) {
              padding-top: 3px;
              font-size: 15px;
              line-height: 22px;
            }
          }
        }
      }
    }

    .radio-wrapper{
      margin-top: 107px !important;
      margin-bottom: 32px !important;

      @media (max-width: 904px) {
        margin-top: 56px !important;
        margin-bottom: 16px !important;
      }

      p{
        a {
          @media (max-width: 904px) {
              display: inline-block;
              margin-top: 20px;
              font-size: 15px;
          }
        }
      }
    }
  }

  &__thank{
    .htmlArea{
      p{
        font-size: 28px;
        line-height: 42px;
        font-weight: $fw-light;

        @media (max-width: 904px) {
          font-size: 18px;
          line-height: 28px;
        }
      }
    }

    .button-wrapper{
      display: flex;
      justify-content: flex-start;
      margin-top: 60px;

      @media (max-width: 904px) {
        justify-content: flex-end;
        margin-top: 46px;
      }

      .btn{
        @media (max-width: 904px) {
          padding: 11px 24px;
          border-radius: 8px;
          font-size: 18px;
          line-height: 26px;
          font-weight: $fw-light;

          .icon{
            &--arrow-right-32-white{
              @media (max-width: 904px) {
                width: 24px;
                height: 24px;
                background-image: url('./../images/icons/arrow/right/arrow-right-24-white.svg');
              }
            }
          }
        }
      }
    }
  }

  .help-text{
    padding: 30px 42px;
    background-color: $contrast-20;

    @media (max-width: 904px) {
      padding: 24px;
    }

    p{
      font-size: 20px;
      line-height: 28px;
      font-weight: $fw-light;

      @media (max-width: 904px) {
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
