.for-accordion--long + .accordion-card-link__wrapper {
  margin-top: -96px;

  @media (max-width: 904px) {
    margin-top: -36px;
  }
}

.accordion-card-link__wrapper + .accordion-card-link__wrapper {
  margin-top: -96px;

  @media (max-width: 904px) {
    margin-top: -36px;
  }
}

.accordion-card-link__wrapper + .for-accordion--long {
  margin-top: -96px;

  @media (max-width: 904px) {
      margin-top: -36px;
  }
}

.accordion-card-link__wrapper {
  margin-bottom: 136px;

  @media (max-width: 904px) {
    margin-bottom: 64px;
  }

  &:last-child {
    margin-bottom: 0px;
  }

  .accordion-card-link{
    margin-bottom: 40px;

    @media (max-width: 904px) {
      margin-bottom: 28px;
    }

    &:last-child{
      margin-bottom: 0;
    }

    &__content {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $warm-60;
      padding: 64px 6% 64px 8.5%;

      @media (max-width: 904px) {
        padding: 19px;
      }

      .title--h4 {
        font-weight: $fw-light;
        max-width: 87%;

        @media (max-width: 904px) {
          font-size: 20px;
          line-height: 27px;
        }
      }

      .icon {
        &--arrow-right-64-red {
          @media (max-width: 904px) {
            width: 32px;
            height: 32px;
            background-image: url('./../images/icons/arrow/right/arrow-right-32-red.svg');
          }
        }

        &--arrow-up-right-64-red {
          @media (max-width: 904px) {
            width: 36px;
            height: 36px;
            background-image: url('./../images/icons/arrow/up-right/arrow-up-right-36-red.svg');
          }
        }
      }
    }
  }
}
