h4 {
    &.table-title {
        margin-bottom: 40px;
        color: $main-text-color;
        font-size: 36px;
        line-height: 40px;
        font-weight: $fw-light;

        @media (max-width: 904px) {
            margin-bottom: 24px;
            font-size: 20px;
            line-height: 30px;
        }
    }
}

.form-table {
    width: 100%;

    .form-title {
        margin-bottom: 40px;

        @media (max-width: 904px) {
            margin-bottom: 24px;
        }

        h3 {
            font-size: 36px;
            line-height: 46px;

            @media (max-width: 904px) {
                font-size: 26px;
                line-height: 36px;
            }
        }

        h4 {
            font-size: 28px;
            line-height: 38px;

            @media (max-width: 904px) {
                font-size: 20px;
                line-height: 27px;
            }
        }

        p {
            margin-top: 8px;
            font-size: 18px;
            line-height: 26px;
            font-weight: $fw-light;

            @media (max-width: 904px) {
                margin-top: 4px;
                font-size: 14px;
                line-height: 20px;
            }

            .required-star {
                color: $red-100;
                margin-right: 4px;
            }
        }

        & + {
            .form-table__row {
                border-top: 1px solid $solid-40;
            }
        }
    }

    &__row {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: stretch;
        padding: 0 16px;
        border-bottom: 1px solid $solid-40;

        @media (max-width: 599px) {
            flex-direction: column;
            padding: 0;
            border-color: $solid-40;
        }

        &:first-child {
            border-top: 1px solid $solid-40;

            @media (max-width: 599px) {
                border-color: $solid-40;
            }
        }

        &:hover {
            .cell--action {
                .action-btn {
                    &--edit {
                        .icon {
                            opacity: 1;
                            background-image: url("./../images/icons/pencil/pencil-24-red.svg");
                        }
                    }
                }
            }
        }

        &--disabled {
            cursor: default;
            pointer-events: none;

            p {
                color: $solid-40;

                &.table-label {
                    &.required {
                        &::after {
                            color: $solid-40 !important;
                        }
                    }
                }
            }

            .row {
                .cell {
                    &--select {
                        .selected-item {
                            border-color: $solid-40 !important;
                        }

                        .icon {
                            filter: grayscale(100%);
                            opacity: 0.5;
                        }
                    }
                }
            }
        }

        &--icon {
            .cell {
                @media (max-width: 599px) {
                    width: 85%;
                }

                &--input {
                    input[type="text"],
                    input[type="email"],
                    input[type="number"],
                    input[type="password"] {
                        padding-right: 64px;
                        padding-left: 0;

                        @media (max-width: 599px) {
                            padding-right: 0;
                        }
                    }
                }

                &--action {
                    display: none;
                    position: absolute;
                    top: 0;
                    right: 23px;
                    height: 83px;
                    align-items: center;
                    // align-self: center;
                    // align-items: flex-end;
                    // margin-left: auto;

                    @media (max-width: 599px) {
                        position: absolute;
                        width: auto;
                        right: 7px;
                        // top: calc(50% - 12px);
                        top: 0;
                        height: 100%;
                    }

                    .action-btn {
                        display: flex;
                        align-items: center;

                        &--edit {
                            @media (max-width: 599px) {
                                margin-top: 4px;
                            }

                            .icon {
                                opacity: 0.5;

                                @media (max-width: 599px) {
                                    width: 24px;
                                    height: 24px;
                                }
                            }
                        }
                    }

                    &.active {
                        display: flex;
                    }
                }
            }
        }

        &--switch {
            .cell {
                @media (max-width: 599px) {
                    width: 75%;
                }

                &--action {
                    display: flex;
                    align-self: center;
                    align-items: flex-end;
                    margin-left: auto;

                    @media (max-width: 599px) {
                        position: absolute;
                        width: auto;
                        right: 0;
                        top: 20px;
                    }

                    .action-btn {
                        display: flex;
                        align-items: center;

                        &--switch {
                            .for-form {
                                .input-group {
                                    margin-top: auto;
                                    margin-bottom: auto;
                                }
                            }
                        }
                    }
                }
            }
        }

        &--endre {
            .cell {
                &--text {
                    .text {
                        padding-right: 100px;
                    }
                }

                &--action {
                    @media (min-width: 600px) {
                        position: absolute;
                        right: 0;
                        width: 60px;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: flex-end;
                    }

                    @media (max-width: 599px) {
                        margin-top: 12px;
                        padding-bottom: 16px;
                    }

                    .action-btn {
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: $fw-light;
                        color: $red-dark-3;
                        border-bottom: 1px solid $red-dark-3;

                        @media (max-width: 904px) {
                            font-size: 15px;
                            line-height: 16px;
                            font-weight: $fw-book;
                        }
                    }
                }
            }
        }

        .row {
            width: 100%;
            padding-top: 16px;
            padding-bottom: 16px;

            @media (max-width: 599px) {
                margin-left: 0 !important;
                margin-right: 0 !important;
                padding-top: 4px;
                padding-bottom: 4px;
            }

            .cell {
                display: flex;
                padding-top: 8px;
                padding-bottom: 8px;
                flex-direction: column;

                // @media (max-width: 904px) {
                //     padding-top: 12px;
                //     padding-bottom: 12px;
                // }

                @media (max-width: 599px) {
                    padding-top: 0;
                    padding-left: 0 !important;
                }

                p {
                    margin-bottom: 24px;
                    font-size: 24px;
                    line-height: 34px;
                    font-weight: $fw-light;

                    &:last-child {
                        margin-bottom: 0;
                    }

                    @media (max-width: 904px) {
                        font-size: 20px;
                        line-height: 30px;
                        font-weight: $fw-light;
                    }

                    @media (max-width: 599px) {
                        margin-bottom: 0;
                    }

                    &.table-label {
                        font-size: 16px;
                        line-height: 24px;

                        @media (max-width: 904px) {
                            font-size: 13px;
                            line-height: 24px;
                        }

                        &.required {
                            display: inline-table;

                            &::after {
                                content: "*";
                                display: inline;
                                margin-left: 4px;
                                color: $red-dark-1;
                            }
                        }

                        &--md {
                            font-size: 20px;
                            line-height: 28px;

                            @media (max-width: 904px) {
                                font-size: 13px;
                                line-height: 24px;
                            }
                        }
                    }

                    &.txt-password {
                        margin-top: 8px;
                        margin-bottom: 0;
                        letter-spacing: 8px;
                        line-height: 24px;

                        @media (max-width: 599px) {
                            margin-top: 0;
                        }
                    }

                    &.small-alert {
                        color: $solid-60;
                        font-size: 16px;
                        line-height: 24px;
                    }

                    a {
                        display: inline-block;
                        color: $red-dark-3;
                        font-weight: $fw-light;
                        text-decoration: underline;

                        @media (max-width: 599px) {
                            margin-top: 12px;
                        }
                    }
                }

                &--input {
                    position: relative;
                    padding-top: 0;
                    padding-bottom: 0;

                    @media (max-width: 599px) {
                        margin-bottom: -5px;
                        padding-right: 0 !important;
                        padding-left: 0 !important;
                        padding-bottom: 12px;
                        border-bottom: 1px solid $solid-40;
                    }

                    input[type="text"],
                    input[type="email"],
                    input[type="number"],
                    input[type="password"] {
                        position: absolute;
                        top: -8px;
                        height: calc(100% + 25px);
                        width: calc(100% + 16px);
                        border: none;
                        border-bottom: 2px solid $solid-60;
                        background-color: transparent;
                        font-size: 24px;
                        line-height: 34px;
                        font-weight: $fw-light;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        border-radius: 0;

                        @media (max-width: 904px) {
                            font-size: 20px;
                            line-height: 30px;
                        }

                        @media (max-width: 599px) {
                            top: 0;
                            position: relative;
                            width: calc(100% + 0px);
                            border-bottom: none;
                            padding-left: 0;
                            padding-right: 0;
                        }

                        &::placeholder {
                            /* Chrome, Firefox, Opera, Safari 10.1+ */
                            color: $solid-60;
                            opacity: 1; /* Firefox */
                        }

                        &:-ms-input-placeholder {
                            /* Internet Explorer 10-11 */
                            color: $solid-60;
                        }

                        &::-ms-input-placeholder {
                            /* Microsoft Edge */
                            color: $solid-60;
                        }

                        &:focus {
                            outline: none;

                            &::placeholder {
                                /* Chrome, Firefox, Opera, Safari 10.1+ */
                                color: transparent;
                                opacity: 1; /* Firefox */
                            }

                            &:-ms-input-placeholder {
                                /* Internet Explorer 10-11 */
                                color: transparent;
                            }

                            &::-ms-input-placeholder {
                                /* Microsoft Edge */
                                color: transparent;
                            }
                        }
                    }

                    .error-message {
                        display: none;
                        width: calc(100% + 48px);
                        margin-top: -8px;
                        margin-bottom: -17px;
                        color: $red-100;
                        padding-bottom: 24px;
                        border-bottom: 2px solid $solid-60;
                        font-size: 18px;
                        line-height: 26px;
                        font-weight: $fw-book;

                        @media (max-width: 904px) {
                            width: calc(100% + 32px);
                        }

                        @media (max-width: 599px) {
                            width: calc(100% + 0px);
                            margin-top: 0;
                            margin-right: 0px;
                            margin-bottom: -1px;
                            margin-left: 0px;
                            padding-right: 0px;
                            padding-bottom: 12px;
                            padding-left: 0px;
                            font-size: 14px;
                            line-height: 20px;
                            border: none;
                        }
                    }

                    textarea {
                        width: calc(100% + 48px);
                        margin: 8px 0;

                        @media (max-width: 904px) {
                            width: calc(100% + 32px);
                        }

                        @media (max-width: 599px) {
                            width: 100%;
                            margin: 12px 0 6px 0;
                        }
                    }

                    + .cell-message {
                        margin-top: 16px;

                        @media (max-width: 599px) {
                            margin-top: 4px;
                        }
                    }
                }

                &--error {
                    @media (max-width: 599px) {
                        padding-bottom: 0;
                        border-bottom: none;
                    }

                    input[type="text"],
                    input[type="email"],
                    input[type="number"],
                    input[type="password"] {
                        position: relative;
                        margin-top: 16px;
                        margin-bottom: 0px;
                        border-bottom: none;
                        border-bottom-color: $red-100;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                        appearance: none;
                        border-radius: 0;

                        @media (max-width: 599px) {
                            margin-top: 0;
                        }
                    }

                    .error-message {
                        display: block;
                    }
                }

                &--label {
                    @media (max-width: 599px) {
                        margin-bottom: 4px;
                        padding-top: 8px;
                        padding-right: 0 !important;
                        padding-left: 0 !important;
                        padding-bottom: 0px;
                    }
                }

                &--select {
                    display: flex;
                    flex-direction: row;

                    @media (max-width: 599px) {
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                    }

                    .selected-item {
                        position: absolute;
                        top: -8px;
                        display: flex;
                        align-items: center;
                        height: calc(100% + 25px);
                        width: calc(100% + 16px);
                        border: none;
                        border-bottom: 2px solid $solid-60;
                        font-size: 24px;
                        line-height: 34px;
                        font-weight: $fw-light;

                        @media (max-width: 599px) {
                            position: relative;
                            top: 0;
                            height: auto;
                            border-bottom: none;
                        }

                        p {
                            width: 90%;
                            overflow: hidden;
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 1;
                            -webkit-box-orient: vertical;

                            @media (min-width: 600px) {
                                transform: translate(0px, -4px);
                            }
                        }
                    }

                    .icon {
                        position: absolute;
                        top: calc(50% - 10px);
                        right: -8px;

                        @media (max-width: 599px) {
                            top: -12px;
                            right: 7px;
                        }
                    }

                    .error-message{
                        display: none;
                    }

                    &.cell--error{
                        @media (max-width: 599px) {
                            flex-direction: column;
                        }

                        .selected-item{
                            @media (min-width: 600px) {
                                align-items: flex-start;
                            }

                            p{
                                @media (min-width: 600px) {
                                    margin-top: 8px;
                                }
                            }
                        }

                        .error-message{
                            display: block;
                            margin-top: 26px;
                            padding-right: 30px;
                            color: $red-100;
                            font-size: 18px;
                            line-height: 26px;

                            @media (max-width: 599px) {
                                margin-top: 4px;
                                padding-right: 0;
                                font-size: 14px;
                                line-height: 20px;
                                padding-bottom: 4px;
                            }
                        }
                    }

                    &.cell--no-ellipsis{
                        flex-wrap: wrap;

                        &::after{
                            content: "";
                            display: block;
                            position: absolute;
                            border-bottom: 2px solid $solid-60;
                            height: 2px;
                            width: calc(100% + 16px);
                            top: calc(100% + 15px);

                            @media (max-width: 599px) {
                                display: none;
                            }
                        }

                        .selected-item{
                            position: relative;
                            border-bottom: none;

                            p{
                                overflow: inherit;
                                text-overflow: inherit;
                                display: block;
                                -webkit-line-clamp: inherit;
                                -webkit-box-orient: inherit;

                                @media (max-width: 599px) {
                                    width: 85%;
                                }
                            }
                        }

                        .icon{
                            @media (max-width: 599px) {
                                top: calc(50% - 30px);
                            }
                        }

                        &.cell--error{
                            .selected-item{
                                height: auto;
                            }

                            .error-message{
                                margin-top: 0;

                                @media (max-width: 599px) {
                                    margin-top: 4px;
                                    max-width: 87%;
                                }
                            }
                        }
                    }
                }

                &--text {
                    @media (min-width: 600px) {
                        padding-top: 4px;
                        margin-bottom: -25px;
                    }

                    @media (max-width: 599px) {
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        padding-bottom: 0;
                    }

                    .text {
                        width: calc(100% + 48px);
                        height: 100%;
                        padding-bottom: 18px;
                        border: none;
                        border-bottom: 2px solid $solid-60;

                        @media (max-width: 904px) {
                            width: calc(100% + 32px);
                        }

                        @media (max-width: 599px) {
                            width: 100%;
                            border-bottom: none;
                            padding-right: 0;
                            padding-bottom: 0;
                        }

                        p {
                            margin-bottom: 0;
                            font-size: 24px;
                            line-height: 34px;
                            font-weight: $fw-light;

                            @media (max-width: 904px) {
                                font-size: 20px;
                                line-height: 26px;
                                font-weight: $fw-book;
                            }

                            &.small {
                                margin-top: 1px;
                                font-size: 18px;
                                line-height: 26px;

                                @media (max-width: 904px) {
                                    margin-top: 8px;
                                    font-size: 14px;
                                    line-height: 20px;
                                    font-weight: $fw-light;
                                }
                            }
                        }
                    }
                }

                .single-link{
                    @media (max-width: 599px) {
                        margin-top: 0;
                        padding-top: 19px;
                        padding-bottom: 11px;
                        text-decoration: none;
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }

            .cell-message {
                display: flex;
                flex-wrap: wrap;
                width: 100%;
                margin-bottom: 52px;
                padding-top: 15px;
                border-top: 1px solid $solid-40;

                @media (max-width: 599px) {
                    margin-bottom: 65px;
                    padding-top: 11px;
                }

                .col-sm-9 {
                    @media (max-width: 599px) {
                        padding: 0 !important;
                    }
                }

                p {
                    font-size: 18px;
                    line-height: 26px;
                    font-weight: $fw-light;

                    @media (max-width: 904px) {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    .radio-wrapper {
        margin-top: 43px;
        margin-bottom: 32px;

        @media (max-width: 904px) {
            margin-top: 24px;
            margin-bottom: 16px;
        }

        &:first-child {
            margin-top: 0;
        }

        .title {
            @media (max-width: 904px) {
                font-size: 20px;
                line-height: 27px;
            }
        }

        p {
            margin-bottom: 28px;
            font-size: 18px;
            line-height: 28px;
            font-weight: $fw-light;

            @media (max-width: 904px) {
                margin-bottom: 20px;
                margin-top: 4px;
                font-size: 14px;
                line-height: 20px;
            }

            &:last-of-type{
                margin-bottom: 0;
            }
        }

        .for-form {
            margin-top: 32px;

            .input-group {
                display: inline-flex;
                margin-right: 52px;
                margin-bottom: 0;

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        & + {
            .form-table__row {
                border-top: 1px solid $solid-40;
            }
        }
    }

    .button-wrapper {
        margin-top: 80px;
        text-align: right;

        @media (max-width: 904px) {
            margin-top: 48px;
        }
    }

    .signup-error{
        display: flex;
        flex-direction: row;
        align-items: flex-start;

        &::before{
            content: "!";
            display: block;
            width: 28px;
            height: 28px;
            min-width: 28px;
            margin-top: 5px;
            margin-right: 20px;
            border-radius: 100%;
            color: $white;
            background-color: $red-error;
            font-size: 18px;
            text-align: center;
            line-height: 26px;
            font-weight: $fw-semi-bold;

            @media (max-width: 904px) {
                width: 24px;
                height: 24px;
                min-width: 24px;
                margin-top: 2px;
                margin-right: 12px;
                font-size: 15px;
                line-height: 22px;
            }
        }

        p{
            font-size: 24px;
            line-height: 34px;
            font-weight: $fw-light;

            @media (max-width: 904px) {
                font-size: 18px;
                line-height: 26px;
            }
        }
    }

    .login-card__alert{
        margin-bottom: 24px;

        @media (max-width: 904px) {
            margin-bottom: 16px;
        }
    }
}
