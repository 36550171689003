.part-contact-card{
  margin-bottom: 80px;

  @media (max-width: 904px) {
    margin-bottom: 56px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  &__content{
    border-top: 1px solid $solid-100;

    .part-contact-card__header{
      padding-top: 40px;

      h2{
        font-size: 36px;
        line-height: 46px;
        font-weight: $fw-light;

        @media (max-width: 904px) {
          font-size: 26px;
          line-height: 36px;
        }
      }
    }

    .part-contact-card__card{
      margin-top: 80px;
      display: flex;
      flex-direction: row;

      @media (max-width: 904px) {
        flex-direction: column;
        margin-top: 56px;
      }

      figure{
        width: 124px;
        min-width: 124px;
        height: 124px;
        margin-right: 64px;

        @media (max-width: 1139px) {
          margin-right: 32px;
        }

        @media (max-width: 904px) {
          margin-right: 0px;
          margin-bottom: 16px;
        }

        img{
          width: 100%;
          height: 100%;
          border-radius: 100%;
          object-fit: cover;
          object-position: center;
        }
      }

      .text{
        .name{
          margin-bottom: 32px;
          font-size: 22px;
          line-height: 32px;
          font-weight: $fw-medium;

          @media (max-width: 904px) {
            margin-bottom: 16px;
            font-size: 18px;
            line-height: 26px;
          }
        }

        .info{
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;

          @media (max-width: 904px) {
            flex-direction: column;
            margin-bottom: 8px;
          }

          &:last-child{
            @media (max-width: 904px) {
              margin-bottom: 0;
            }
          }

          h6{
            margin-right: 5px;
            font-size: 18px;
            line-height: 28px;
            font-weight: $fw-medium;

            @media (max-width: 904px) {
              margin-bottom: 4px;
              font-size: 18px;
              line-height: 26px;
            }
          }

          p{
            font-size: 18px;
            line-height: 28px;
            font-weight: $fw-light;

            @media (max-width: 904px) {
              font-size: 18px;
              line-height: 26px;
            }

            a{
              word-break: break-word;
            }
          }
        }
      }
    }
  }

}
