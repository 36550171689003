.part-table{
  margin-bottom: 136px;

  @media (max-width: 904px) {
    margin-bottom: 56px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  .table-htmlArea{
    margin-bottom: 0;
  }

  .table-logo{
    margin-top: 0;

    @media (max-width: 904px) {
      margin-top: 0;
    }
  }
}
