// Link list base.
// This list is applied across different parts.
.link-list{
    > ul{
        border-top: 1px solid $solid-100;

        > li{
            border-bottom: 1px solid $solid-100;

            .arrow-link{
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                width: 100%;
                padding: 24px 24px 24px 0;
                color: $main-text-color;
                font-size: 20px;
                line-height: 24px;
                font-weight: $fw-light;

                @media (max-width: 904px) {
                    padding: 18px 4px 18px 0;
                    font-size: 16px;
                    line-height: 24px;
                    font-weight: $fw-book;
                    text-align: left;
                }

                .indicator{
                    margin-left: auto;
                    margin-right: 4px;

                    span{
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 24px;
                        height: 24px;
                        margin-left: 20px;
                        border-radius: 100%;
                        background-color: $aqua-100;
                        color: $white;
                        font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
                        font-size: 14px;
                        line-height: normal;
                        font-weight: $fw-bold;
                        text-align: center;
                    }
                }

                .icon--arrow-right-32-red{
                    margin-left: 20px;

                    @media (max-width: 904px) {
                        width: 24px;
                        height: 24px;
                        background-image: url('./../images/icons/arrow/right/arrow-right-24-red.svg');
                    }
                }

                .icon--arrow-down-32-red{
                    margin-left: 20px;

                    @media (max-width: 904px) {
                        width: 24px;
                        height: 24px;
                        background-image: url('./../images/icons/arrow/down/arrow-down-24-red.svg');
                    }
                }
            }
        }
    }

    &--white{
        > ul{
            border-top: 1px solid $white;

            li{
                border-bottom: 1px solid $white;

                .arrow-link{
                    color: $white;

                    .icon{
                        background-image: url('./../images/icons/arrow/right/arrow-right-32-white.svg');

                        @media (max-width: 904px) {
                            width: 24px;
                            height: 24px;
                            background-image: url('./../images/icons/arrow/right/arrow-right-24-white.svg');
                        }
                    }
                }
            }
        }
    }
}
