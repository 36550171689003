.htmlArea__container{
  margin-bottom: 32px;

  &:last-child{
    margin-bottom: 0;
  }

  // table
  .htmlArea{
    .htmlArea__table{
      overflow: auto;
    }
  }

  & + .read-more-link{
    margin-top: -80px;

    @media (max-width: 904px) {
      margin-top: -40px;
    }
  }
}

.htmlArea{
  font-size: 22px;
  line-height: 40px;
  font-weight: $fw-light;

  @media (max-width: 904px) {
    font-size: 16px;
    line-height: 26px;
  }

  > p{
    margin-bottom: 40px;
    font-size: 22px;
    line-height: 40px;
    font-weight: $fw-light;

    @media (max-width: 904px) {
      margin-bottom: 24px;
      font-size: 16px;
      line-height: 26px;
    }

    &:last-child{
      margin-bottom: 0;
    }

    & + ul, & + ol{
      // margin-top: -40px;

      @media (max-width: 904px){
        // margin-top: -24px;
      }
    }
  }

  > h2{
    margin-bottom: 24px;
    font-size: 30px;
    line-height: 40px;
    font-weight: $fw-medium;
    color: $red-dark-3;

    @media (max-width: 904px) {
      font-size: 20px;
      line-height: 30px;
      font-weight: $fw-book;
    }

    &+ul , &+ol{
      margin-top: 0;
    }
  }

  > h3{
    margin-bottom: 24px;
    font-size: 26px;
    line-height: 36px;
    font-weight: $fw-medium;

    @media (max-width: 904px) {
      margin-bottom: 16px;
      font-size: 18px;
      line-height: 28px;
    }

    &:first-child{
      margin-top: 0;
    }
  }

  > h4{
    margin-bottom: 24px;
    font-size: 22px;
    line-height: 32px;
    font-weight: $fw-medium;

    @media (max-width: 904px) {
      margin-bottom: 16px;
      font-size: 18px;
      line-height: 28px;
    }
  }

  hr{
    display: block;
    height: 1px;
    margin: 72px 0;
    padding: 0;
    border: 0;
    border-top: 1px solid $solid-40;

    @media (max-width: 904px) {
      margin: 59px 0 34px
    }

    &:last-child{
      margin-bottom: 0;
    }
  }

  > figure{
    margin-top: 96px;
    margin-bottom: 96px;

    @media (max-width: 904px) {
      margin: 56px 0;
    }

    &:first-child{
      margin-top: 0;
    }

    &:last-child{
      margin-bottom: 0;
    }

    img{
      width: 100%;
    }

    &.editor-align-right,
    &.editor-align-left {
      margin-top: 0;
      margin-bottom: 32px;

      &:last-child{
        margin-bottom: 0;
      }

      figcaption {
        &:empty{
          display: none;
        }

        @media (max-width: 904px) {
          padding: 0;
        }
      }
    }

    &.editor-align-right {
      margin-left: 32px;

      @media (max-width: 904px) {
        float: none !important;
        width: 100% !important;
        margin-right: 0;
        margin-left: 0;
      }
    }

    &.editor-align-left {
      margin-right: 32px;

      @media (max-width: 904px) {
        float: none !important;
        width: 100% !important;
        margin-right: 0;
        margin-left: 0;
      }
    }

    figcaption{
      margin-top: 32px;
      color: $solid-80;
      font-size: 18px;
      line-height: 26px;
      font-weight: $fw-light;

      @media (max-width: 904px) {
        margin-top: 24px;
        font-size: 14px;
        line-height: 20px;
        font-weight: $fw-book;
      }
    }
  }

  blockquote{
    margin: 64px 0;
    padding: 48px 9% 56px 13%;
    background-color: $warm-40;
    font-size: 32px;
    line-height: 42px;
    font-weight: $fw-light;
    letter-spacing: 0.12px;

    @media (max-width: 904px) {
      margin: 56px 12px 56px 16px;
      padding: 30px 30px 26px;
      background-color: $warm-20;
      font-size: 20px;
      line-height: 26px;
      letter-spacing: 0;
    }

    &:last-child{
      margin-bottom: 0;
    }

    p{
      &::before{
        content: '';
        position: absolute;
        left: -31px;
        display: block;
        width: 69px;
        height: 63px;
        background-image: url('./../images/icons/quote/quote-red.svg');
        background-size: contain;

        @media (max-width: 904px) {
          left: 8px;
          width: 28px;
          height: 26px;
        }
      }

      &::after{
        content: '';
        position: absolute;
        right: -8px;
        display: block;
        width: 48px;
        height: 44px;
        background-image: url('./../images/icons/quote/quote-red.svg');
        background-size: contain;
        -webkit-transform: rotateZ(180deg) translate(0px, 10px);
        transform: rotateZ(180deg) translate(0px, 10px);

        @media (max-width: 904px) {
          right: 8px;
          width: 28px;
          height: 26px;
          -webkit-transform: rotateZ(180deg) translate(0px, 22px);
          transform: rotateZ(180deg) translate(0px, 22px);
        }
      }
    }

    footer{
      margin-top: 16px;
      color: $solid-100;
      font-size: 18px;
      line-height: 18px;
      font-weight: $fw-book;
      font-style: normal;

      @media (max-width: 904px) {
        margin-top: 10px;
        font-size: 12px;
        line-height: 20px;
      }

      cite{
        font-style: normal;
      }
    }
  }

  // br{
  //   &:first-child{
  //     display: none;
  //   }
  // }

  .press-photos__wrapper{
    margin-bottom: 48px;

    @media (max-width: 904px) {
      margin-bottom: 40px;
    }

    &:last-child{
      margin-bottom: 0;
    }

    table{
      tbody{
        tr{
          @media (max-width: 904px) {
            display: flex;
            flex-direction: column;
          }

          td{
            vertical-align: bottom;
            font-size: 18px;
            line-height: 28px;

            @media (max-width: 904px) {
              display: flex;
              flex-direction: column;
            }

            figure{
              max-width: 296px;
              padding-right: 32px;

              @media (max-width: 904px) {
                max-width: 216px;
                margin-bottom: 16px;
                padding-right: 0;
              }
            }

            p{
              &:last-child{
                margin-bottom: 26px;

                @media (max-width: 904px) {
                  margin-bottom: 0;
                  order: -1;
                }
              }

              strong{
                font-weight: $fw-medium;
              }
            }

            a{
              display: block;
              margin-top: 26px;

              @media (max-width: 904px) {
                margin-top: 0;
                margin-bottom: 26px;
              }
            }
          }
        }
      }
    }
  }

  > div a, > div strong a{
    color: $red-dark-3;
    text-decoration: underline;

    &:hover{
      color: $red-dark-3;
      text-decoration: underline;
    }
  }

  .offgrid-macro{
    margin-left: calc(((100vw - 858px)/2) * -1) !important;
    margin-right: calc(((100vw - 858px)/2) * -1) !important;

    @media (max-width: 1680px) {
      margin-left: calc(((100vw - 380px) * -0.166667) - 200px) !important;
      margin-right: calc(((100vw - 380px) * -0.166667) - 200px) !important;

    }

    @media (max-width: 1440px) {
        margin-left: calc(((100vw - 698px)/2) * -1) !important;
        margin-right: calc(((100vw - 698px)/2) * -1) !important;
    }

    @media (max-width: 1140px) {
        margin-left: calc(((100vw - 380px) * -0.166667) - 100px) !important;
        margin-right: calc(((100vw - 380px) * -0.166667) - 100px) !important;
    }

    @media (max-width: 904px) {
        margin-left: -32px !important;
        margin-right: -32px !important;
    }
  }
}

// general list style
.htmlArea, .table-htmlArea{
  ul, ol{
    margin-top: 40px;
    margin-bottom: 40px;

    @media (max-width: 904px) {
      margin-top: 24px;
      margin-bottom: 32px;
    }

    &:first-child{
      margin-top: 0;
    }

    &:last-child{
      margin-bottom: 0;
    }

    li{
      a{
        color: $red-dark-3;
        text-decoration: underline;
      }
    }
  }

  ul{
    list-style: none;

    li{
      display: block;
      position: relative;
      padding-left: 34px;
      font-size: 22px;
      line-height: 34px;
      font-weight: $fw-light;

      @media (max-width: 904px) {
        padding-left: 31px;
        font-size: 16px;
        line-height: 26px;
      }

      &::before{
        content: "●";
        position: absolute;
        left: 0;
        color: $solid-80;
        font-weight: bold;
        display: inline-block;
        font-size: 16px;
        line-height: 35px;

        @media (max-width: 904px) {
          font-size: 10px;
          line-height: 26px;
        }
      }
    }
  }

  ol{
    list-style: none;
    counter-reset: items;

    li{
      position: relative;
      display: block;
      padding-left: 34px;
      counter-increment: items;
      font-size: 22px;
      line-height: 34px;
      font-weight: $fw-light;

      @media (max-width: 904px) {
        font-size: 15px;
        line-height: 22px;
      }

      &::before{
        position: absolute;
        left: 0;
        content: counter(items) ".";
        vertical-align: top;
        line-height: 34px;
        min-width: 24px;

        @media (max-width: 904px) {
            // margin-right: 5px;
            line-height: 22px;
        }
      }
    }
  }
}

.table-htmlArea{
  margin-bottom: 32px;

  @media (max-width: 904px) {
    margin-bottom: 24px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  .table-overflow{
    @media (max-width: 904px) {
      display: flex;
      overflow: auto;
      margin-right: -32px;
      margin-left: -32px;
      // padding-right: 32px;
      padding-left: 32px;
    }

    &::after{
      @media (max-width: 904px) {
        content: "";
        display: block;
        width: 32px;
        height: 32px;
        min-width: 32px;
        background-color: transparent;
      }
    }

    .htmlArea__table{
      margin: 0 auto;
    }

    > p{
      &:first-of-type{
        display: none;
      }
    }
  }

  &--left{
    .table-overflow{
      .htmlArea__table{
        margin-left: 0 !important;

        table{
          margin-left: 0 !important;
        }
      }
    }
  }
}

// tables using the same style inside or outside htmlArea
.table-htmlArea , .htmlArea__container .htmlArea > .htmlArea__table{
  table{
    margin: 0 auto;
    font-size: 18px;
    line-height: 26px;

    th, td{
      padding: 16px 20px;
      border: 1px solid $solid-40;
      vertical-align: middle;

      @media (max-width: 904px) {
        font-size: 14px;
        line-height: 18px;
      }

      ul, ol{
        margin-top: 0;

        @media (max-width: 904px) {
          margin-top: 0;
        }

        li{
          padding-left: 24px;
          font-size: 18px;

          @media (max-width: 904px) {
            font-size: 14px;
            padding-left: 16px;
          }
        }

        & + p {
          margin-top: -40px;

          @media (max-width: 904px) {
            margin-top: -32px;
          }
        }
      }
    }

    thead{
      tr{
        th{
          background-color: $warm-40;
          font-weight: $fw-semi-bold;

          @media (max-width: 904px) {
            font-weight: $fw-bold;
            padding: 23px 6px;
          }
        }
      }
    }

    tbody{
      tr{
        td{
          font-weight: $fw-light;

          @media (max-width: 904px) {
            padding: 10px 6px;
          }
        }
      }
    }

    a{
      color: $red-dark-3;
      text-decoration: underline;

      &:hover{
        color: $red-dark-3;
        text-decoration: underline;
      }
    }
  }

  &.no-border{
    table{
      th, td{
        border: none;
      }
    }
  }

  &.transparent-header{
    table{
      th{
        background-color: transparent;
      }
    }
  }

  &.table-background{
    margin-top: -12px;
    margin-bottom: 13px;

    @media (max-width: 904px) {
      margin-top: -16px;
      margin-bottom: 8px;
    }

    table{
      width: 100%;

      thead, tbody{
        tr{
          th, td{
            border: none;

            ul, ol{
              li{
                font-size: 22px;

                @media (max-width: 904px) {
                  font-size: 14px;
                }
              }
            }
          }
        }
      }

      thead{
        tr{
          th{
            padding: 34px 20px;
            background-color: $yellow-50;
            font-size: 36px;
            line-height: 46px;
            font-weight: $fw-light;

            @media (max-width: 904px) {
              padding: 19px 15px;
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }

      tbody{
        tr{
          td{
            position: relative;
            padding: 34px 20px 53px;
            font-size: 22px;
            line-height: 32px;

            @media (max-width: 904px) {
              padding: 19px 15px 35px;
              font-size: 14px;
              line-height: 20px;
            }

            &::before{
              content: "";
              display: block;
              position: absolute;
              background-color: $yellow-20;
              width: 100%;
              height: calc(100% - 19px);
              left: 0;
              top: 0;
              z-index: -1;

              @media (max-width: 904px) {
                height: calc(100% - 16px);
              }
            }
          }

          &:first-child{
            td{
              padding-top: 46px;

              @media (max-width: 904px) {
                padding-top: 35px;
              }

              &::before{
                top: 12px;
                height: calc(100% - 19px - 12px);

                @media (max-width: 904px) {
                  top: 16px;
                  height: calc(100% - 16px - 16px);
                }
              }
            }
          }
        }
      }
    }

    &--red{
      table{
        thead{
          tr{
            th{
              background-color: $red-40;
            }
          }
        }

        tbody{
          tr{
            td{
              &::before{
                background-color: $red-20;
              }
            }
          }
        }
      }
    }

    &--red{
      table{
        thead{
          tr{
            th{
              background-color: $red-40;
            }
          }
        }

        tbody{
          tr{
            td{
              &::before{
                background-color: $red-20;
              }
            }
          }
        }
      }
    }

    &--warm{
      table{
        thead{
          tr{
            th{
              background-color: $warm-60;
            }
          }
        }

        tbody{
          tr{
            td{
              &::before{
                background-color: $warm-20;
              }
            }
          }
        }
      }
    }

    &--contrast{
      table{
        thead{
          tr{
            th{
              background-color: $contrast-100;
            }
          }
        }

        tbody{
          tr{
            td{
              &::before{
                background-color: $contrast-40;
              }
            }
          }
        }
      }
    }
  }
}

.htmlArea__container .htmlArea > .htmlArea__table{
  margin-bottom: 96px;

  @media (max-width: 904px) {
    display: flex;
    margin-right: -32px;
    margin-bottom: 46px;
    margin-left: -32px;
    padding-left: 32px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  &::after{
    @media (max-width: 904px) {
      content: "";
      display: block;
      width: 32px;
      height: 32px;
      min-width: 32px;
      background-color: transparent;
    }
  }
}

// Les mer button
.read-more-link{
  margin-bottom: 136px;

  @media (max-width: 904px) {
    margin-bottom: 56px;
  }

  &:last-child{
    margin-bottom: 0;
  }

  a{
    color: $red-dark-3;
    font-size: 22px;
    line-height: 40px;
    font-weight: 300;

    @media (max-width: 904px) {
      font-size: 16px;
      line-height: 26px;
    }
  }
}
