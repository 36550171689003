// Primary Colors
$white: #FFFFFF;
$black: #000000;
$main-text-color: #202020;
$solid-100: #464646;
$solid-80: #6B6B6B;
$solid-60: #909090;
$solid-40: #B5B5B5;
$solid-30: #C7C7C7;
$solid-20: #DADADA;
$solid-10:  #ECECEC;

$red-error: #FF0002;
$red-dark-1: #D93737;
$red-dark-2: #CF2222;
$red-dark-3: #B91111;
$red-100: #E34D4E;
$red-80: #E97171;
$red-60: #EE9495;
$red-40: #F4B8B8;
$red-20: #F9DBDC;

$warm-dark-1: #D9C7BE;
$warm-dark-2: #D1B9AF;
$warm-dark-3: #B19182;
$warm-100: #E4D5CE;
$warm-80: #E9DDD8;
$warm-60: #EFE6E2;
$warm-40: #F4EEEB;
$warm-20: #FAF7F5;

$contrast-dark-1: #6FAA8F;
$contrast-dark-2: #498C6B;
$contrast-100: #A8D1BF;
$contrast-80: #B9DACC;
$contrast-60: #CBE3D9;
$contrast-40: #DCEDE5;
$contrast-20: #EEF6F2;

// Secondary colors
$yellow-100: #FDAF4A;
$yellow-80: #FDBF6E;
$yellow-60: #FECF92;
$yellow-50: #FED7A4;
$yellow-30: #FEE7C9;
$yellow-20: #FFEFDB;
$yellow-10: #FFF7ED;

$blue-100: #4E76D5;
$blue-80: #7191DD;
$blue-50: #A6BAEA;
$blue-30: #CAD6F2;
$blue-20: #DCE4F7;

$coral-100: #F5897E;
$mocha-100: #8F6F6D;
$aqua-100: #4DADB8;
$aqua-10: #EDF7F8;

$moss-green-100: #92A563;
$moss-green-80: #A8B782;
$moss-green-60: #BEC9A1;
$moss-green-40: #D3DBC1;
$moss-green-30: #DEE4D0;
$moss-green-10: #F4F6EF;

$leaf-green-dark: #4A7A59;
$leaf-green-100: #549B76;
$leaf-green-80: #76AF91;
$leaf-green-60: #98C3AD;

$gray-green-100: #839D9A;

// text color classes
.color-solid-80{
  color: $solid-80;
}
