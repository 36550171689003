// Popup menu
.popup-menu{
    display: none;
    position: absolute;
    width: 240px;
    top: calc(100% + 28px);
    right: -30px;
    padding: 47px 30px 33px;
    background: $white;
    text-align: left;
    box-shadow: -4px 4px 19px rgba(0, 0, 0, 0.25);
    z-index: 1;

    &.active{
      display: flex;
      flex-direction: column;
    }

    &::before{
        content: "";
        position: absolute;
        top: -10px;
        right: 30px;
        width: 22px;
        height: 22px;
        background-color: $white;
        transform: rotateY(0deg) rotate(45deg);
    }

    li{
        border-bottom: 1px solid $solid-40;

        &:first-child{
            border-top: 1px solid $solid-40;
        }

        a{
            display: block;
            text-align: right;
            padding: 16px 0;
            font-size: 20px;
            line-height: 20px;
            font-weight: $fw-book;
        }
    }
}
