// Image aspect ratio
.img {
  position: relative;
  width: 100%;
  overflow: hidden;

  img, > iframe, video {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 100%;
    object-fit: cover;
    width: 100%;
    transform: translate(-50%, -50%);
  }

  video {
    height: 100%;
    width: 100%;
  }

  &.aspectRatio--1x1 {
    padding-top: calc((1 / 1) * 100%);
  }

  &.aspectRatio--292x200 {
    padding-top: calc((200 / 292) * 100%);
  }

  &.aspectRatio--405x277 {
    padding-top: calc((277 / 405) * 100%);
  }

  &.aspectRatio--405x255 {
    padding-top: calc((255 / 405) * 100%);
  }

  &.aspectRatio--450x256 {
    padding-top: calc((256 / 450) * 100%);
  }

  &.aspectRatio--450x232 {
    padding-top: calc((232 / 450) * 100%);
  }

  &.aspectRatio--623x256 {
    padding-top: calc((256 / 623) * 100%);
  }

  &.aspectRatio--624x362 {
    padding-top: calc((362 / 624) * 100%);
  }

  &.aspectRatio--1203x640 {
    padding-top: calc((640 / 1203) * 100%);
  }

  &.aspectRatio--1280x747 {
    padding-top: calc((747 / 1280) * 100%);
  }

  &.aspectRatio--515x500 {
    padding-top: calc((500 / 515) * 100%);
  }

  &.aspectRatio--624x500 {
    padding-top: calc((500 / 624) * 100%);
  }

  &.aspectRatio--292x219 {
    padding-top: calc((219 / 292) * 100%);
  }

  &.aspectRatio--1280x700 {
    padding-top: calc((700 / 1280) * 100%);
  }

  &.aspectRatio--547x500 {
    padding-top: calc((500 / 547) * 100%);
  }

  &.aspectRatio--412x274 {
    padding-top: calc((274 / 412) * 100%);
  }

  &.aspectRatio--839x380 {
    padding-top: calc((380 / 839) * 100%);
  }
}
