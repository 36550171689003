.calendar-page {
  .featured-hero {
    @media (min-width: 905px) {
      margin-bottom: 100px;
      padding-bottom: 54px;
    }
  }

  .for-accordion {
    &__accordion--calendar {
      border-color: $solid-40;

      &:first-of-type {
        .content {
          .btn-show {
            border-color: $solid-40;
          }
        }
      }
    }
  }
}

.calendar-hero {
  margin-bottom: 96px;

  @media (max-width: 904px) {
    margin-bottom: 64px;
  }

  .article-header {
    @media (min-width: 905px) {
      padding-bottom: 76px;
    }
  }

  .offgrid-wrapper {
    &__text {
      position: relative;
      padding: 98px 7% 98px 0;

      @media (max-width: 904px) {
        padding: 64px 0 32px;
      }

      .share-list {
        top: -32px;
        right: 3.5%;

        @media (max-width: 904px) {
          top: -24px;
          right: 0;
        }
      }

      .row {
        .col-12 {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          justify-content: space-between;

          @media (max-width: 904px) {
            flex-direction: column;
            align-items: flex-start;
          }

          .calendar-info {
            width: 100%;
            max-width: 434px;
            margin-right: 32px;

            @media (max-width: 1139px) {
              max-width: 410px;
            }

            @media (max-width: 904px) {
              max-width: 100%;
              margin-right: 0;
            }

            ul {
              li {
                display: flex;
                flex-direction: row;
                padding: 12px 15px;
                border-bottom: 1px solid $solid-40;

                @media (max-width: 904px) {
                  padding: 12px 0 16px;
                }

                &:first-child {
                  border-top: 1px solid $solid-40;
                }

                h6 {
                  margin-right: 4px;
                  font-size: 20px;
                  line-height: 28px;
                  font-weight: $fw-medium;

                  @media (max-width: 904px) {
                    font-size: 16px;
                    line-height: 26px;
                  }
                }

                p {
                  font-size: 20px;
                  line-height: 28px;
                  font-weight: $fw-light;

                  @media (max-width: 904px) {
                    font-size: 16px;
                    line-height: 26px;
                  }
                }
              }
            }
          }

          .button-wrapper {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media (max-width: 904px) {
              margin-top: 47px;
            }

            .btn {
              margin-top: 21px;

              &:first-child {
                margin-top: 0;
              }

              @media (max-width: 904px) {
                padding: 8px 16px;
                border-radius: 6px;
                font-size: 15px;
                line-height: 26px;
                font-weight: $fw-book;
              }

              .icon {
                &--arrow-right-32-white {
                  @media (max-width: 904px) {
                    width: 24px;
                    height: 24px;
                    background-image: url("./../images/icons/arrow/right/arrow-right-24-white.svg");
                  }
                }

                &--arrow-up-right-36-white {
                  @media (max-width: 904px) {
                    width: 24px;
                    height: 24px;
                    background-image: url("./../images/icons/arrow/up-right/arrow-up-right-24-white.svg");
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Event registration page
.event-information {
  margin-bottom: 104px;

  .event-information__datetime {
    font-size: 16px;
    color: gray;

    @media (max-width: 904px) {
      font-size: 14px;
    }
  }

  p.event-information__freetext {
    font-size: 20px !important;

    @media (max-width: 599px) {
      margin-bottom: 24px !important;
    }

    @media (max-width: 904px) {
      font-size: 14px !important;
    }
  }

  @media (max-width: 904px) {
    margin-bottom: 56px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  h3.event-information__title {
    margin-bottom: 32px;

    @media (max-width: 904px) {
      margin-bottom: 24px;
      font-size: 20px;
      line-height: 27px;
    }
  }

  h3.title {
    margin-bottom: 24px;
  }

  h2.event-information__title {
    margin-bottom: 20px;
    font-size: 36px;
    line-height: 46px;
    font-weight: $fw-light;

    @media (max-width: 904px) {
      margin-bottom: 24px;
      font-size: 20px;
      line-height: 27px;
    }
  }

  .calendar {
    a {
      border-bottom: 1px solid $solid-40;

      .calendar__dates {
        @media (min-width: 905px) {
          width: 24%;
        }
      }

      .calendar__info {
        @media (min-width: 905px) {
          width: 76%;
          flex-direction: column;
          justify-content: center;
          padding: 23px 40px 15px 61px;
        }

        .title-info {
          @media (min-width: 905px) {
            width: 100%;
          }
        }

        .location-info {
          @media (min-width: 905px) {
            width: 100%;
            // display: flex;
            // flex-direction: row;
          }

          p {
            @media (min-width: 905px) {
              margin-right: 5px;
            }

            &:last-child {
              @media (min-width: 905px) {
                margin-right: 0;
              }
            }
          }

          .canceled-tag {
            @media (min-width: 905px) {
              position: absolute;
              top: 0;
              right: 0;
              padding: 12px 13px 10px;
              z-index: -1;
            }

            & + p{
              @media (max-width: 904px) {
                margin-top: 12px;
              }
            }
          }
        }
      }
    }

    & + .calendar {
      a {
        border-top: none;
      }
    }
  }

  &__contact {
    margin-top: 29px;
    margin-bottom: 78px;
    padding-bottom: 23px;
    border-bottom: 1px solid $solid-40;

    @media (max-width: 904px) {
      margin-bottom: 30px;
      padding-bottom: 30px;
    }

    .item {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0;
      }

      @media (max-width: 599px) {
        flex-direction: column;
        margin-bottom: 16px;
      }

      p {
        width: calc(50% - 16px);
        font-size: 24px;
        line-height: 34px;
        font-weight: $fw-light;

        @media (max-width: 904px) {
          font-size: 18px;
          line-height: 26px;
        }

        @media (max-width: 599px) {
          width: 100%;
        }
      }
    }
  }

  .button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 425px) {
      flex-direction: column-reverse;
      align-items: flex-end;
    }

    .btn-cancel {
      margin-right: 73px;
      font-size: 24px;
      font-weight: $fw-light;
      border-bottom: 1px solid $main-text-color;

      @media (max-width: 904px) {
        margin-right: 32px;
        font-size: 18px;
        line-height: 26px;
      }

      @media (max-width: 425px) {
        margin-right: 0;
        margin-top: 16px;
      }
    }

    .btn--secondary {
      @media (max-width: 904px) {
        padding: 11px 24px;
        font-size: 18px;
        line-height: 26px;
        font-weight: $fw-light;
        border-radius: 8px;
      }

      .icon {
        &--arrow-right-32-white {
          @media (max-width: 904px) {
            width: 24px;
            height: 24px;
            background-image: url("./../images/icons/arrow/right/arrow-right-24-white.svg");
          }
        }
      }
    }
  }

  .form-table {
    margin-top: 58px;
    margin-bottom: 68px;

    @media (max-width: 904px) {
      margin-top: 30px;
      margin-bottom: 30px;
    }

    .radio-wrapper {
      p {
        strong {
          font-weight: $fw-book;
        }
      }

      .for-form {
        margin-top: 16px;
      }
    }

    .signup-error {
      margin-top: 110px;
      padding-bottom: 24px;
      border-bottom: 1px solid $solid-40;

      @media (max-width: 904px) {
        margin-top: 56px;
      }
    }
  }

  .top-filter-header{
    margin-top: 0;
    margin-bottom: 30px;

    @media (max-width: 904px) {
      margin-bottom: 24px;
    }

    .filter-header__wrapper{

      .title{
        font-size: 36px;
        line-height: 46px;
        font-weight: $fw-light;

        @media (max-width: 904px) {
          font-size: 20px;
          line-height: 27px;
        }
      }
    }
  }

  .for-accordion{
    &__accordion{
      .content{
        .btn-show{
          @media (min-width: 905px) {
            padding: 40px 0;
          }
        }
      }
    }
  }
}

// Event confirmation page
.event-confirmation {
  .title, h2 {
    font-size: 36px;
    line-height: 46px;
    font-weight: $fw-light;

    @media (max-width: 904px) {
      font-size: 24px;
      line-height: 30px;
    }
  }

  p {
    margin-top: 43px;
    font-size: 28px;
    line-height: 42px;
    font-weight: $fw-light;

    @media (max-width: 904px) {
      margin-top: 24px;
      font-size: 20px;
      line-height: 27px;
    }
  }

  .button-wrapper {
    margin-top: 73px;

    @media (max-width: 904px) {
      margin-top: 56px;
    }

    .btn {
      @media (max-width: 904px) {
        padding: 11px 24px;
        font-size: 18px;
        line-height: 26px;
        font-weight: $fw-light;
        border-radius: 8px;
      }

      .icon {
        &--arrow-right-32-white {
          @media (max-width: 904px) {
            width: 24px;
            height: 24px;
            background-image: url("./../images/icons/arrow/right/arrow-right-24-white.svg");
          }
        }
      }
    }
  }
}
