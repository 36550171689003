.article-hero {
  position: relative;
  margin-bottom: 96px;
  z-index: 1;

  @media (max-width: 904px) {
    margin-bottom: 56px;
  }

  &__image {
    max-width: 94.5%;
    margin-bottom: -112px;
    position: relative;
    z-index: 1;

    @media (max-width: 904px) {
      margin-bottom: -40px;
      max-width: 100%;
    }

    .img {
      position: relative;

      @media (max-width: 904px) {
        padding-top: calc((200 / 292) * 100%);
      }
    }
  }

  .offgrid-wrapper {
    position: relative;
    z-index: 0;

    &__text {
      padding-top: 112px;

      @media (max-width: 1440px) {
        width: 1040px;
      }

      @media (max-width: 1140px) {
        width: calc(100% - 50px);
      }

      @media (max-width: 904px) {
        padding-top: 40px;
        width: calc(100% - 64px);
        margin: 0 auto;
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 48px 0px;

    @media (max-width: 904px) {
      flex-direction: column-reverse;
      padding: 40px 0px 24px 0px;
    }

    &--info {
      width: 17%;
    }

    &--description {
      width: 56%;
      margin-left: 8.4%;
      @media (max-width: 904px) {
        margin-bottom: 16px;
      }
    }

    &--info, &--description {
      font-size: 18px;
      line-height: 26px;
      font-weight: $fw-light;
      color: $solid-80;
      padding-left: 0px;

      @media (max-width: 904px) {
        font-size: 14px;
        line-height: 20px;
        width: 100%;
        margin-left: 0px;
      }
    }
  }

  &--no-image {
    .article-hero__image {
      figure {
        display: none;
      }

      .share-list {
        top: calc(100% - 32px);
        // background-color: $warm-20;

        @media (max-width: 904px) {
          top: calc(100% - 19px);
          right: 3.5%;
        }
      }
    }

    .article-hero__content--description {
      // display: none;
    }

    .offgrid-wrapper {
      &__text {
        padding-top: 0px;
      }
    }
  }

  &--no-author {
    .article-hero__content--info {
      display: none;
    }

    .article-hero__content--description {
      margin-left: 0px;
      width: 81.6%;

      @media (max-width: 904px) {
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
}
